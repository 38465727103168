import { Input, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BorderTable from "src/components/BorderTable";
import TextConstants from "src/constants/TextConstants";
import { getBMConfigrurationInformation } from "src/store/actions/bms";
const Others = () => {
  const { facilityId, floorId } = useParams();
  const dispatch = useDispatch();
  const { facilityFloor, loading: loadingFloor } = useSelector(
    (state) => state.Floors
  );
  const { configurationInfo, loading } = useSelector((state) => state.Bms);

  useEffect(() => {
    if (facilityFloor.bmId) {
      dispatch(getBMConfigrurationInformation({ bmId: facilityFloor.bmId }));
    }
  }, [facilityFloor.bmId]);
  const columns = [
    {
      title: TextConstants.FloorBMLiteInformation.appVersion,
      dataIndex: "appVersion",
      key: "appVersion",
    },
    {
      title: TextConstants.FloorBMLiteInformation.bmVersion,
      dataIndex: "bmVersion",
      key: "bmVersion",
    },
    {
      title: TextConstants.FloorBMLiteInformation.lxVersion,
      dataIndex: "lxVersion",
      key: "lxVersion",
    },
    {
      title: TextConstants.FloorBMLiteInformation.tgVersion,
      dataIndex: "tgVersion",
      key: "tgVersion",
    },
    {
      title: TextConstants.FloorBMLiteInformation.macAddress,
      dataIndex: "macAddress",
      key: "macAddress",
    },
    {
      title: TextConstants.FloorBMLiteInformation.lightingType,
      dataIndex: "lightingType",
      key: "lightingType",
    },
    {
      title: TextConstants.FloorBMLiteInformation.loginInfo,
      dataIndex: "loggedInUserInfo",
      key: "loggedInUserInfo",
      render: (value) => (
        <>
          {TextConstants.Common.EmailAddress}: {value.email}
          <br />
          {TextConstants.FloorBMLiteInformation.LastLoggedIn}:{" "}
          {dayjs(value.lastLoggedIn).format("YYYY/MM/DD HH:mm:ss")}
          <br />
          {TextConstants.FloorBMLiteInformation.IPAddress}: {value.ipAddress}
        </>
      ),
    },
  ];

  const bmConfigInfoData = useMemo(() => {
    if (configurationInfo.bmVersion) {
      return [configurationInfo];
    } else {
      return [];
    }
  }, [configurationInfo]);

  return (
    <React.Fragment>
      <BorderTable
        showSearch={false}
        columns={columns}
        dataSource={bmConfigInfoData}
        rowKey={"appVersion"}
        showPagination={false}
        loading={loadingFloor || loading}
        minHeight={240}
      />
      <Input
        className="access-id-input"
        addonBefore={
          <Typography.Text style={{ color: "white" }}>
            Access ID
          </Typography.Text>
        }
        value={`${facilityId}:${floorId}`}
      />
    </React.Fragment>
  );
};

export default Others;
