import { getColorHexFromSliderValue } from "src/helpers/helpers";

const LightColorCircle = ({ color = 0 }) => {
  return (
    <div
      className="light-color-circle"
      style={{
        backgroundColor: getColorHexFromSliderValue(color),
        display: "inline-block",
      }}
    ></div>
  );
};

export default LightColorCircle;
