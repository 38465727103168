import { Col, Row, Spin } from "antd";
import React from "react";
import CustomCard from "src/components/CustomCard/CustomCard";

const NewDeploymentCard = ({ title, onGoBack, loading = false, children }) => {
  return (
    <React.Fragment>
      <CustomCard title={title} onGoBack={onGoBack}>
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 99,
              backgroundColor: "rgb(225 225 225 / 39%)",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "wait",
            }}
          >
            <Spin />
          </div>
        )}
        <Row gutter={[24, 0]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            xl={{ span: 18 }}
            xxl={{ span: 16 }}
          >
            <Row gutter={[24, 24]}>
              <Col xs={{ span: 24 }}>{children}</Col>
            </Row>
          </Col>
        </Row>
      </CustomCard>
    </React.Fragment>
  );
};

export default NewDeploymentCard;
