import { MailOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, Row, Typography } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import logo from "src/assets/images/dark-logo.png";
import AppReleaseVersion from "src/components/AppReleaseVersion";
import CustomForm from "src/components/CustomForm";
import { ASCII_REGEX, ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { Notification } from "src/helpers/Notification";
import { PATHS } from "src/routes";
import { passwordResetRequest } from "src/store/actions";
const { Content } = Layout;

const PasswordResetRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, user } = useSelector((state) => state.Auth);

  const redirectToLoginPage = () => {
    navigate(`${PATHS.LOGIN}`);
  };

  const passwordResetRequestSuccessCallback = () => {
    setTimeout(() => {
      redirectToLoginPage();
    }, 2000);
    Notification.success(TextConstants.Common.PasswordResetRequestSuccessMsg);
  };

  const onFinish = (params) => {
    dispatch(passwordResetRequest(params, passwordResetRequestSuccessCallback));
  };

  const isAuthenticated = useMemo(() => !!user, [user]);

  if (isAuthenticated && user) {
    if (user.role === ROLES.SA) {
      return <Navigate to={PATHS.ADMIN_DASHBOARD} />;
    }

    if ([ROLES.FA, ROLES.SU].includes(user.role)) {
      return <Navigate to={PATHS.DASHBOARD} />;
    }
  }

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 8 }}
            lg={{ span: 12 }}
            md={{ span: 16 }}
            xs={{ span: 24 }}
          >
            <div className="d-flex justify-content-center">
              <img src={logo} alt="LICONEX LITE" className="home-logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Typography.Title level={3}>
                {TextConstants.Common.ProceduresIfYouCannotLogIn}
              </Typography.Title>
            </div>
            <div className="d-flex justify-content-center">
              <Typography.Paragraph>
                <pre>
                  <Typography.Text strong>
                    {TextConstants.Common.PleaseEnterYourEmailRegistered}
                  </Typography.Text>
                </pre>
              </Typography.Paragraph>
            </div>
            <CustomForm
              onFinish={onFinish}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="email"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.EmailAddress}
                  </Typography.Text>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                  {
                    pattern: ASCII_REGEX,
                    message: TextConstants.Common.OnlyAcceptASCIIMessage,
                  },
                  {
                    max: 254,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        "254"
                      ),
                  },
                ]}
              >
                <Input
                  autoFocus
                  addonBefore={<MailOutlined style={{ color: "#fff" }} />}
                  placeholder={TextConstants.Common.EmailAddress}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  htmlType="button"
                  onClick={redirectToLoginPage}
                  style={{ width: "130px" }}
                >
                  {TextConstants.Common.GoBack}
                </Button>

                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "130px", float: "right" }}
                >
                  {TextConstants.Common.Submit}
                </Button>
              </Form.Item>
              <Form.Item>
                <hr className="custom-hr" />
                <AppReleaseVersion />
              </Form.Item>
            </CustomForm>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default PasswordResetRequest;
