import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "src/components/CustomForm";
import {
  ASCII_REGEX,
  FACILITY_ADMIN_ROLES,
  MAX_NUM_SETUP_USER_EACH_FACILITY,
  MAX_NUM_USERS_INVITED,
  MAX_NUM_USERS_INVITE_ONE_TIME,
  ROLES,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { inviteFacilityUsers } from "src/store/actions";

const facilityAdminRoleOptions = [
  { label: TextConstants.Common.Guest, value: FACILITY_ADMIN_ROLES.GUEST },
  {
    label: `${TextConstants.Common.Owner}（${TextConstants.Common.CompanyMember}）`,
    value: FACILITY_ADMIN_ROLES.OWNER,
  },
];

const facilityAdminRoleOptionsForGuest = [
  { label: TextConstants.Common.Guest, value: FACILITY_ADMIN_ROLES.GUEST },
];

const FacilityCreateUserModal = ({
  onClose,
  onSubmit,
  facilityId,
  membersInviting = [],
  isFacilityOwner = false,
}) => {
  const formInitValues = {
    role: ROLES.FA,
    email_0: "",
    admin_role_0: FACILITY_ADMIN_ROLES.GUEST,
  };

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { users, loading } = useSelector((state) => state.Users);
  const [totalEmaisIndex, setTotalEmaisIndex] = useState([]);
  const selectedRole = Form.useWatch("role", form);

  const isNotLimitNumberEmail = useMemo(() => {
    return (
      selectedRole === ROLES.FA ||
      (selectedRole === ROLES.SU &&
        totalEmaisIndex.length <
          MAX_NUM_SETUP_USER_EACH_FACILITY - membersInviting.length)
    );
  }, [selectedRole, totalEmaisIndex]);

  const onFinish = useCallback(
    (formValues) => {
      const body = [
        {
          email: formValues[`email_0`],
          role: formValues.role,
          faAdminRole:
            formValues.role === ROLES.FA ? formValues[`admin_role_0`] : null,
        },
      ];

      totalEmaisIndex.forEach((indexNum) => {
        body.push({
          email: formValues[`email_${indexNum}`],
          role: formValues.role,
          faAdminRole:
            formValues.role === ROLES.FA
              ? formValues[`admin_role_${indexNum}`]
              : null,
        });
      });

      dispatch(
        inviteFacilityUsers({ facilityId, body }, () => {
          onClose(true);
        })
      );
    },
    [dispatch, totalEmaisIndex, facilityId]
  );

  const isHaveReachedUserInvitationLimit = useMemo(() => {
    return (
      (users || []).length + totalEmaisIndex.length + 1 > MAX_NUM_USERS_INVITED
    );
  }, [users, totalEmaisIndex]);

  return (
    <React.Fragment>
      <Modal
        title={TextConstants.CreateUserModal.Title}
        open={true}
        onOk={onSubmit}
        okButtonProps={{
          disabled: isHaveReachedUserInvitationLimit,
          onClick: () => {
            form.submit();
          },
        }}
        cancelText={TextConstants.Common.Cancel}
        okText={TextConstants.Common.Invitation}
        onCancel={() => {
          onClose(false);
        }}
        cancelButtonProps={{ disabled: loading }}
        confirmLoading={loading}
        width={800}
      >
        {isHaveReachedUserInvitationLimit && (
          <Alert
            message={`${
              TextConstants.Common.MaxTotalNumberInvitedUserInvalid
            }${TextConstants.Common.InvalidMaxNumberMessage.replace(
              "{num}",
              MAX_NUM_USERS_INVITED
            )}(${
              1 + totalEmaisIndex.length + users.length
            }/${MAX_NUM_USERS_INVITED})`}
            type="warning"
            showIcon
            style={{ marginBottom: 20 }}
          />
        )}
        <CustomForm
          form={form}
          name="basic"
          initialValues={formInitValues}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[24, 0]}>
            <Col lg={{ span: 4 }} md={{ span: 5 }} xs={{ span: 24 }}>
              <label htmlFor="role">
                <Typography.Text type="danger">*</Typography.Text>
                {TextConstants.Common.Role}：
              </label>
            </Col>
            <Col lg={{ span: 20 }} md={{ span: 19 }} xs={{ span: 24 }}>
              <Form.Item
                name="role"
                style={{
                  maxWidth: 250,
                }}
              >
                <Select
                  disabled={loading || isHaveReachedUserInvitationLimit}
                  onChange={() => {
                    setTotalEmaisIndex([]);
                  }}
                  options={[
                    {
                      label: TextConstants.ROLES[ROLES.FA],
                      value: ROLES.FA,
                    },
                    // TODO: Disabled the Setup user
                    // {
                    //   label: TextConstants.ROLES[ROLES.SU],
                    //   value: ROLES.SU,
                    // },
                  ]}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col lg={{ span: 4 }} md={{ span: 5 }} xs={{ span: 24 }}>
              <label>
                <Typography.Text type="danger">*</Typography.Text>
                {TextConstants.NewFacility.InviteUsers}：
              </label>
            </Col>
            <Col lg={{ span: 20 }} md={{ span: 19 }} xs={{ span: 24 }}>
              <Row key={"first-item"} gutter={[24, 0]}>
                <Col lg={{ span: 10 }} md={{ span: 10 }} xs={{ span: 24 }}>
                  <Form.Item
                    key={0}
                    name={`email_0`}
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                      {
                        pattern: ASCII_REGEX,
                        message: TextConstants.Common.OnlyAcceptASCIIMessage,
                      },
                      {
                        max: 254,
                        message:
                          TextConstants.Common.MaxLengthWarningMessage.replace(
                            "{max}",
                            "254"
                          ),
                      },
                    ]}
                  >
                    <Input
                      id={`email_0`}
                      placeholder={`${TextConstants.Common.EmailAddress} #1`}
                      disabled={loading || isHaveReachedUserInvitationLimit}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 14 }} md={{ span: 14 }} xs={{ span: 24 }}>
                  {selectedRole === ROLES.FA && (
                    <Form.Item
                      style={{ width: 230, marginRight: 20, float: "left" }}
                      key={`admin_role_0`}
                      name={`admin_role_0`}
                    >
                      <Select
                        defaultValue={FACILITY_ADMIN_ROLES.GUEST}
                        options={
                          isFacilityOwner
                            ? facilityAdminRoleOptions
                            : facilityAdminRoleOptionsForGuest
                        }
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>

              {totalEmaisIndex.map((indexNum, i) => {
                const index = i + 1;
                return (
                  <Row gutter={[24, 0]}>
                    <Col lg={{ span: 10 }} md={{ span: 10 }} xs={{ span: 24 }}>
                      <Form.Item
                        key={`email_${indexNum}`}
                        name={`email_${indexNum}`}
                        rules={[
                          {
                            required: true,
                            type: "email",
                          },
                          {
                            pattern: ASCII_REGEX,
                            message:
                              TextConstants.Common.OnlyAcceptASCIIMessage,
                          },
                          {
                            max: 254,
                            message:
                              TextConstants.Common.MaxLengthWarningMessage.replace(
                                "{max}",
                                "254"
                              ),
                          },
                        ]}
                        style={{ marginBottom: 10 }}
                      >
                        <Input
                          placeholder={`${TextConstants.Common.EmailAddress} #${
                            index + 1
                          }`}
                          disabled={loading || isHaveReachedUserInvitationLimit}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 14 }} md={{ span: 12 }} xs={{ span: 24 }}>
                      {selectedRole === ROLES.FA && (
                        <Form.Item
                          style={{ width: 230, marginRight: 20, float: "left" }}
                          key={`admin_role_${indexNum}`}
                          name={`admin_role_${indexNum}`}
                        >
                          <Select
                            defaultValue={FACILITY_ADMIN_ROLES.GUEST}
                            options={facilityAdminRoleOptions}
                          />
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Button
                          disabled={loading}
                          type="danger"
                          onClick={() => {
                            setTotalEmaisIndex(
                              totalEmaisIndex.filter(
                                (index) => index !== indexNum
                              )
                            );
                          }}
                        >
                          <MinusOutlined />
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
              {isNotLimitNumberEmail && (
                <Form.Item>
                  <Button
                    disabled={
                      loading ||
                      isHaveReachedUserInvitationLimit ||
                      totalEmaisIndex.length + 1 >=
                        MAX_NUM_USERS_INVITE_ONE_TIME
                    }
                    type="primary"
                    onClick={() => {
                      const maxIndex =
                        totalEmaisIndex.length > 0
                          ? Math.max(...totalEmaisIndex)
                          : -1;
                      setTotalEmaisIndex([...totalEmaisIndex, maxIndex + 2]);
                      form.setFieldValue(
                        `admin_role_${maxIndex + 2}`,
                        FACILITY_ADMIN_ROLES.GUEST
                      );
                    }}
                    icon={<PlusOutlined />}
                  >
                    {TextConstants.Facility.AddUsersToInvite}
                  </Button>
                </Form.Item>
              )}
              {selectedRole === ROLES.FA && (
                <Form.Item>
                  <Alert
                    message={`一度の招待できる数は50になります。${
                      totalEmaisIndex.length + 1
                    }/${MAX_NUM_USERS_INVITE_ONE_TIME}`}
                    type="info"
                    showIcon
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </CustomForm>
      </Modal>
    </React.Fragment>
  );
};

export default FacilityCreateUserModal;
