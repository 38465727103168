import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import client from "src/helpers/HTTPClient";
import { Notification } from "src/helpers/Notification";
import TextConstants from "src/constants/TextConstants";
import * as Actions from "../actions";
import { createActionStates } from "../utils";
import { API_ENDPOINTS } from "src/constants";

function* getCompaniesSaga({ type }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.get, API_ENDPOINTS.COMPANIES);

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* createCompanySaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.post, API_ENDPOINTS.COMPANIES, payload);

    yield put(onSuccess(response));
    Notification.success(TextConstants.CreateCompanyModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* updateCompanySaga({ type, payload: { companyId, data }, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.COMPANIES}/${companyId}`;
    const response = yield call(client.put, endpoint, data);

    yield put(onSuccess(response));
    Notification.success(TextConstants.UpdateCompanyModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteCompanySaga({ type, payload: companyId, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.COMPANIES}/${companyId}`;
    yield call(client.delete, endpoint);

    yield put(onSuccess());
    Notification.success(TextConstants.DeleteCompanyModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchCompaniesSagas() {
  yield takeEvery(Actions.GET_COMPANIES.REQUEST, getCompaniesSaga);
  yield takeEvery(Actions.CREATE_COMPANY.REQUEST, createCompanySaga);
  yield takeEvery(Actions.UPDATE_COMPANY.REQUEST, updateCompanySaga);
  yield takeEvery(Actions.DELETE_COMPANY.REQUEST, deleteCompanySaga);
}

export default function* rootSaga() {
  yield all([fork(watchCompaniesSagas)]);
}
