import { Button, Col, Divider, Popconfirm, Row, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddButton from "src/components/AddButton";
import BorderTable from "src/components/BorderTable";
import CustomCard from "src/components/CustomCard/CustomCard";
import { FACILITY_USERS_STATUS, ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import {
  createUser,
  deleteFacilityUser,
  getFacilityUsers,
} from "src/store/actions";
import FacilityCreateUserModal from "./FacilityCreateUserModal";

const FacilityUserManagement = () => {
  const dispatch = useDispatch();
  const { facilityId } = useParams();
  const navigate = useNavigate();

  const { user: loggedInUser } = useSelector((state) => state.Auth);
  const { loading, users } = useSelector((state) => state.Users);
  const { loading: facilitiesLoading, facilities } = useSelector(
    (state) => state.Facilities
  );

  const [showInvitingModal, setShowInvitingModal] = useState(false);

  const targetFacility = useMemo(() => {
    return facilities.filter(
      (facility) => facility.facilityId === facilityId
    )[0];
  }, [facilities, facilityId]);

  const fetchFacilityUsers = useCallback(() => {
    dispatch(getFacilityUsers(facilityId));
  }, [dispatch, facilityId]);

  useEffect(() => {
    fetchFacilityUsers();
  }, [dispatch, fetchFacilityUsers]);

  const handleOpenCreateModal = () => setShowInvitingModal(true);

  const handleCreateUser = (values) => {
    if (values.companyId && values.companyId === "-") {
      delete values.companyId;
    }
    dispatch(
      createUser(values, () => {
        setShowInvitingModal(false);
        fetchFacilityUsers();
      })
    );
  };

  const handleCloseCreateModal = (isInvitedSuccess) => {
    setShowInvitingModal(false);
    isInvitedSuccess && fetchFacilityUsers();
  };

  const handleDeleteUser = useCallback(
    (email) => {
      dispatch(deleteFacilityUser({ facilityId, email }, fetchFacilityUsers));
    },
    [dispatch, facilityId]
  );

  const membersDataMapped = useMemo(() => {
    return users
      .filter((user) => user.status === FACILITY_USERS_STATUS.ACCEPTED)
      .map((user) => {
        return {
          ...user,
          roleLabel: TextConstants.ROLES[user.role],
        };
      });
  }, [users]);

  const membersInvitingDataMapped = useMemo(() => {
    return users
      .filter((user) => user.status === FACILITY_USERS_STATUS.PENDING)
      .map((user) => {
        return {
          ...user,
          roleLabel: TextConstants.ROLES[user.role],
        };
      });
  }, [users]);

  const memberColumns = [
    {
      title: TextConstants.Common.EmailAddress,
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: "25%",
    },
    {
      title: TextConstants.Common.EmployeeName,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "20%",
      defaultSortOrder: "ascend", // Set default sorting order
    },
    {
      title: TextConstants.Common.Role,
      dataIndex: "roleLabel",
      key: "roleLabel",
      sorter: (a, b) => a.roleLabel.localeCompare(b.roleLabel),
      width: "20%",
    },
    {
      title: TextConstants.Common.CompanyName,
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      width: "20%",
    },
    {
      title: TextConstants.Common.Actions,
      dataIndex: "_actions",
      key: "_actions",
      canSearch: false,
      width: "15%",
      render: (_, record) => (
        <Row justify="center" gutter={[8, 6]}>
          <Col>
            <Popconfirm
              disabled={
                loggedInUser.role === ROLES.FA &&
                record.email === loggedInUser.email ||
                (targetFacility && !targetFacility.isOwner)
              }
              title={TextConstants.Common.ConfirmDeleteMessage.replace(
                "{item}",
                TextConstants.Common.User
              )}
              onConfirm={() => handleDeleteUser(record.email)}
              okText={TextConstants.Common.Delete}
              cancelText={TextConstants.Common.Cancel}
              placement="topRight"
            >
              <Button
                disabled={
                  loggedInUser.role === ROLES.FA &&
                  record.email === loggedInUser.email ||
                  (targetFacility && !targetFacility.isOwner)
                }
                size="small"
                type="danger"
              >
                {TextConstants.Common.Delete}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      {showInvitingModal && (
        <FacilityCreateUserModal
          onClose={handleCloseCreateModal}
          onSubmit={handleCreateUser}
          facilityId={facilityId}
          membersInviting={membersInvitingDataMapped}
          isFacilityOwner={targetFacility && targetFacility.isOwner}
        />
      )}
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <CustomCard
            className="common-card tablespace"
            title={TextConstants.Pages.UserManagement}
            onGoBack={onGoBack}
          >
            <Divider
              orientation="left"
              plain
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              <Typography.Title level={5}>
                {TextConstants.Common.Member}
              </Typography.Title>
            </Divider>
            <BorderTable
              header={loggedInUser.role == ROLES.FA && <AddButton onClick={handleOpenCreateModal} />}
              showSearch
              loading={loading}
              columns={memberColumns}
              dataSource={membersDataMapped}
              rowKey={"email"}
              searchInputPlaceHolder={
                TextConstants.Facility.SearchUsersPlaceHolder
              }
            />

            <Divider orientation="left" plain style={{ marginBottom: 0 }}>
              <Typography.Title level={5}>
                {TextConstants.Common.Inviting}
              </Typography.Title>
            </Divider>
            <BorderTable
              showSearch
              loading={loading}
              columns={memberColumns}
              dataSource={membersInvitingDataMapped}
              rowKey={"email"}
              searchInputPlaceHolder={
                TextConstants.Facility.SearchUsersPlaceHolder
              }
            />
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FacilityUserManagement;
