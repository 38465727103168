import { Divider } from "antd";
import React from "react";
import BorderTable from "src/components/BorderTable";
import TextConstants from "src/constants/TextConstants";

const GroupSettingsV2 = ({ groups = [], loading = false }) => {
  const columns = [
    {
      title: TextConstants.FloorBMLiteInformation.ConferenceRoom,
      dataIndex: "label",
      key: "label",
    },
    {
      title: TextConstants.FloorBMLiteInformation.Number,
      dataIndex: "numOfLights",
      key: "numOfLights",
      render: (v) => {
        return (
          <>
            {TextConstants.FloorBMLiteInformation.Number}：{v}
          </>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Divider orientation="left">
        {TextConstants.FloorBMLiteInformation.GroupSettings}
      </Divider>
      <BorderTable
        loading={loading}
        columns={columns}
        dataSource={groups}
        rowKey={"id"}
        showPagination={false}
        minHeight={0}
      />
    </React.Fragment>
  );
};

export default GroupSettingsV2;
