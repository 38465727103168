import { Alert, Table, Typography } from "antd";
import React, { useMemo } from "react";
import TextConstants from "src/constants/TextConstants";
import YesNoSelector from "../newFloor/components/YesNoSelector/YesNoSelector";

const columns = [
  {
    title: "_question",
    dataIndex: "_question",
    width: "80%",
  },
  {
    title: "_answer",
    dataIndex: "_answer",
    align: "center",
    colSpan: 18,
    fixed: 'right',
  },
];

const Survey = ({ form }) => {
  const data = useMemo(() => {
    return [
      {
        key: "1",
        _question: (
          <>
            <Typography.Text strong style={{ marginRight: 10 }}>
              1.
            </Typography.Text>
            <Typography.Text>
              金属で囲まれた区画はない。（アルミ・スチール・鉄筋コンクリート等）
              <Typography.Text type="danger">*</Typography.Text>
            </Typography.Text>
          </>
        ),
        _answer: (
          <YesNoSelector name={"isNoAreasSurroundedByMetal"} form={form} />
        ),
      },
      {
        key: "2",
        _question: (
          <>
            <Typography.Text strong style={{ marginRight: 10 }}>
              2.
            </Typography.Text>
            <Typography.Text>
              照明と照明の間に大きな梁はない。（目安：高さ 1ｍ幅 0.5ｍ）
              <Typography.Text type="danger">*</Typography.Text>
            </Typography.Text>
          </>
        ),
        _answer: (
          <YesNoSelector name={"isNoLargeBeamsBetweenTheLights"} form={form} />
        ),
      },
      {
        key: "3",
        _question: (
          <>
            <Typography.Text strong style={{ marginRight: 10 }}>
              3.
            </Typography.Text>
            <Typography.Text>
              防火シャッターを下ろした状態での運用予定はなし。
              <Typography.Text type="danger">*</Typography.Text>
            </Typography.Text>
          </>
        ),
        _answer: (
          <YesNoSelector
            name={"isNoPlansToOperateWithTheFireShuttersDown"}
            form={form}
          />
        ),
      },
      {
        key: "4",
        _question: (
          <>
            <Typography.Text strong style={{ marginRight: 10 }}>
              4.
            </Typography.Text>
            <Typography.Text>
              荷物を高く積み上げる場所はない。（積荷が通信障害の原因になる可能性はない。）
              <Typography.Text type="danger">*</Typography.Text>
            </Typography.Text>
          </>
        ),
        _answer: (
          <YesNoSelector name={"isNoPlaceToStackYourLuggageHigh"} form={form} />
        ),
      },
      {
        key: "5",
        _question: (
          <>
            <Typography.Text strong style={{ marginRight: 10 }}>
              5.
            </Typography.Text>
            <Typography.Text>
              最寄りの照明との距離が 15m 以上離れている照明が存在しない
              <Typography.Text type="danger">*</Typography.Text>
            </Typography.Text>
          </>
        ),
        _answer: (
          <YesNoSelector
            name={"isNoLightsThatAreMoreThan15mAwayFromTheNearestLight"}
            form={form}
          />
        ),
      },
      {
        key: "6",
        _question: (
          <>
            <Typography.Text strong style={{ marginRight: 10 }}>
              6.
            </Typography.Text>
            <Typography.Text>
              設置予定場所の近くに他の LiCONEX-lite のシステムが存在しないか。
            </Typography.Text>
          </>
        ),
        _answer: (
          <YesNoSelector
            name={"isLicoLiteSystemsNearPlannedInstallLocation"}
            form={form}
            requiredYes={false}
          />
        ),
      },
    ];
  }, [form]);

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        showHeader={false}
        size="small"
        style={{ overflow: "auto", width: "100%" }}
      />
      <Alert
        message={TextConstants.NewFloorMapSurvey.ConditionsMetWarningMessage}
        type="warning"
        showIcon
        style={{ marginTop: 10 }}
      />
    </React.Fragment>
  );
};

export default Survey;
