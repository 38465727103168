import * as Types from "src/store/actions";

export const login = (params, callback = null) => ({
  type: Types.LOGIN.REQUEST,
  payload: params,
  callback,
});

export const logout = (params, callback = null) => ({
  type: Types.LOGOUT.REQUEST,
  payload: params,
  callback,
});

export const signup = (params, callback = null) => ({
  type: Types.SIGNUP.REQUEST,
  payload: params,
  callback,
});

export const passwordResetRequest = (params, callback = null) => ({
  type: Types.PASSWORD_RESET_REQUEST.REQUEST,
  payload: params,
  callback,
});

export const resetPassword = (params, callback = null) => ({
  type: Types.RESET_PASSWORD.REQUEST,
  payload: params,
  callback,
});

export const confirmEmailAddress = (params, callback = null) => ({
  type: Types.CONFIRM_EMAIL_ADDRESS.REQUEST,
  payload: params,
  callback,
});
