import { Card, Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { getFacilities } from "src/store/actions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.Auth);
  const { loading, facilities, fetchedAt } = useSelector(
    (state) => state.Facilities
  );

  useEffect(() => {
    if (!fetchedAt || new Date().getTime() - fetchedAt > 1000) {
      dispatch(getFacilities());
    }
  }, [dispatch, fetchedAt]);

  useEffect(() => {
    if (fetchedAt && new Date().getTime() - fetchedAt <= 1000) {
      if (facilities.length > 0) {
        const firstItem = [...facilities].sort((a, b) =>
          a.facilityName.localeCompare(b.facilityName)
        )[0];

        navigate(
          PATHS.FACILITY_DETAIL.replace(":facilityId", firstItem.facilityId)
        );
      } else {
        if (user.role === ROLES.FA) {
          navigate(PATHS.NEW_FACILITY);
        }
      }
    }
  }, [facilities, fetchedAt, user]);

  return (
    <React.Fragment>
      <Card
        bordered={true}
        className="common-card criclebox tablespace mb-24"
        title={TextConstants.Pages.Dashboard}
      >
        <Row gutter={[24, 0]}>
          <Col xs={{ span: 24 }}>
            {loading && (
              <>
                <Spin style={{ marginRight: 10 }} />
                {TextConstants.Common.Loading}
              </>
            )}
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default Dashboard;
