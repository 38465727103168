import * as Types from "src/store/actions";

export const getAllFeedback = (params, callback = null) => ({
  type: Types.GET_ALL_FEEDBACK.REQUEST,
  payload: params,
  callback,
});

export const submitFeedback = (params, callback = null) => ({
  type: Types.SUBMIT_FEEDBACK.REQUEST,
  payload: params,
  callback,
});
