import { DeleteFilled, EditOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Popconfirm,
  Row,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddButton from "src/components/AddButton";
import BorderTable from "src/components/BorderTable";
import FloorStatus from "src/components/FloorStatus";
import { FLOOR_SETTINGS_MODE_LABEL, ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { deleteFloor, getFloors } from "src/store/actions";
import DeleteFacilityModal from "./DeleteFacilityModal";
import EditFacilityModal from "./EditFacilityModal";

const Facility = () => {
  const dispatch = useDispatch();
  const { facilityId } = useParams();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.Auth);
  const { loading, floors } = useSelector((state) => state.Floors);
  const { loading: facilitiesLoading, facilities } = useSelector(
    (state) => state.Facilities
  );

  const [isShowFacilityEditModal, setIsShowFacilityEditModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const targetFacility = useMemo(() => {
    return facilities.filter(
      (facility) => facility.facilityId === facilityId
    )[0];
  }, [facilities, facilityId]);

  const fetchFloors = useCallback(() => {
    dispatch(getFloors(facilityId));
  }, [dispatch, facilityId]);

  useEffect(() => {
    fetchFloors();
  }, [fetchFloors]);

  const moveToEditFloorScreen = useCallback(
    (floorId) => {
      navigate(
        PATHS.EDIT_FACILITY_FLOOR.replace(":facilityId", facilityId).replace(
          ":floorId",
          floorId
        )
      );
    },
    [facilityId]
  );

  const handleDeleteFloor = useCallback(
    (floorId) => {
      dispatch(deleteFloor({ facilityId, floorId }, fetchFloors));
    },
    [facilityId, dispatch]
  );

  const deletedSuccessCallback = useCallback(() => {
    fetchFloors();
    setIsShowDeleteModal(false);
    navigate(PATHS.DASHBOARD);
  }, [navigate, fetchFloors, setIsShowDeleteModal]);

  const floorsDataMapped = useMemo(() => {
    return floors.map((floor) => {
      return {
        ...floor,
        lightFWVersion: floor.lightFWVersion || "-",
        bmFWVersion: floor.bmFWVersion || "-",
        settingModeLabel: FLOOR_SETTINGS_MODE_LABEL[floor.settingMode],
        statusLabel: floor.bmId
          ? TextConstants.FacilityFloors.FloorStatus.AlreadySetup
          : TextConstants.FacilityFloors.FloorStatus.WaitingForSettings,
        _information: `${TextConstants.FacilityFloors.FloorSize}：${(
          floor.dimensions || []
        ).join("x")}、${TextConstants.FacilityFloors.TotalLightsLabel}：${
          isNaN(floor.numberLights) ? "--" : floor.numberLights
        }`,
        createdAt: dayjs(floor.createdAt).format("YYYY/MM/DD HH:mm:ss"),
      };
    });
  }, [floors]);

  const columns = [
    {
      title: TextConstants.Common.Name,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "20%",
      defaultSortOrder: "ascend", // Set default sorting order
    },
    {
      title: TextConstants.Common.Mode,
      dataIndex: "settingModeLabel",
      key: "settingModeLabel",
      sorter: (a, b) => a.settingModeLabel.localeCompare(b.settingModeLabel),
      width: "10%",
    },
    {
      title: TextConstants.Common.Status,
      dataIndex: "statusLabel",
      key: "statusLabel",
      sorter: (a, b) => a.statusLabel.localeCompare(b.statusLabel),
      width: "10%",
      render: (_, record) => <FloorStatus registered={record.bmId} />,
    },
    {
      title: TextConstants.Common.Information,
      dataIndex: "_information",
      key: "_information",
      width: "20%",
    },
    {
      title: TextConstants.Common.BMFWVersion,
      dataIndex: "bmFWVersion",
      key: "bmFWVersion",
      width: "10%",
    },
    {
      title: TextConstants.Common.LightFWVersion,
      dataIndex: "lightFWVersion",
      key: "lightFWVersion",
      width: "10%",
    },
    {
      title: TextConstants.Common.CreatedAt,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      width: "10%",
    },
    {
      title: TextConstants.Common.Actions,
      dataIndex: "_actions",
      key: "_actions",
      canSearch: false,
      width: "10%",
      render: (_, record) => (
        <Row justify="center" gutter={[8, 6]}>
          <Col>
            <Button
              type="primary"
              size="small"
              onClick={() => moveToEditFloorScreen(record.floorId)}
            >
              {TextConstants.Common.Edit}
            </Button>
          </Col>
          <Col>
            {user.role === ROLES.FA && (
              <Popconfirm
                title={TextConstants.Common.ConfirmDeleteMessage.replace(
                  "{item}",
                  TextConstants.Common.Floor
                )}
                onConfirm={() => handleDeleteFloor(record.floorId)}
                okText={TextConstants.Common.Delete}
                cancelText={TextConstants.Common.Cancel}
                placement="topRight"
              >
                <Button size="small" type="danger">
                  {TextConstants.Common.Delete}
                </Button>
              </Popconfirm>
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const moveToCreateNewFloor = useCallback(() => {
    navigate(PATHS.NEW_FACILITY_FLOOR.replace(":facilityId", facilityId));
  }, [navigate, facilityId]);

  const moveToFacilityUserMangement = useCallback(() => {
    navigate(PATHS.FACILITY_USER_MANAGEMENT.replace(":facilityId", facilityId));
  }, [navigate, facilityId]);

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card
            className="common-card tablespace"
            title={targetFacility ? targetFacility.facilityName : "--"}
            // title={TextConstants.Common.FloorList}
            extra={
              <Space size={"small"}>
                <Button
                  type="primary"
                  onClick={moveToFacilityUserMangement}
                  icon={<UserOutlined />}
                >
                  {TextConstants.Pages.UserManagement}
                </Button>
                {targetFacility && targetFacility.isOwner && (
                  <>
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setIsShowFacilityEditModal(true);
                      }}
                    >
                      {TextConstants.Facility.EditFacilityName}
                    </Button>
                    <Button
                      type="primary"
                      danger
                      icon={<DeleteFilled />}
                      onClick={() => {
                        setIsShowDeleteModal(true);
                      }}
                    >
                      {TextConstants.Facility.DeleteFacility}
                    </Button>
                  </>
                )}
              </Space>
            }
          >
            <Divider
              orientation="left"
              plain
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              <Typography.Title level={5}>
                {TextConstants.Common.FloorList}
              </Typography.Title>
            </Divider>
            <BorderTable
              showSearch
              loading={loading}
              columns={columns}
              dataSource={floorsDataMapped}
              rowKey={"floorId"}
              header={
                user.role === ROLES.FA ? (
                  <>
                    <AddButton
                      onClick={moveToCreateNewFloor}
                      label={TextConstants.Facility.AddFloor}
                    />
                  </>
                ) : (
                  <></>
                )
              }
              searchInputPlaceHolder={
                TextConstants.Facility.SearchFloorPlaceHolder
              }
            />
          </Card>
        </Col>
      </Row>
      <DeleteFacilityModal
        facility={targetFacility}
        facilitiesLoading={facilitiesLoading}
        deletedSuccessCallback={deletedSuccessCallback}
        isModalOpen={isShowDeleteModal}
        setIsOpenModal={setIsShowDeleteModal}
      />

      {isShowFacilityEditModal && (
        <EditFacilityModal
          setIsShowFacilityEditModal={setIsShowFacilityEditModal}
          facility={targetFacility}
        />
      )}
    </React.Fragment>
  );
};

export default Facility;
