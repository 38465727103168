import { Space, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BorderTable from "src/components/BorderTable";
import LightColorCircle from "src/components/LightColorCircle";
import { DAY_IN_WEEK_LABELS } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import {
  parseSwitchBrightnessValueToUIReadable,
  parseSwitchColorValueToUIReadable,
  parseSwitchConfigValueToText,
} from "src/helpers/helpers";
import { getFloorOperationLogs } from "src/store/actions";
const { Text } = Typography;

const mockData = [
  {
    key: "1",
    datetime: "2024/10/01 08:00",
    scheduleOperation: (
      <>
        08:00 <br />
        月、火、水、木、金、土、日 <br />
        明るさ: 50% <br />
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    switchOperation: (
      <>
        Rem1 <br />
        Button 1: 明るさ 100%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
        <br />
        Button 2: 明るさ 100%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    sensorOperation: (
      <>
        グループ 1: 利用しない <br />
        グループ 2: 人感センサー <br />
        グループ 3: 明るさセンサー
      </>
    ),
  },
  {
    key: "2",
    datetime: "2024/10/02 09:00",
    scheduleOperation: (
      <>
        09:00 <br />
        月、火、水、木、金 <br />
        明るさ: 70% <br />
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    switchOperation: (
      <>
        Rem2 <br />
        Button 1: 明るさ 80%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
        <br />
        Button 2: 明るさ 90%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    sensorOperation: (
      <>
        グループ 1: 利用しない <br />
        グループ 2: 人感センサー <br />
        グループ 3: 明るさセンサー
      </>
    ),
  },
  {
    key: "3",
    datetime: "2024/10/03 10:00",
    scheduleOperation: (
      <>
        10:00 <br />
        月、水、金 <br />
        明るさ: 60% <br />
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    switchOperation: (
      <>
        Rem3 <br />
        Button 1: 明るさ 100%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
        <br />
        Button 2: 明るさ 100%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    sensorOperation: (
      <>
        グループ 1: 利用しない <br />
        グループ 2: 人感センサー <br />
        グループ 3: 明るさセンサー
      </>
    ),
  },
  {
    key: "4",
    datetime: "2024/10/04 11:00",
    scheduleOperation: (
      <>
        11:00 <br />
        火、木、土 <br />
        明るさ: 40% <br />
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    switchOperation: (
      <>
        Rem4 <br />
        Button 1: 明るさ 85%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
        <br />
        Button 2: 明るさ 95%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    sensorOperation: (
      <>
        グループ 1: 利用しない <br />
        グループ 2: 利用しない <br />
        グループ 3: 明るさセンサー
      </>
    ),
  },
  {
    key: "5",
    datetime: "2024/10/05 12:00",
    scheduleOperation: (
      <>
        12:00 <br />
        月、金 <br />
        明るさ: 90% <br />
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    switchOperation: (
      <>
        Rem5 <br />
        Button 1: 明るさ 100%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
        <br />
        Button 2: 明るさ 70%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    sensorOperation: (
      <>
        グループ 1: 利用しない <br />
        グループ 2: 人感センサー <br />
        グループ 3: 利用しない
      </>
    ),
  },
  {
    key: "6",
    datetime: "2024/10/06 13:00",
    scheduleOperation: (
      <>
        13:00 <br />
        水、木、日 <br />
        明るさ: 30% <br />
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    switchOperation: (
      <>
        Rem6 <br />
        Button 1: 明るさ 75%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
        <br />
        Button 2: 明るさ 80%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    sensorOperation: (
      <>
        グループ 1: 利用しない <br />
        グループ 2: 人感センサー <br />
        グループ 3: 明るさセンサー
      </>
    ),
  },
  {
    key: "7",
    datetime: "2024/10/07 14:00",
    scheduleOperation: (
      <>
        14:00 <br />
        月、火、木、土 <br />
        明るさ: 20% <br />
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    switchOperation: (
      <>
        Rem7 <br />
        Button 1: 明るさ 90%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
        <br />
        Button 2: 明るさ 95%,{" "}
        <Space>
          調色:
          <LightColorCircle color={6} />
        </Space>
      </>
    ),
    sensorOperation: (
      <>
        グループ 1: 利用しない <br />
        グループ 2: 利用しない <br />
        グループ 3: 明るさセンサー
      </>
    ),
  },
];

const getSensorModeName = (mode) => {
  switch (mode) {
    case 1:
      return "人感センサー";
    case 2:
      return "明るさセンサー";
    default:
      return "利用しない";
  }
};

const OperationLogs = () => {
  const { facilityId, floorId } = useParams();
  const dispatch = useDispatch();
  const { floorOperationLogs, loading } = useSelector((state) => state.Floors);

  useEffect(() => {
    if (facilityId && floorId) {
      dispatch(getFloorOperationLogs({ facilityId, floorId }));
    }
  }, [floorId, facilityId]);

  const columns = [
    {
      title: "日時",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (value) => {
        return dayjs(value).format("YYYY/MM/DD HH:mm:ss");
      },
    },
    {
      title: "スケジュール動作",
      dataIndex: "data",
      key: "scheduleOperation",
      render: (value) => {
        return (
          <>
            {(value.scheduleOperation || []).map((schedule, index) => {
              return (
                <div
                  key={index}
                  style={{ marginBottom: 6, fontSize: "smaller" }}
                >
                  &#8226;{" "}
                  {(schedule.days || [])
                    .map((day) => {
                      return DAY_IN_WEEK_LABELS[day];
                    })
                    .join("、")}{" "}
                  (
                  <Text strong>
                    {schedule.startHour}:{schedule.startMin}
                  </Text>
                  )
                  {(schedule.groups || []).map((g) => {
                    return (
                      <p>
                        {TextConstants.Common.GroupName}:{" "}
                        {value.groupNameByIDs
                          ? value.groupNameByIDs[String(g.groupID)]  || "--"
                          : "--"}
                        、{TextConstants.FloorBMLiteInformation.Brightness}:{" "}
                        {g.brightness}% 、
                        {TextConstants.FloorBMLiteInformation.ColorAdjustment}:{" "}
                        <LightColorCircle color={g.color} />
                      </p>
                    );
                  })}
                </div>
              );
            })}
          </>
        );
      },
    },
    // "buttons": [
    // {
    //  "buttonID": 0,
    //  "configType": 0,
    //  "mode": 0,
    //  "settings": [
    //   {
    //    "brightness": 101,
    //    "color": 255,
    //    "groupID": 0
    //   }
    //  ]
    // },
    {
      title: "スイッチ動作",
      dataIndex: "data",
      key: "switchOperation",
      render: (record) => {
        const sensorOperations = record.switchOperation || [];
        return (
          <>
            {sensorOperations.map((ss) => {
              return (
                <>
                  <div
                    key={ss.mac}
                    style={{ marginBottom: 6, fontSize: "smaller" }}
                  >
                    &#8226; {TextConstants.Common.Name}: {ss.name} (MAC:{" "}
                    {ss.mac})
                    <div>
                      {ss.buttons.map((btn) => {
                        return (
                          <div>
                            <p>Button NO: {btn.buttonID}</p>
                            {btn.mode === 0 && (
                              <>
                                {TextConstants.Common.Mode} :{" "}
                                {
                                  TextConstants.FloorBMLiteInformation
                                    .SimpleSettings
                                }
                                {btn.settings?.length > 0 && (
                                  <>
                                    <p>
                                      {TextConstants.Common.GroupName}:{" "}
                                      {record.groupNameByIDs
                                        ? record.groupNameByIDs[
                                            String(btn.settings[0].groupID)
                                          ] || "--"
                                        : "--"}
                                    </p>
                                    {btn.configType === 0 && ( // simple mode: Brightness
                                      <p>
                                        {
                                          TextConstants.FloorBMLiteInformation
                                            .Brightness
                                        }
                                        :{" "}
                                        {parseSwitchConfigValueToText(
                                          parseSwitchBrightnessValueToUIReadable(
                                            btn.settings[0].brightness
                                          ),
                                          record.dimmingStepVal
                                        )}
                                      </p>
                                    )}
                                    {btn.configType === 1 && ( // simple mode: colors
                                      <p>
                                        {
                                          TextConstants.FloorBMLiteInformation
                                            .ColorAdjustment
                                        }
                                        :{" "}
                                        {parseSwitchConfigValueToText(
                                          parseSwitchColorValueToUIReadable(
                                            btn.settings[0].color
                                          ),
                                          record.dimmingStepVal
                                        )}
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {btn.mode === 1 && (
                              <>
                                {TextConstants.Common.Mode} :{" "}
                                {
                                  TextConstants.FloorBMLiteInformation
                                    .DetailedSettings
                                }
                                {btn.settings?.length > 0 && (
                                  <>
                                    {btn.settings.map((st) => {
                                      return (
                                        <p>
                                          {TextConstants.Common.GroupName}:{" "}
                                          {record.groupNameByIDs
                                            ? record.groupNameByIDs[
                                                String(st.groupID)
                                              ] || "--"
                                            : "--"}
                                          、
                                          {
                                            TextConstants.FloorBMLiteInformation
                                              .Brightness
                                          }
                                          :{" "}
                                          {parseSwitchConfigValueToText(
                                            parseSwitchBrightnessValueToUIReadable(
                                              st.brightness
                                            ),
                                            record.dimmingStepVal
                                          )}
                                          、
                                          {
                                            TextConstants.FloorBMLiteInformation
                                              .ColorAdjustment
                                          }
                                          :{" "}
                                          {parseSwitchConfigValueToText(
                                            parseSwitchColorValueToUIReadable(
                                              st.color
                                            ),
                                            record.dimmingStepVal
                                          )}
                                        </p>
                                      );
                                    })}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: "センサ動作",
      dataIndex: "data",
      key: "sensorOperation",
      render: (record) => {
        const sensorOperation = record.sensorOperation || [];
        return (
          <>
            {sensorOperation.map((ssConfig) => {
              return (
                <div
                  key={ssConfig.groupID}
                  style={{ marginBottom: 6, fontSize: "smaller" }}
                >
                  &#8226; {TextConstants.Common.GroupName}: {ssConfig.label}
                  <div style={{ marginLeft: 8 }}>
                    {TextConstants.Common.Mode}:{" "}
                    {getSensorModeName(ssConfig.mode)}
                  </div>
                  {ssConfig.sensor?.mode === 1 && (
                    <>
                      <div style={{ marginLeft: 8 }}>
                        反応感度(1：大きな動きに反応 9：微細な動きに反応):{" "}
                        {ssConfig.sensor?.sensivity || "--"}
                      </div>
                      <div style={{ marginLeft: 8 }}>
                        点灯調光率: {ssConfig.sensor?.dimmingRate || "--"}%
                      </div>
                      <div style={{ marginLeft: 8 }}>
                        点灯保持時間:{" "}
                        {ssConfig.sensor?.retentionTime.minutes || "0"}分
                        {ssConfig.sensor?.retentionTime.seconds || "0"}秒
                      </div>
                      <div style={{ marginLeft: 8 }}>
                        待機調光率:{" "}
                        {ssConfig.sensor?.standByDimmingRate || "--"}%
                      </div>
                    </>
                  )}
                  {ssConfig.sensor?.mode === 2 && (
                    <>
                      <div style={{ marginLeft: 8 }}>
                        明るさ感度(1：弱い 9：強い):{" "}
                        {ssConfig.sensor?.brightnessValue || "--"}
                      </div>
                      <div style={{ marginLeft: 8 }}>
                        最低調光率: {ssConfig.sensor?.minDimmingRate || "--"}%
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <BorderTable
        showSearch
        columns={columns}
        dataSource={floorOperationLogs}
        rowKey={"key"}
        showPagination={false}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default OperationLogs;
