import React from "react";

const MapViewGuideLines = ({ xLines, yLines, ratio }) => {
  return (
    <>
      {[...Array(yLines || 8)].map((_, idx) => (
        <div
          className="line-to-draw yline"
          key={idx}
          style={{
            top: 0,
            left: (idx + 1) * 100 * (ratio || 1),
          }}
        >
          {(idx + 1) * 100}
        </div>
      ))}
      {[...Array(xLines || 6)].map((_, idx) => (
        <div
          className="line-to-draw xline"
          key={idx}
          style={{
            top: (idx + 1) * 100 * (ratio || 1),
            left: 0,
          }}
        >
          {(idx + 1) * 100}
        </div>
      ))}
    </>
  );
};

export default MapViewGuideLines;
