import { FileDoneOutlined } from "@ant-design/icons";
import { Col, Layout, Row, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "src/assets/images/dark-logo.png";
import { JWT_REGEX } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { parseJwt } from "src/helpers/AuthHelper";
import { Notification } from "src/helpers/Notification";
import { PATHS } from "src/routes";
import { acceptFacilityInvitation } from "src/store/actions";
const { Content } = Layout;

const AcceptFacilityInvitation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { acceptedInvitation, acceptingInvitation } = useSelector(
    (state) => state.Facilities
  );
  const [invitationTitle, setInvitationTitle] = useState(
    "LiCONEX LiTE施設管理者の招待"
  );

  const redirectToLoginPage = () => {
    navigate(`${PATHS.LOGIN}`);
  };

  const successCallback = () => {
    setTimeout(() => {
      redirectToLoginPage();
    }, 2000);
    Notification.success(TextConstants.Common.Success);
  };

  useEffect(() => {
    // get the invitation token
    const query = new URLSearchParams(search);
    const invitationToken = query.get("token");
    if (!invitationToken || !JWT_REGEX.test(invitationToken))
      redirectToLoginPage();
    // parse the token payload and get the username
    const parseToken = parseJwt(invitationToken);

    if (parseToken.exp) {
      const expTime = (parseToken.exp || 0) * 1000;
      if (dayjs().isAfter(dayjs(expTime))) {
        Notification.error(TextConstants.Common.ExpiredLink);
        redirectToLoginPage();
      }
    }

    dispatch(
      acceptFacilityInvitation({ token: invitationToken }, successCallback)
    );
  }, [search, acceptFacilityInvitation, dispatch]);

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 8 }}
            lg={{ span: 12 }}
            md={{ span: 16 }}
            xs={{ span: 24 }}
          >
            <div className="d-flex justify-content-center">
              <img src={logo} alt="LICONEX LITE" className="home-logo" />
            </div>
            <div className="d-flex justify-content-center mb-20">
              <Typography.Title level={3}>
                {invitationTitle}:{" "}
                {acceptingInvitation ? "...受け付けています。" : ""}
                {acceptedInvitation ? (
                  <>
                    <FileDoneOutlined style={{ marginRight: 10 }} />
                    成功裏に承認されました。{" "}
                  </>
                ) : (
                  ""
                )}
              </Typography.Title>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default AcceptFacilityInvitation;
