import { Card, Col, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import BorderTable from "src/components/BorderTable";
import TextConstants from "src/constants/TextConstants";
import { getBMsDeleted } from "src/store/actions/bms";

const BMsDeleted = () => {
  const dispatch = useDispatch();
  const { loading, bmsDeleted } = useSelector((state) => state.Bms);

  useEffect(() => {
    dispatch(getBMsDeleted());
  }, []);

  const bmsDeletedMapped = useMemo(() => {
    return bmsDeleted.map((bm) => {
      return {
        ...bm,
        createdAt: dayjs(bm.createdAt).format("YYYY/MM/DD HH:mm:ss"),
      };
    });
  }, [bmsDeleted]);

  const columns = [
    {
      title: "#",
      dataIndex: "_no",
      key: "_no",
      width: 5,
      render: (_, __, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: TextConstants.Common.DeletedAt,
      dataIndex: "createdAt",
      key: "createdAt",
      width: 20,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      defaultSortOrder: "descend", // Set default sorting order
      render: (value) => {
        return dayjs(value).format("YYYY/MM/DD HH:mm:ss");
      },
    },
    {
      title: TextConstants.Common.CompanyName,
      dataIndex: "companyName",
      key: "companyName",
      width: 25,
      render: (value) => {
        return value || "-";
      },
    },
    {
      title: "BM ID",
      dataIndex: "bmId",
      key: "bmId",
      width: 25,
    },
    {
      title: TextConstants.BMsDeleted.EncryptionKey,
      dataIndex: "encryptionKey",
      key: "encryptionKey",
      width: 25,
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card
            className="common-card tablespace"
            title={TextConstants.Pages.BMsDeleted}
          >
            <BorderTable
              showSearch
              searchInputPlaceHolder={TextConstants.Common.Input}
              loading={loading}
              columns={columns}
              dataSource={bmsDeletedMapped}
              rowKey={"bmId"}
              searchableCols={[
                "createdAt",
                "companyName",
                "bmId",
                "encryptionKey",
              ]}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BMsDeleted;
