import { DashboardFilled } from "@ant-design/icons";
import { Card, Statistic } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import TextConstants from "src/constants/TextConstants";
import GroupSettings from "./GroupSettings/GroupSettings";
import PowerOnSettings from "./PowerOnSettings/PowerOnSettings";
import ScheduleSettings from "./ScheduleSettings/ScheduleSettings";
import SwitchSettings from "./SwitchSettings/SwitchSettings";
import { patchFloorDataToV2 } from "src/helpers/helpers";

const Settings = () => {
  const { facilityFloor, loading, facilityFloorV2, loadingV2Data } =
    useSelector((state) => state.Floors);

  const [floorSettingData, setFloorSettingData] = useState({});
  const [dataGroups, setDataGroups] = useState([]);
  const [dataSwitches, setDataSwitches] = useState([]);
  const [dataSchedules, setDataSchedules] = useState([]);

  const isV2FloorData = useMemo(() => {
    return facilityFloorV2.dataFormatVer && facilityFloorV2.dataFormatVer === 2;
  }, [facilityFloorV2.dataFormatVer]);

  useEffect(() => {
    const floorData =
      facilityFloorV2.dataFormatVer === 2
        ? facilityFloorV2
        : patchFloorDataToV2(facilityFloor);
    setFloorSettingData(floorData);

    const floorDataResult = { groups: [], switches: [], schedules: [] };
    const groupNameMappedByID = {};
    const _dataGroups = [];
    const _dataSwitches = [];
    const _dataSchedulers = [];
    floorData.groups.forEach((g) => {
      groupNameMappedByID[String(g.groupID)] = g.label;
      _dataGroups.push({
        ...g,
        name: g.label,
        numOfLights: (floorData.lights || []).filter(
          (l) => l.groupId === g.groupID
        ).length,
      });
    });
    setDataGroups(_dataGroups);

    (floorData.switches || []).forEach((sw) => {
      _dataSwitches.push({
        ...sw,
        name: `${sw.name}(MAC: ${sw.mac})`,
        buttons: sw.buttons.map((btn) => {
          return {
            ...btn,
            settings: (btn.settings || []).map((st) => {
              return {
                ...st,
                groupName: !isNaN(st.groupID)
                  ? groupNameMappedByID[String(st.groupID)]
                  : "-",
              };
            }),
          };
        }),
      });
    });
    setDataSwitches(_dataSwitches);

    (floorData.schedules || []).forEach((schl) => {
      _dataSchedulers.push({
        ...schl,
        groups: schl.groups.map((gr) => {
          return {
            ...gr,
            groupName: !isNaN(gr.groupID)
              ? groupNameMappedByID[String(gr.groupID)]
              : "-",
          };
        }),
      });
    });
    setDataSchedules(_dataSchedulers);

    return floorDataResult;
  }, [facilityFloorV2, facilityFloor]);

  const loadingCombined = useMemo(
    () => {
      return loading || loadingV2Data;
    },
    loading,
    loadingV2Data
  );

  return (
    <React.Fragment>
      <div className="bm-information-setting-section-container">
        <GroupSettings groups={dataGroups} loading={loadingCombined} />
      </div>
      <div className="bm-information-setting-section-container">
        <SwitchSettings
          switches={dataSwitches}
          loading={loadingCombined}
          dimmingStepVal={floorSettingData.dimmingStep}
        />
      </div>
      <div className="bm-information-setting-section-container">
        <ScheduleSettings
          schedules={dataSchedules}
          loading={loadingCombined}
          dimmingStepVal={floorSettingData.dimmingStep}
        />
      </div>
      <div className="bm-information-setting-section-container">
        <PowerOnSettings groups={dataGroups} loading={loadingCombined} />
      </div>
      <div className="bm-information-setting-section-container">
        <Card bordered={true}>
          <Statistic
            title={TextConstants.FloorBMLiteInformation.BrightnessLimitSettings}
            value={
              isNaN(floorSettingData.maxBrightness)
                ? "-"
                : parseInt(floorSettingData.maxBrightness)
            }
            precision={0}
            valueStyle={{
              color: "#3f8600",
            }}
            prefix={<DashboardFilled />}
            suffix="%"
          />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Settings;
