import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { API_ENDPOINTS } from "src/constants";
import client from "src/helpers/HTTPClient";
import * as Actions from "../actions";
import { createActionStates } from "../utils";

function* getAllFeedback({ type }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.get, API_ENDPOINTS.FEEDBACK);

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* submitNewFeedback({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.post, API_ENDPOINTS.FEEDBACK, payload);

    yield put(onSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchBackendSagas() {
  yield takeEvery(Actions.GET_ALL_FEEDBACK.REQUEST, getAllFeedback);
  yield takeEvery(Actions.SUBMIT_FEEDBACK.REQUEST, submitNewFeedback);
}

export default function* rootSaga() {
  yield all([fork(watchBackendSagas)]);
}
