import produce from "immer";
import * as Actions from "../actions";
import { hashCodeStrToNum } from "src/helpers/helpers";

const initState = {
  loading: false,
  acceptingInvitation: false,
  acceptedInvitation: false,
  facilities: [],
  facilityNameMappedByIds: {},
  facilityInvitations: [],
  facilityInvitationsHash: 0,
  facilityInvitationLoading: false,
  fetchedAt: null,
  type: "",
};

const Facilities = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.ACCEPT_FACILITY_INVITATION.REQUEST:
      return produce(state, (draft) => {
        draft.acceptingInvitation = true;
        draft.type = type;
      });

    case Actions.GET_FACILITY_INVITATIONS.REQUEST:
    case Actions.ACCEPT_FACILITY_INVITATION_VIA_DASHBOARD.REQUEST:
    case Actions.REJECT_FACILITY_INVITATION_VIA_DASHBOARD.REQUEST:
      return produce(state, (draft) => {
        draft.facilityInvitationLoading = true;
        draft.type = type;
      });

    case Actions.GET_FACILITY_INVITATIONS.FAILURE:
    case Actions.ACCEPT_FACILITY_INVITATION_VIA_DASHBOARD.FAILURE:
    case Actions.REJECT_FACILITY_INVITATION_VIA_DASHBOARD.FAILURE:
      return produce(state, (draft) => {
        draft.facilityInvitationLoading = false;
        draft.type = type;
      });

    case Actions.GET_FACILITY_INVITATIONS.SUCCESS:
      return produce(state, (draft) => {
        draft.facilityInvitationLoading = false;
        draft.facilityInvitations = payload;
        draft.facilityInvitationsHash = hashCodeStrToNum(
          (payload || []).map((i) => i.facilityId).join(".")
        );
        draft.type = type;
      });

    case Actions.ACCEPT_FACILITY_INVITATION_VIA_DASHBOARD.SUCCESS:
      const targetFacilityId = payload.facilityId;
      const targetFacilityInvitation = state.facilityInvitations.find(
        (fa) => fa.facilityId === targetFacilityId
      );
      const facilitiesCopied = [...state.facilities];
      if (targetFacilityInvitation) {
        facilitiesCopied.push({
          facilityId: targetFacilityId,
          facilityName: targetFacilityInvitation.facilityName,
          isOwner: false,
        });
      }

      return produce(state, (draft) => {
        draft.type = type;
        draft.facilityInvitationLoading = false;
        draft.facilities = facilitiesCopied;
        draft.facilityInvitations = state.facilityInvitations.filter(
          (fa) => fa.facilityId !== targetFacilityId
        );
      });
    case Actions.REJECT_FACILITY_INVITATION_VIA_DASHBOARD.SUCCESS:
      return produce(state, (draft) => {
        draft.facilityInvitationLoading = false;
        draft.type = type;
        draft.facilityInvitations = state.facilityInvitations.filter(
          (fa) => fa.facilityId !== payload.facilityId
        );
      });

    case Actions.GET_FACILITIES.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
        draft.facilities = [];
      });
    case Actions.CREATE_FACILITY.REQUEST:
    case Actions.UPDATE_FACILITY.REQUEST:
    case Actions.DELETE_FACILITY.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });

    case Actions.GET_FACILITIES.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.facilities = payload;
        const facilityNameMappedByIdsObj = {};
        payload.forEach((fa) => {
          facilityNameMappedByIdsObj[fa.facilityId] = fa.facilityName;
        });
        draft.facilityNameMappedByIds = facilityNameMappedByIdsObj;
        draft.fetchedAt = new Date().getTime();
        draft.type = type;
      });

    case Actions.CREATE_FACILITY.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.facilities = [...state.facilities, { ...payload, isOwner: true }];
        draft.type = type;
      });

    case Actions.UPDATE_FACILITY.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.facilities = state.facilities.map((facility) => {
          if (facility.facilityId === payload.facilityId) {
            return { ...facility, ...payload };
          }
          return facility;
        });
      });

    case Actions.GET_FACILITIES.FAILURE:
    case Actions.CREATE_FACILITY.FAILURE:
    case Actions.UPDATE_FACILITY.FAILURE:
    case Actions.DELETE_FACILITY.FAILURE:
    case Actions.DELETE_FACILITY.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });

    case Actions.ACCEPT_FACILITY_INVITATION.FAILURE:
      return produce(state, (draft) => {
        draft.acceptingInvitation = false;
        draft.type = type;
      });
    case Actions.ACCEPT_FACILITY_INVITATION.SUCCESS:
      return produce(state, (draft) => {
        draft.acceptingInvitation = false;
        draft.acceptedInvitation = true;
        draft.type = type;
      });

    default:
      return state;
  }
};

export default Facilities;
