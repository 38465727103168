import produce from "immer";
import * as Actions from "../actions";

const initState = {
  loading: false,
  isCreating: false,
  isUpdating: false,
  offices: [],
  type: "",
};

const Offices = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_OFFICES.REQUEST:
    case Actions.DELETE_OFFICE.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });
    case Actions.GET_OFFICES.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.offices = payload;
        draft.type = type;
      });
    case Actions.GET_OFFICES.FAILURE:
    case Actions.DELETE_OFFICE.SUCCESS:
    case Actions.DELETE_OFFICE.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    case Actions.CREATE_OFFICE.REQUEST:
      return produce(state, (draft) => {
        draft.isCreating = true;
        draft.type = type;
      });
    case Actions.CREATE_OFFICE.SUCCESS:
    case Actions.CREATE_OFFICE.FAILURE:
      return produce(state, (draft) => {
        draft.isCreating = false;
        draft.type = type;
      });
    case Actions.UPDATE_OFFICE.REQUEST:
      return produce(state, (draft) => {
        draft.isUpdating = true;
        draft.type = type;
      });
    case Actions.UPDATE_OFFICE.SUCCESS:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.offices = state.offices.map((office) => {
          return office.officeId === payload.officeId
            ? { ...office, name: payload.name }
            : office;
        });
        draft.type = type;
      });
    case Actions.UPDATE_OFFICE.FAILURE:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.type = type;
      });
    default:
      return state;
  }
};

export default Offices;
