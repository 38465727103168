import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import client from "src/helpers/HTTPClient";
import { Notification } from "src/helpers/Notification";
import TextConstants from "src/constants/TextConstants";
import * as Actions from "../actions";
import { createActionStates } from "../utils";
import { API_ENDPOINTS } from "src/constants";

function* getOfficesSaga({ type }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.get, API_ENDPOINTS.OFFICES);

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* createOfficeSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.post, API_ENDPOINTS.OFFICES, payload);

    yield put(onSuccess(response));
    Notification.success(TextConstants.CreateOfficeModal.SuccessMessage);
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* updateOfficeSaga({ type, payload: { officeId, data }, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.OFFICES}/${officeId}`;
    const response = yield call(client.put, endpoint, data);

    yield put(onSuccess(response));
    Notification.success(TextConstants.UpdateOfficeModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteOfficeSaga({ type, payload: officeId, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.OFFICES}/${officeId}`;
    yield call(client.delete, endpoint);

    yield put(onSuccess());
    Notification.success(TextConstants.DeleteOfficeModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchOfficesSagas() {
  yield takeEvery(Actions.GET_OFFICES.REQUEST, getOfficesSaga);
  yield takeEvery(Actions.CREATE_OFFICE.REQUEST, createOfficeSaga);
  yield takeEvery(Actions.UPDATE_OFFICE.REQUEST, updateOfficeSaga);
  yield takeEvery(Actions.DELETE_OFFICE.REQUEST, deleteOfficeSaga);
}

export default function* rootSaga() {
  yield all([fork(watchOfficesSagas)]);
}
