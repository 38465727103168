import { Descriptions, Modal, Tag } from "antd";
import React from "react";
import dayjs from "dayjs";
import { FEEDBACK_TYPE } from "src/constants";
import TextConstants from "src/constants/TextConstants";

const FeedbackDetailModal = ({
  isShow = false,
  onCloseModal = () => {},
  data = {},
}) => {
  const tagColor =
    data.type === FEEDBACK_TYPE.FEEDBACK
      ? "blue"
      : data.type === FEEDBACK_TYPE.BUGS_REPORT
      ? "red"
      : "silver";

  return (
    <React.Fragment>
      <Modal
        title={TextConstants.SendFeedback.FeedbackDetail}
        visible={isShow}
        onCancel={onCloseModal}
        width={1000}
        footer={null}
        style={{ wordBreak: "keep-all" }}
      >
        <Descriptions
          size="small"
          bordered
          column={{
            xs: 1,
            xl: 2,
            xxl: 2,
          }}
        >
          <Descriptions.Item label={TextConstants.Common.DateTime}>
            {dayjs(data.createdAt).format("YYYY/MM/DD HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label={TextConstants.Common.CompanyName}>
            {data.companyName || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={TextConstants.Common.Username}>
            {data.nameOfUser}
          </Descriptions.Item>
          <Descriptions.Item label={TextConstants.Common.EmailAddress}>
            {data.email}
          </Descriptions.Item>
          <Descriptions.Item label={TextConstants.SendFeedback.InquiryDetails}>
            <pre
              style={{ height: 300, overflow: "auto", whiteSpace: "pre-wrap" }}
            >
              {data.des}
            </pre>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </React.Fragment>
  );
};

export default FeedbackDetailModal;
