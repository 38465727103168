import { Col, Form, Input, Modal, Radio, Row } from "antd";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import CustomForm from "src/components/CustomForm";
import CustomSelect from "src/components/CustomSelect";
import InvalidPasswordMessage from "src/components/InvalidPasswordMessage";
import {
  ASCII_REGEX,
  MANAGED_ROLES,
  PASSWORD_REGEX,
  ROLES,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";

const CreateUserModal = ({ onClose, onSubmit }) => {
  const [form] = Form.useForm();

  const { user: loggedInUser } = useSelector((state) => state.Auth);

  const { isCreating } = useSelector((state) => state.Users);
  const { companies } = useSelector((state) => state.Companies);
  const { offices } = useSelector((state) => state.Offices);

  const authRole = useMemo(() => loggedInUser.role, [loggedInUser]);

  const roles = useMemo(() => {
    return Object.values(MANAGED_ROLES[authRole]).map((role) => ({
      label: TextConstants.ROLES[role],
      value: role,
    }));
  }, [authRole]);

  const initValues = useMemo(() => {
    const formValues = { role: authRole };
    if (authRole === ROLES.CA) {
      formValues["companyId"] = loggedInUser.companyId;
    }
    if (authRole === ROLES.DA) {
      formValues["companyId"] = loggedInUser.companyId;
      formValues["departmentId"] = loggedInUser.departmentId;
    }
    return formValues;
  }, [authRole, loggedInUser]);

  const selectedRole = Form.useWatch("role", form);
  const selectedCompanyId = Form.useWatch("companyId", form);
  const selectedDepartmentId = Form.useWatch("departmentId", form);

  const matchOffices = useMemo(
    () =>
      (offices || []).filter((x) => x.departmentId === selectedDepartmentId),
    [offices, selectedDepartmentId]
  );

  // reset lower level dropdown if top level changes
  useEffect(() => {
    if ([ROLES.SA, ROLES.CA].includes(authRole)) {
      form.setFieldValue("departmentId", null);
      form.setFieldValue("officeId", null);
    }
  }, [authRole, form, selectedCompanyId]);

  // reset lower level dropdown if top level changes
  useEffect(() => {
    if ([ROLES.SA, ROLES.CA].includes(authRole)) {
      form.setFieldValue("officeId", null);
    }
  }, [authRole, form, selectedDepartmentId]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleSubmit = () => {
    form.submit();
  };

  const formCustomFields = useMemo(() => {
    return (
      <Row gutter={[24, 0]}>
        {[ROLES.CA, ROLES.DA, ROLES.GU].includes(selectedRole) && (
          <>
            <Col xs={24} md={12} hidden={![ROLES.SA].includes(authRole)}>
              <Form.Item
                label={TextConstants.Common.Company}
                name="companyId"
                rules={[
                  {
                    required: selectedCompanyId !== "-",
                  },
                ]}
              >
                <CustomSelect
                  showSearch
                  size="large"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={[
                    {
                      value: "-",
                      label: `--${TextConstants.Common.CreateNew}--`,
                    },
                    ...(companies || []).map((x) => ({
                      value: x.companyId,
                      label: x.name,
                    })),
                  ]}
                  notFoundContent={TextConstants.Common.NoData}
                />
              </Form.Item>
            </Col>
            {selectedCompanyId === "-" && (
              <Col xs={24} md={12}>
                <Form.Item
                  label={TextConstants.Common.CompanyName}
                  name="companyName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    autoComplete="false"
                    style={{ borderRadius: 2 }}
                    placeholder={TextConstants.Common.CompanyName}
                  />
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Row>
    );
  }, [
    authRole,
    companies,
    matchOffices,
    selectedRole,
    selectedCompanyId,
    selectedDepartmentId,
  ]);

  return (
    <React.Fragment>
      <Modal
        title={TextConstants.CreateUserModal.Title}
        visible={true}
        onOk={onSubmit}
        okButtonProps={{ onClick: handleSubmit }}
        cancelText={TextConstants.Common.Cancel}
        onCancel={onClose}
        cancelButtonProps={{ disabled: isCreating }}
        confirmLoading={isCreating}
        width={800}
      >
        <CustomForm
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="row-col"
          initialValues={initValues}
        >
          <Form.Item
            label={TextConstants.Common.Role}
            name="role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group options={roles} optionType="button" />
          </Form.Item>
          {formCustomFields}
          <Row gutter={[24, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={TextConstants.Common.EmailAddress}
                name="username"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                  {
                    pattern: ASCII_REGEX,
                    message: TextConstants.Common.OnlyAcceptASCIIMessage,
                  },
                  {
                    max: 254,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        "254"
                      ),
                  },
                ]}
              >
                <Input
                  autoComplete="false"
                  style={{ minHeight: 50, borderRadius: 2 }}
                  placeholder={TextConstants.Common.EmailAddress}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={TextConstants.Common.Password}
                name="password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: PASSWORD_REGEX,
                    message: <InvalidPasswordMessage />,
                  },
                ]}
              >
                <Input.Password
                  autoComplete="false"
                  placeholder={TextConstants.Common.Password}
                />
              </Form.Item>
            </Col>
          </Row>
        </CustomForm>
      </Modal>
    </React.Fragment>
  );
};

export default CreateUserModal;
