import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import TextConstants from "src/constants/TextConstants";

const InvalidEmailMessage = ({ isShowHint = true }) => {
  return (
    <>
      {TextConstants.Common.InvalidEmailMessage}
      {isShowHint && (
        <Tooltip
          placement="topLeft"
          title={
            <>
              {TextConstants.Common.InvalidEmailMessageDetail.Why}
              <br />
              {TextConstants.Common.InvalidEmailMessageDetail.Hint1}
              <br />
              {TextConstants.Common.InvalidEmailMessageDetail.Hint2}
              <br />
              {TextConstants.Common.InvalidEmailMessageDetail.Hint3}
              <br />
            </>
          }
        >
          <QuestionCircleOutlined />
        </Tooltip>
      )}
    </>
  );
};

export default InvalidEmailMessage;
