import { Card, Col, Row, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BorderTable from "src/components/BorderTable";
import { FEEDBACK_TYPE } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { getFeedbackTypeLabelByKey } from "src/helpers/helpers";
import { getAllFeedback } from "src/store/actions";
import FeedbackDetailModal from "./FeedbackDetailModal";

const { Title, Link, Text } = Typography;

const AllFeedback = () => {
  const dispatch = useDispatch();
  const { loading, feedbackList } = useSelector((state) => state.Feedback);
  // use isShowDetailModal state
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState({});

  useEffect(() => {
    dispatch(getAllFeedback());
  }, []);

  const showTheFeedbackDetailModal = (rowData) => {
    setShowDetailModal(true);
    setSelectedFeedback(rowData);
  };

  const feedbackDataMapped = useMemo(() => {
    return feedbackList.map((feedback) => {
      return {
        ...feedback,
        _typeLabel: getFeedbackTypeLabelByKey(feedback.type),
        createdAt: dayjs(feedback.createdAt).format("YYYY/MM/DD HH:mm:ss"),
      };
    });
  }, [feedbackList]);

  const onCloseModal = () => {
    setShowDetailModal(false);
    setSelectedFeedback({});
  };

  const columns = [
    {
      title: "#",
      dataIndex: "_no",
      key: "_no",
      width: 5,
      render: (_, __, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: TextConstants.Common.DateTime,
      dataIndex: "createdAt",
      key: "createdAt",
      width: 10,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      defaultSortOrder: "descend", // Set default sorting order
      render: (value) => {
        return dayjs(value).format("YYYY/MM/DD HH:mm:ss");
      },
    },
    {
      title: TextConstants.Common.CompanyName,
      dataIndex: "companyName",
      key: "companyName",
      width: 20,
      render: (value) => {
        return value || "-";
      },
    },
    {
      title: TextConstants.Common.User,
      dataIndex: "nameOfUser",
      key: "nameOfUser",
      sorter: (a, b) => a.nameOfUser.localeCompare(b.nameOfUser),
      width: 20,
      render: (_, rowData) => {
        return (
          <>
            <p>
              <Text>{rowData.nameOfUser}</Text>
            </p>
            <Text type="secondary" italic>
              {rowData.email}
            </Text>
          </>
        );
      },
    },
    {
      title: TextConstants.SendFeedback.InquiryDetails,
      dataIndex: "des",
      key: "des",
      width: 45,
      render: (_, rowData) => {
        return (
          <div className="feedback-description">
            {rowData.des?.length > 68 ? (
              <>
                {rowData.des.slice(0, 68)}...
                <Link
                  onClick={() => {
                    showTheFeedbackDetailModal(rowData);
                  }}
                >
                  {TextConstants.Common.SeeMore}
                </Link>
              </>
            ) : (
              rowData.des
            )}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card
            className="common-card tablespace"
            title={TextConstants.Pages.FeedbackFromUser}
          >
            <BorderTable
              showSearch
              searchInputPlaceHolder={TextConstants.Common.Input}
              loading={loading}
              columns={columns}
              dataSource={feedbackDataMapped}
              rowKey={"feedbackId"}
              searchableCols={[
                "email",
                "nameOfUser",
                "_typeLabel",
                "title",
                "des",
                "createdAt",
              ]}
            />
          </Card>
        </Col>
      </Row>
      <FeedbackDetailModal
        isShow={isShowDetailModal}
        onCloseModal={onCloseModal}
        data={selectedFeedback}
      />
    </React.Fragment>
  );
};

export default AllFeedback;
