import { Card, Col, Row, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PROPERTY_TYPES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { getFloors, getLights } from "src/store/actions";

const columns = [
  {
    title: TextConstants.Common.Item,
    dataIndex: "item",
    width: "50%",
  },
  {
    title: TextConstants.Common.Value,
    dataIndex: "value",
    width: "50%",
  },
];

const Floors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    lights,
    lightFetchedAt,
    loading: isLoadingLights,
  } = useSelector((state) => state.Lights);

  const { floors, loading, floorFetchedAt } = useSelector(
    (state) => state.Floors
  );
  const [floorIdSelected, setFloorIdSelected] = useState("");

  useEffect(() => {
    if (!floorFetchedAt || new Date().getTime() - floorFetchedAt > 1000) {
      dispatch(getFloors());
    }
  }, [dispatch, floorFetchedAt]);

  useEffect(() => {
    if (!lightFetchedAt || new Date().getTime() - lightFetchedAt > 1000) {
      dispatch(getLights());
    }
  }, [dispatch, lightFetchedAt]);

  useEffect(() => {
    if (floors && floors.length > 0) {
      const firstId = floors[0].floorId;
      navigate(PATHS.FLOOR_MANAGEMENT_DETAIL.replace(":floorId", firstId));
    }
  }, [floors, navigate]);

  useEffect(() => {
    if (location.pathname) {
      const targetId = location.pathname.split("/floor-management/")[1];
      setFloorIdSelected(targetId);
    }
  }, [location]);

  const floorsObj = useMemo(() => {
    const result = {};
    floors.forEach((floor) => {
      result[floor.floorId] = floor;
    });
    return result;
  }, [floors]);

  const lightsObj = useMemo(() => {
    const result = {};
    lights.forEach((light) => {
      result[light.lightId] = light;
    });
    return result;
  }, [lights]);

  const newDeploymentDataSource = useMemo(() => {
    const newDeployment = floorsObj[floorIdSelected];
    if (!newDeployment) {
      return [];
    }

    return [
      {
        key: 1,
        item: TextConstants.NewFloorMapSettings.Name,
        value: newDeployment.officeName,
      },
      {
        key: 2,
        item: TextConstants.NewFloorMapSettings.BuildingName,
        value: newDeployment.buildingName,
      },
      {
        key: 3,
        item: TextConstants.NewFloorMapSettings
          .EnterRoomDimensionsAndCeilingHeight,
        value: (
          <>
            {newDeployment.dimensions[0]}
            <span style={{ marginRight: "10px", marginLeft: "10px" }}>x</span>
            {newDeployment.dimensions[1]}
            <span style={{ marginRight: "10px", marginLeft: "10px" }}>x</span>
            {newDeployment.dimensions[2]}
          </>
        ),
      },
      {
        key: 4,
        item: TextConstants.NewFloorMapSettings.PropertyType,
        value: PROPERTY_TYPES[newDeployment.propertyType]["LABEL"],
      },
      {
        key: 5,
        item: TextConstants.EasySetingsPage.MaxDistanceBetweenLightsLabel,
        value: newDeployment.maxLightDistance,
      },
      {
        key: 6,
        item: TextConstants.EasySetingsPage.TotalGroupsLabel,
        value: newDeployment.groups?.length || 0,
      },
      {
        key: 7,
        item: TextConstants.EasySetingsPage.TotalLightsLabel,
        value: (
          <>
            {Object.entries(newDeployment.totalLights || {}).map(
              ([lightId, num]) => {
                const light = lightsObj[lightId];
                const lightName = light ? light.name : `[ID: ${lightId}]`;
                return (
                  <p key={lightId}>
                    {lightName}：{num}
                  </p>
                );
              }
            )}
          </>
        ),
      },
      {
        key: 8,
        item: TextConstants.EasySetingsPage.GroupNamesLabel,
        value: newDeployment.groups?.map((g) => g.name).join("、") || "",
      },
    ];
  }, [floorsObj, floorIdSelected, lightsObj]);

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card
            bordered={true}
            className="criclebox tablespace mb-24"
            title={
              (floorsObj && floorsObj[floorIdSelected]?.officeName) || "..."
            }
          >
            <Table
              loading={isLoadingLights || loading}
              columns={columns}
              dataSource={newDeploymentDataSource}
              bordered
              pagination={false}
              size="small"
              style={{ overflow: "auto" }}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Floors;
