import { Col, Modal, Radio, Row, Select, Spin } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {
  FLOOR_SETTINGS_FILE_TYPE,
  MAP_LIGHT_EDIT_MODE,
  SENSOR_OPERATION_METHOD_LABEL,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { useWindowDimensions } from "src/helpers/hooks/useWindowDimensions";
import { PATHS } from "src/routes";
import {
  createFloor,
  getFloorMapSettingFileURL,
  getLights,
} from "src/store/actions";
import MapViewController from "../components/MapViewController/MapViewController";
import MapViewGuideLines from "../components/MapViewGuideLines/MapViewGuideLines";
import StepControlButtons from "../components/StepControlButtons/StepControlButtons";

const OPERATION_METHODS = [
  { value: "SWITCH", label: TextConstants.OperationMethod.Switch },
  { value: "SCHEDULE", label: TextConstants.OperationMethod.Schedules },
  { value: "SENSOR", label: TextConstants.OperationMethod.Sensors },
  { value: "SCENE", label: TextConstants.OperationMethod.Scenes },
  {
    value: "SMARTPHONE",
    label: TextConstants.OperationMethod.SmartphoneControl,
  },
];

const LIGHT_SETTINGS_MODES = {
  AUTO: "AUTO",
  MANUAL: "MANUAL",
};

const ZOOM_TYPE = {
  Button: "button",
  Dropdown: "dropdown",
};
const FloorMapLightSettings = ({
  facilityId,
  stepNo,
  newDeployment,
  lightsObj = {},
  onGoBack,
  updateNewDeploymentLocal,
}) => {
  const dispatch = useDispatch();
  const navigate =useNavigate();
  // const prevData = useRef();
  const mapZoomRef = useRef();
  const boxRef = useRef();
  const transformWrapperRef = useRef();

  const { floorSettingsFileURL: floorMapImage, loading } = useSelector(
    (state) => state.Floors
  );

  const [lightTypeIdSelected, setLightTypeIdSelected] = useState(
    newDeployment.lightIds?.[0]
  );
  const [operationMethodUpdated, setOperationMethodUpdated] = useState();
  const [newLights, setNewLights] = useState([]);
  const [editLightMode, setEditLightMode] = useState(MAP_LIGHT_EDIT_MODE.ADD);
  const [lightSettingsMode, setLightSettingsMode] = useState(
    LIGHT_SETTINGS_MODES.AUTO
  );

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const [CurrentZoomLevel, setCurrentZoomLevel] = useState(0); // current zoom level
  const [IsEditModeEnable, setIsEditModeEnable] = useState(false); // toggle view/edit mode
  const [isPanning, setIsPanning] = useState(false); // toggle view/edit mode
  const [zoomScale, setZoomScale] = useState(1);
  const [hasRatioCalculated, setHasRatioCalculated] = useState(false);

  const [mapSize, setMapSize] = useState({ width: 0, height: 0, ratio: 1 });

  const lineProps = useMemo(() => {
    const xLines = parseInt(mapSize.height / 100);
    const yLines = parseInt(mapSize.width / 100);
    return { xLines, yLines, ratio: 1 / mapSize.ratio };
  }, [mapSize]);

  useEffect(() => {
    if ([2, 3].includes(stepNo) && newDeployment.lightSettings) {
      setNewLights(newDeployment.lightSettings);
    }

    if (newDeployment && newDeployment.operationMethod) {
      setOperationMethodUpdated(newDeployment.operationMethod);
    }
  }, [stepNo, setNewLights, newDeployment]);

  useEffect(() => {
    setCurrentZoomLevel(0);
  }, [stepNo, setCurrentZoomLevel]);

  useEffect(() => {
    if (newDeployment.mapImageTempFilePath) {
      dispatch(
        getFloorMapSettingFileURL({
          filePath: newDeployment.mapImageTempFilePath,
          settingType: FLOOR_SETTINGS_FILE_TYPE.MAP_IMAGE,
        })
      );
    }

    dispatch(getLights());
  }, [dispatch, newDeployment]);

  useEffect(() => {
    if (floorMapImage) {
      setHasRatioCalculated(false);
      const img = new Image();
      img.src = floorMapImage;
      img.onload = ({ target: { width, height } }) => {
        const mapViewDom = document.querySelector(".map-view");
        const mapViewWidth = mapViewDom.getBoundingClientRect().width;
        const ratio = width / mapViewWidth;
        setMapSize({ width, height, ratio });
        mapViewDom.style.height = `${height / ratio}px`;
        setHasRatioCalculated(true);
      };
    } else {
      setMapSize({ width: 0, height: 0, ratio: 1 });
    }
  }, [floorMapImage, windowWidth, windowHeight, transformWrapperRef]);

  const handleMapZoomClick = (type) => {
    if (!transformWrapperRef) return false;
    if (type === "+") {
      transformWrapperRef.current.zoomIn();
    } else {
      transformWrapperRef.current.zoomOut();
    }
    mapZoomRef.current = ZOOM_TYPE.Button;
    setTimeout(() => {
      setZoomScale(transformWrapperRef?.current?.state?.scale || 1);
    }, 300);
  };

  const handleMapZoomSelectChange = (value) => {
    if (!transformWrapperRef) return false;

    // reset zoom before apply new zoom
    if (mapZoomRef.current === ZOOM_TYPE.Button) {
      transformWrapperRef.current.resetTransform();
      setCurrentZoomLevel(0);
    }

    setTimeout(() => {
      if (value === "FIT") {
        transformWrapperRef.current.resetTransform();
        setCurrentZoomLevel(0); // reset zoom level
        setZoomScale(1);
      } else {
        const oldZoomLevel = CurrentZoomLevel;
        if (oldZoomLevel > parseFloat(value)) {
          transformWrapperRef.current.zoomOut(parseFloat(value));
        } else {
          transformWrapperRef.current.zoomIn(parseFloat(value));
        }
        setCurrentZoomLevel(parseFloat(value));
      }
      mapZoomRef.current = ZOOM_TYPE.Dropdown;
    }, 500);
  };

  const onAddNewLight = useCallback(
    (event) => {
      const isCanAddNewLight =
        event.target && event.target.getAttribute("id") === "box-content";
      if (
        isCanAddNewLight &&
        editLightMode === MAP_LIGHT_EDIT_MODE.ADD &&
        !isPanning
      ) {
        const x = event.nativeEvent.offsetX * mapSize.ratio;
        const y = event.nativeEvent.offsetY * mapSize.ratio;

        const newLight = {
          key: crypto.randomUUID(),
          lightTypeId: lightTypeIdSelected,
          x,
          y,
        };

        setNewLights([...newLights, newLight]);
      }
    },
    [
      setNewLights,
      newLights,
      lightTypeIdSelected,
      editLightMode,
      isPanning,
      mapSize,
    ]
  );

  const stopDragTheLight = useCallback(
    (x, y, lightKey) => {
      setIsEditModeEnable(false);
      setNewLights(
        newLights.map((l) => {
          if (l.key === lightKey) {
            return {
              ...l,
              x: x * mapSize.ratio + 10 * mapSize.ratio,
              y: y * mapSize.ratio + 10 * mapSize.ratio,
            };
          }
          return l;
        })
      );
    },
    [newLights, setNewLights, mapSize]
  );

  const onLightClick = useCallback(
    (lightKey) => {
      if (editLightMode === MAP_LIGHT_EDIT_MODE.DELETE) {
        setNewLights(newLights.filter((l) => l.key !== lightKey));
      }
    },
    [editLightMode, newLights, setNewLights]
  );

  const moveToFacilityDetail = () => {
    navigate(PATHS.FACILITY_DETAIL.replace(":facilityId", facilityId));
  };

  const onFloorCreatedSuccess = () => {
    Modal.success({
      title: TextConstants.PreRegistrationCompletedConfirmModal.Title,
      content:
        TextConstants.PreRegistrationCompletedConfirmModal.ConfirmContent,
      afterClose: moveToFacilityDetail,
    });
  };

  const createNewFloor = useCallback(() => {
    dispatch(createFloor(newDeployment, onFloorCreatedSuccess));
  }, [dispatch]);

  const onGoNext = useCallback(() => {
    if (stepNo === 3) {
      // submit from confirm screen
      createNewFloor();
    } else {
      const newDeploymentUpdate = {
        stepNo: stepNo + 1,
      };

      if (stepNo === 1) {
        if (
          lightSettingsMode === LIGHT_SETTINGS_MODES.AUTO &&
          mapSize.width > 0 &&
          mapSize.height > 0
        ) {
          const lightsAutoGenerated = [];

          const floorWidth = newDeployment.dimensions[0]; // Metter
          const floorWidthRounded = parseInt(floorWidth);
          const widthMargin = (floorWidth - floorWidthRounded) / 2;
          const startFloorWidthIndex = widthMargin >= 0.25 ? 0 : 1;
          const floorLength = newDeployment.dimensions[1]; // Metter
          const floorLengthRounded = parseInt(floorLength);
          const lengthMargin = (floorLength - floorLengthRounded) / 2;
          const startFloorLengthIndex = lengthMargin >= 0.25 ? 0 : 1;

          const widthSnap = mapSize.width / floorWidth;
          const lengthSnap = mapSize.height / floorLength;
          for (
            let i = startFloorWidthIndex;
            i <= floorWidthRounded - startFloorWidthIndex;
            i++
          ) {
            const x = i * widthSnap + widthMargin * widthSnap;

            for (
              let j = startFloorLengthIndex;
              j <= floorLengthRounded - startFloorLengthIndex;
              j++
            ) {
              const y = j * lengthSnap + lengthMargin * lengthSnap;
              lightsAutoGenerated.push({
                key: crypto.randomUUID(),
                lightTypeId: lightTypeIdSelected,
                x,
                y,
              });
            }
          }
          setNewLights(lightsAutoGenerated);
        } else {
          setNewLights([]);
        }
      }

      if (stepNo === 2) {
        if (operationMethodUpdated) {
          newDeploymentUpdate["operationMethod"] = operationMethodUpdated;
        }

        newDeploymentUpdate["lightSettings"] = newLights;
      }
      updateNewDeploymentLocal(newDeploymentUpdate);
    }
  }, [
    facilityId,
    stepNo,
    newLights,
    mapSize,
    lightSettingsMode,
    setNewLights,
    operationMethodUpdated,
    createNewFloor,
  ]);

  const getTotalLightByType = useCallback(
    (lightId) => {
      return (
        (newDeployment.lightSettings || []).filter(
          (light) => light.lightTypeId === lightId
        ).length || 0
      );
    },
    [newDeployment]
  );

  return (
    <React.Fragment>
      <HeaderController
        stepNo={stepNo}
        newDeployment={newDeployment}
        lightSettingsMode={lightSettingsMode}
        setLightSettingsMode={setLightSettingsMode}
        editLightMode={editLightMode}
        setEditLightMode={setEditLightMode}
        lightTypeIdSelected={lightTypeIdSelected}
        setLightTypeIdSelected={setLightTypeIdSelected}
        lightsObj={lightsObj}
      />
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <div className="map-view spin-container">
            {loading && <Spin />}
            {floorMapImage && hasRatioCalculated && !loading && (
              <TransformWrapper
                doubleClick={{ disabled: true }}
                wheel={{ disabled: true }}
                panning={{ disabled: IsEditModeEnable }}
                pinch={{ disabled: IsEditModeEnable }}
                ref={transformWrapperRef}
                zoomAnimation={{ disabled: true }}
                centerOnInit
                onPanning={() => {
                  setIsPanning(true);
                }}
                onPanningStop={() => {
                  setTimeout(() => {
                    setIsPanning(false);
                  }, 100);
                }}
              >
                <TransformComponent
                  wrapperStyle={{
                    overflow:
                      CurrentZoomLevel !== 0 || zoomScale > 1
                        ? "auto"
                        : "hidden",
                  }}
                >
                  <div
                    className="boxContent"
                    id="box-content"
                    style={{
                      backgroundImage: `url(${floorMapImage})`,
                      width: mapSize.width / mapSize.ratio,
                      height: mapSize.height / mapSize.ratio,
                    }}
                    onClick={(e) => {
                      if (stepNo === 2) {
                        onAddNewLight(e);
                      }
                    }}
                    ref={boxRef}
                  >
                    {[2, 3].includes(stepNo) &&
                      newLights.map((light) => {
                        const x = light.x;
                        const y = light.y;
                        return (
                          <Draggable
                            disabled={
                              editLightMode === MAP_LIGHT_EDIT_MODE.DELETE
                            }
                            key={light.key}
                            bounds="parent"
                            position={{
                              x: x / mapSize.ratio - 10 || 0,
                              y: y / mapSize.ratio - 10 || 0,
                            }}
                            onStart={() => {
                              setIsEditModeEnable(true);
                            }}
                            onStop={(_, data) => {
                              stopDragTheLight(data.x, data.y, light.key);
                            }}
                          >
                            <div
                              className={`map-light ${
                                editLightMode === MAP_LIGHT_EDIT_MODE.DELETE
                                  ? "can-delete"
                                  : "can-edit"
                              }`}
                              onClick={() => {
                                onLightClick(light.key);
                              }}
                              style={{
                                backgroundColor:
                                  lightsObj[light.lightTypeId]?.mapSettings
                                    ?.color,
                              }}
                            ></div>
                          </Draggable>
                        );
                      })}
                    <MapViewGuideLines {...lineProps} />
                  </div>
                </TransformComponent>
              </TransformWrapper>
            )}
          </div>
        </Col>
        <Col xs={{ span: 24 }}>
          <MapViewController
            handleMapZoomClick={handleMapZoomClick}
            handleMapZoomSelectChange={handleMapZoomSelectChange}
          />
        </Col>
        {stepNo === 2 && (
          <Col xs={{ span: 24 }}>
            <Row gutter={[24, 0]}>
              <Col>
                {TextConstants.NewFloorMapSettings.ActionMode}：
                <Select
                  value={operationMethodUpdated}
                  style={{
                    width: 140,
                  }}
                  onChange={(operationMethod) => {
                    setOperationMethodUpdated(operationMethod);
                  }}
                  options={OPERATION_METHODS}
                />
              </Col>
              <Col className="d-flex align-items-center">
                {TextConstants.NewFloorMapSettings.NumberLightFixtures}：
                {newLights.length}
              </Col>
            </Row>
          </Col>
        )}
        {stepNo === 3 && (
          <>
            <Col xs={{ span: 24 }}>
              {TextConstants.NewFloorMapSettings.ActionMode}：
              {SENSOR_OPERATION_METHOD_LABEL[newDeployment.operationMethod]}
            </Col>
            <Col>
              {TextConstants.NewFloorMapSettings.LightingType}（
              {TextConstants.NewFloorMapSettings.NumberLightFixtures}）：
              {newDeployment.lightIds
                .map((lightId) => {
                  return `${lightsObj[lightId]?.name} (${getTotalLightByType(
                    lightId
                  )})`;
                })
                .join("、")}
            </Col>
          </>
        )}
      </Row>
      <StepControlButtons
        onGoBack={onGoBack}
        onGoNext={onGoNext}
        nextLabel={stepNo === 3 ? TextConstants.Common.Confirmation : null}
      />
    </React.Fragment>
  );
};

const HeaderController = ({
  stepNo,
  newDeployment,
  lightSettingsMode,
  setLightSettingsMode,
  editLightMode,
  setEditLightMode,
  lightTypeIdSelected,
  setLightTypeIdSelected,
  lightsObj,
}) => {
  return (
    <Row gutter={[24, 10]} className="mb-20">
      {stepNo === 1 && (
        <Col xs={{ span: 24 }} className="d-flex flex-start">
          <div className="option-label">
            {
              TextConstants.NewFloorMapSettings
                .DoUWantToSetTheLightingPositionToAuto
            }
          </div>
          <Radio.Group
            size="small"
            options={[
              {
                label: TextConstants.NewFloorMapSettings.Automatic,
                value: LIGHT_SETTINGS_MODES.AUTO,
              },
              {
                label: TextConstants.NewFloorMapSettings.Manual,
                value: LIGHT_SETTINGS_MODES.MANUAL,
              },
            ]}
            onChange={(e) => {
              setLightSettingsMode(e.target.value);
            }}
            buttonStyle="solid"
            value={lightSettingsMode}
            optionType="button"
            style={{ marginLeft: 10 }}
          />
        </Col>
      )}
      {stepNo === 2 && (
        <>
          <Col xs={{ span: 24 }} className="d-flex flex-start">
            <div className="option-label">
              {TextConstants.NewFloorMapSettings.SettingUpLighting}
            </div>
            <Radio.Group
              size="small"
              options={[
                {
                  label: TextConstants.NewFloorMapSettings.AddMode,
                  value: MAP_LIGHT_EDIT_MODE.ADD,
                },
                {
                  label: TextConstants.NewFloorMapSettings.DeleteMode,
                  value: MAP_LIGHT_EDIT_MODE.DELETE,
                },
              ]}
              onChange={(e) => {
                setEditLightMode(e.target.value);
              }}
              buttonStyle="solid"
              value={editLightMode}
              optionType="button"
              style={{ marginLeft: 10, marginRight: 10 }}
            />
            {TextConstants.NewFloorMapSettings.LightSettingsNote}
          </Col>
          <Col xs={{ span: 24 }}>
            <label htmlFor="lightSelected" className="option-label">
              {TextConstants.NewFloorMapSettings.LightingType}
            </label>
            {newDeployment && newDeployment.lightIds && (
              <Select
                id="lightSelected"
                style={{ width: 200, marginLeft: 10 }}
                value={lightTypeIdSelected}
                onChange={(lightId) => {
                  setLightTypeIdSelected(lightId);
                }}
                disabled={editLightMode !== MAP_LIGHT_EDIT_MODE.ADD}
                options={newDeployment.lightIds.map((id) => {
                  return {
                    value: id,
                    label: (
                      <>
                        <div
                          className="map-light-preview"
                          style={{
                            backgroundColor: lightsObj[id]?.mapSettings?.color,
                          }}
                        ></div>
                        {lightsObj[id]?.name}
                      </>
                    ),
                  };
                })}
              ></Select>
            )}
          </Col>
        </>
      )}
      {stepNo === 3 && (
        <div className="option-label">
          {TextConstants.NewFloorMapSettings.CheckLightingSettings}
        </div>
      )}
    </Row>
  );
};

export default FloorMapLightSettings;
