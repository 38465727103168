import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import TextConstants from "src/constants/TextConstants";

const MaxLightSensorNumberWarningTooltip = ({ isShowHint = true }) => {
  return (
    <>
      {isShowHint && (
        <Tooltip
          placement="topLeft"
          title={
            <>
              {
                TextConstants.NewFloorMapSettings
                  .MaxLightSensorNumberWarningHint.Content1
              }
              <br />
              {
                TextConstants.NewFloorMapSettings
                  .MaxLightSensorNumberWarningHint.Content2
              }
              <br />
              {
                TextConstants.NewFloorMapSettings
                  .MaxLightSensorNumberWarningHint.Hint1
              }
              <br />
              {
                TextConstants.NewFloorMapSettings
                  .MaxLightSensorNumberWarningHint.Hint2
              }
            </>
          }
        >
          <QuestionCircleOutlined style={{ marginLeft: 6 }} />
        </Tooltip>
      )}
    </>
  );
};

export default MaxLightSensorNumberWarningTooltip;
