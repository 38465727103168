import { Col, Row } from "antd";

const CustomNote = ({ children }) => {
  return (
    <Row gutter={[24, 0]} className="mb-20">
      <Col xs={{ span: 24 }} className="custom-note">
        {children}
      </Col>
    </Row>
  );
};

export default CustomNote;
