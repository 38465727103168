import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import client from "src/helpers/HTTPClient";
import * as Actions from "../actions";
import { createActionStates } from "../utils";

function* getMeasurementsSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { officeId, type, range } = payload;
    let url = `measurements?type=${type}&officeId=${officeId}`;
    if (range) {
      const [start, end] = range;
      const startTimeString = start.startOf('day').toISOString().split('.').shift() + 'Z';
      const endTimeString = end.endOf('day').toISOString().split('.').shift() + 'Z';
      url += `&start=${startTimeString}&end=${endTimeString}`
    }
    const response = yield call(client.get, url);

    yield put(onSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchDataSagas() {
  yield takeEvery(Actions.GET_MEASUREMENTS.REQUEST, getMeasurementsSaga);
}

export default function* rootSaga() {
  yield all([fork(watchDataSagas)]);
}
