import {
  KeyOutlined,
  LoadingOutlined,
  MailOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Menu, Row, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import logoWhite from "src/assets/images/logo-full-white.png";
import AppReleaseVersion from "src/components/AppReleaseVersion";
import { ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import items from "src/containers/_nav";
import { PATHS } from "src/routes";
import { getFacilities } from "src/store/actions";

const FacilitiesSearchingInput = ({
  onFacilitySearching,
  isFacilitySearching = false,
}) => {
  return (
    <>
      <Input
        size="small"
        allowClear
        placeholder={`${TextConstants.Facility.FacilityName}`}
        prefix={isFacilitySearching ? <LoadingOutlined /> : <SearchOutlined />}
        onChange={(event) => {
          onFacilitySearching(event.target.value);
        }}
      />
    </>
  );
};

const LinkToFacility = ({ facility = {} }) => {
  return (
    <>
      <NavLink
        to={PATHS.FACILITY_DETAIL.replace(":facilityId", facility.facilityId)}
      >
        {facility.isOwner ? (
          <Tooltip title={TextConstants.Common.Owner}>
            <KeyOutlined style={{ marginRight: 4 }} />
          </Tooltip>
        ) : (
          <Tooltip title={TextConstants.Common.IsInvited}>
            <MailOutlined style={{ marginRight: 4 }} />
          </Tooltip>
        )}
        {facility.facilityName}
      </NavLink>
    </>
  );
};

let onFacilitySearchingTimeout = null;
const Sidenav = () => {
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isFacilitySearching, setIsFacilitySearching] = useState(false);
  const [facilityGroupNameSearchingValue, setFacilityGroupNameSearchingValue] =
    useState("");

  const { facilities, loading: isFetchingFacilities } = useSelector(
    (state) => state.Facilities
  );
  const { user } = useSelector((state) => state.Auth);
  const role = useMemo(() => user.role, [user]);

  useEffect(() => {
    if ([ROLES.FA, ROLES.SU].includes(role)) {
      dispatch(getFacilities());
    }
  }, [role]);

  const onFacilitySearching = (value) => {
    setIsFacilitySearching(true);

    if (onFacilitySearchingTimeout) {
      clearTimeout(onFacilitySearchingTimeout);
    }
    onFacilitySearchingTimeout = setTimeout(() => {
      setIsFacilitySearching(false);
      setFacilityGroupNameSearchingValue(value);
    }, 500);
  };

  const facilitiesFiltered = useMemo(() => {
    if (!facilityGroupNameSearchingValue) return facilities;

    return facilities.filter((fa) => {
      return fa.facilityName
        .toLowerCase()
        .includes(facilityGroupNameSearchingValue.toLocaleLowerCase());
    });
  }, [facilityGroupNameSearchingValue, facilities]);

  const menuItems = useMemo(() => {
    return items
      .filter((item) => item.roles.includes(role))
      .map((item) => {
        // When is the dashboard page of facility admin
        if (item.route === PATHS.DASHBOARD && [ROLES.FA].includes(role)) {
          return {
            ...item,
            key: "sub-menu-always-open",
            className: "ant-menu-sub-custom",
            label: item.label,
            children:
              isFetchingFacilities && facilities && facilities.length === 0
                ? [
                    {
                      key: "_loading",
                      type: "group",
                      className: "facilities-group-parent",
                      label: (
                        <a
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span className="label">
                            <LoadingOutlined style={{ marginRight: 8 }} />
                            {TextConstants.Common.Loading}
                          </span>
                        </a>
                      ),
                    },
                  ]
                : [
                    {
                      key: "_facilities-group",
                      label: (
                        <FacilitiesSearchingInput
                          onFacilitySearching={onFacilitySearching}
                          isFacilitySearching={isFacilitySearching}
                        />
                      ),
                      className: "facilities-group-parent",
                      type: "group",
                      children: [...facilitiesFiltered]
                        .sort((a, b) =>
                          a.facilityName.localeCompare(b.facilityName)
                        )
                        .map((facility) => {
                          return {
                            key: facility.facilityId,
                            type: "group",
                            label: <LinkToFacility facility={facility} />,
                          };
                        }),
                    },
                  ],
          };
        }
        return {
          ...item,
          key: item.route,
          label: <NavLink to={item.route}>{item.label}</NavLink>,
        };
      });
  }, [
    items,
    role,
    isFacilitySearching,
    onFacilitySearching,
    facilitiesFiltered,
  ]);

  return (
    <>
      <Row justify={"center"} gutter={[24, 10]} className="sider-header">
        <img
          src={logoWhite}
          alt={TextConstants.PortalName}
          style={{ height: 50 }}
        />
        {pathname && pathname.indexOf("/admin/") !== -1 && (
          <Button size="small" className="goto-admin-screen">
            {TextConstants.Common.SystemScreenAdmin}
          </Button>
        )}
      </Row>
      <Menu
        items={menuItems}
        mode="inline"
        subMenuCloseDelay={0}
        className="custom-left-sidebar-menu"
        defaultOpenKeys={["sub-menu-always-open"]}
        selectedKeys={pathname}
      />
      <div className="left-sidebar-app-version-container">
        <AppReleaseVersion />
      </div>
    </>
  );
};

export default Sidenav;
