import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import client from "src/helpers/HTTPClient";
import { Notification } from "src/helpers/Notification";
import TextConstants from "src/constants/TextConstants";
import * as Actions from "../actions";
import { createActionStates } from "../utils";
import { API_ENDPOINTS } from "src/constants";

function* getLightsSaga({ type }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.get, API_ENDPOINTS.LIGHT_TYPES);

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* createLightSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.post, API_ENDPOINTS.LIGHT_TYPES, payload);

    yield put(onSuccess(response));
    Notification.success(TextConstants.CreateLightModal.SuccessMessage);
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* updateLightSaga({ type, payload: { officeId, data }, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.LIGHT_TYPES}/${officeId}`;
    const response = yield call(client.put, endpoint, data);

    yield put(onSuccess(response));
    Notification.success(TextConstants.UpdateLightModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteLightSaga({ type, payload: officeId, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.LIGHT_TYPES}/${officeId}`;
    yield call(client.delete, endpoint);

    yield put(onSuccess());
    Notification.success(TextConstants.DeleteLightModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchLightsSagas() {
  yield takeEvery(Actions.GET_LIGHTS.REQUEST, getLightsSaga);
  yield takeEvery(Actions.CREATE_LIGHT.REQUEST, createLightSaga);
  yield takeEvery(Actions.UPDATE_LIGHT.REQUEST, updateLightSaga);
  yield takeEvery(Actions.DELETE_LIGHT.REQUEST, deleteLightSaga);
}

export default function* rootSaga() {
  yield all([fork(watchLightsSagas)]);
}
