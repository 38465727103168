import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LOCAL_STORAGE_KEYS, NEW_FLOOR_MAP_SETTING_MODE } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { updateNewDeploymentLocal } from "src/store/actions";
import InitFloorInfo from "./InitFloorInfo";
import LightSelection from "./LightSelection";
import NewDeploymentCard from "./components/NewDeploymentCard/NewDeploymentCard";
import FloorMapLightSettings from "./manualSteps/FloorMapLightSettings";
import { useEffect } from "react";
import { getUserInfo } from "src/helpers/AuthHelper";

const NewFloorMapSettings = () => {
  const { facilityId } = useParams();
  const dispatch = useDispatch();
  const { newDeployment, isCreating } = useSelector((state) => state.Floors);
  const { lights, loading: isLoadingLights } = useSelector(
    (state) => state.Lights
  );

  useEffect(() => {
    const user = getUserInfo() || {};

    const newDeploymentLocal = localStorage.getItem(
      `${LOCAL_STORAGE_KEYS.NEW_DEPLOYMENT_SETTINGS}_${facilityId}_${user.email}`
    );

    if (newDeploymentLocal) {
      dispatch(updateNewDeploymentLocal(JSON.parse(newDeploymentLocal)));
    }
  }, [dispatch, facilityId, getUserInfo, updateNewDeploymentLocal]);

  const lightsObj = useMemo(() => {
    const result = {};
    lights.forEach((light) => {
      result[light.lightId] = light;
    });
    return result;
  }, [lights]);

  const updateNewDeploymentFromLocal = (newDeploymentData) => {
    dispatch(updateNewDeploymentLocal(newDeploymentData));
  };

  const onGoBackClick = useCallback(() => {
    if (newDeployment.stepNo === 0) {
      dispatch(
        updateNewDeploymentLocal({
          stepNo: undefined,
        })
      );
    }

    if (newDeployment.stepNo > 0) {
      dispatch(
        updateNewDeploymentLocal({
          stepNo: newDeployment.stepNo - 1,
        })
      );
    }
  }, [newDeployment]);

  return (
    <React.Fragment>
      <NewDeploymentCard
        title={TextConstants.Pages.FloorRegistration}
        loading={isCreating || isLoadingLights}
      >
        {/* FIRST STEP , [EZ, MANUAL] MODE */}
        {newDeployment.stepNo === undefined && (
          <InitFloorInfo
            facilityId={facilityId}
            newDeployment={newDeployment}
            updateNewDeploymentLocal={updateNewDeploymentFromLocal}
          />
        )}

        {/* NEXT OF INITIALED A NEW DEPLOYMENT -> LIGHT SELECTION , [EZ, MANUAL] MODE */}
        {newDeployment.stepNo === 0 && (
          <LightSelection
            facilityId={facilityId}
            newDeployment={newDeployment}
            onGoBack={onGoBackClick}
            updateNewDeploymentLocal={updateNewDeploymentFromLocal}
            lights={lights}
            mode={newDeployment.settingMode}
          />
        )}

        {newDeployment.settingMode === NEW_FLOOR_MAP_SETTING_MODE.MANUAL && (
          <>
            {/* NEXT OF LIGHTS SELECTION STEP -> EZ SETTINGS , [EZ] MODE */}
            {[1, 2, 3].includes(newDeployment.stepNo) && (
              <FloorMapLightSettings
                facilityId={facilityId}
                stepNo={newDeployment.stepNo}
                newDeployment={newDeployment}
                lightsObj={lightsObj}
                onGoBack={onGoBackClick}
                updateNewDeploymentLocal={updateNewDeploymentFromLocal}
              />
            )}
          </>
        )}
      </NewDeploymentCard>
    </React.Fragment>
  );
};

export default NewFloorMapSettings;
