import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TextConstants from "src/constants/TextConstants";
import { getFacilityFloor } from "src/store/actions";
import InitFloorInfo from "./InitFloorInfo";
import NewDeploymentCard from "./components/NewDeploymentCard/NewDeploymentCard";

const NewFloorMapSettings = () => {
  const { facilityId, floorId } = useParams();
  const dispatch = useDispatch();
  const { facilityFloor, loading, isUpdating } = useSelector((state) => state.Floors);

  useEffect(() => {
    dispatch(getFacilityFloor({ facilityId, floorId }));
  }, [dispatch, facilityId, floorId, getFacilityFloor]);


  return (
    <React.Fragment>
      <NewDeploymentCard
        loading={loading}
        title={TextConstants.Pages.EditFloor}
      >
        {facilityFloor.floorId && (
          <InitFloorInfo
            facilityId={facilityId}
            floorId={floorId}
            facilityFloor={facilityFloor}
            isUpdating={isUpdating}
          />
        )}
      </NewDeploymentCard>
    </React.Fragment>
  );
};

export default NewFloorMapSettings;
