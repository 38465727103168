import { Form, Radio, Typography } from "antd";
import { NEW_FLOOR_MAP_SETTING_MODE } from "src/constants";
import TextConstants from "src/constants/TextConstants";

const EZOrManualModeSelector = ({
  name,
  label,
  rules = [],
  onChange = () => {},
}) => {
  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={rules}
        className="radio-new-floor-map-setting-mode"
      >
        <Radio.Group onChange={onChange}>
          <Radio value={NEW_FLOOR_MAP_SETTING_MODE.EASY}>
            <div className="mode-label">
              <Typography.Title level={5}>
                {TextConstants.NewFloorMapSettings.EasyRegistration}
              </Typography.Title>
              <Typography.Text>
                {
                  TextConstants.NewFloorMapSettings
                    .RegisterTheMinimumInformation
                }
              </Typography.Text>
            </div>
          </Radio>

          <Radio value={NEW_FLOOR_MAP_SETTING_MODE.MANUAL}>
            <div className="mode-label">
              <Typography.Title level={5}>
                {TextConstants.NewFloorMapSettings.MakeSureToRegister}
              </Typography.Title>
              <Typography.Text>
                {
                  TextConstants.NewFloorMapSettings
                    .RegisterDrawingsAndPlaceLighting
                }
              </Typography.Text>
            </div>
          </Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default EZOrManualModeSelector;
