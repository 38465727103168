import { Typography } from "antd";

const CardSelector = ({ label = "", onClick = () => {} }) => {
  return (
    <div className="card-selector" onClick={onClick}>
      <Typography.Text strong>{label}</Typography.Text>
    </div>
  );
};

export default CardSelector;
