import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import TextConstants from "src/constants/TextConstants";

const InvalidPasswordMessage = ({ isShowHint = true }) => {
  return (
    <>
      {TextConstants.Common.IncorrectPasswordFormat}{" "}
      {isShowHint && (
        <Tooltip
          placement="topLeft"
          title={
            <>
              {TextConstants.Common.IncorrectPasswordFormatMessageDetail.Hint1}
              <br />
              {TextConstants.Common.IncorrectPasswordFormatMessageDetail.Hint2}
              <br />
              {TextConstants.Common.IncorrectPasswordFormatMessageDetail.Hint3}
            </>
          }
        >
          <QuestionCircleOutlined />
        </Tooltip>
      )}
    </>
  );
};

export default InvalidPasswordMessage;
