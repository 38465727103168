import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "src/assets/images/dark-logo.png";
import AppReleaseVersion from "src/components/AppReleaseVersion";
import CustomForm from "src/components/CustomForm";
import InvalidPasswordMessage from "src/components/InvalidPasswordMessage";
import {
  ASCII_REGEX,
  FACILITY_ADMIN_ROLES,
  JWT_REGEX,
  PASSWORD_REGEX,
  ROLES,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { parseJwt } from "src/helpers/AuthHelper";
import { Notification } from "src/helpers/Notification";
import useQuery from "src/helpers/hooks/useQuery";
import { PATHS } from "src/routes";
import { SIGNUP, signup } from "src/store/actions";
const { Content } = Layout;

const NewAccountConfirmation = ({
  isSubmited,
  loading,
  setIsConfirmMode,
  userRoleValue,
  isShowRoleInput,
  authType,
  form,
  isHideCompanyNameInput,
  companyNameValue,
  nameValue,
  departmentNameValue,
  emailValue,
  navigate,
}) => {
  // const dispatch = useDispatch();
  // const [isSendVerifySuccess, setIsSendVerifySuccess] = useState(false);
  // const [isSubmitedVerifyEmailAddress, setIsSubmitedVerifyEmailAddress] =
  //   useState(false);

  // useEffect(() => {
  //   if (
  //     isSubmitedVerifyEmailAddress &&
  //     authType === SEND_EMAIL_ADDRESS_CONFIRMATION.SUCCESS
  //   ) {
  //     setIsSendVerifySuccess(true);
  //   }
  // }, [authType, setIsSendVerifySuccess, isSubmitedVerifyEmailAddress]);

  // const showEmailAddressConfirmSuccessModal = () => {
  //   Modal.success({
  //     content: TextConstants.Common.ConfirmEmailAddressSuccessMessage,
  //   });
  // };

  // const sendConfirmationOfEmailAdress = useCallback(() => {
  //   dispatch(
  //     sendEmailAddressConfirmation({ email: emailValue }, () => {
  //       showEmailAddressConfirmSuccessModal();
  //     })
  //   );
  // }, [dispatch, sendEmailAddressConfirmation]);

  const redirectToLoginPage = () => {
    navigate(`${PATHS.LOGIN}`);
  };
  const isRegisterSuccess = useMemo(() => {
    return authType === SIGNUP.SUCCESS && isSubmited;
    // if (registerState) {
    //   setTimeout(() => {
    //     redirectToLoginPage();
    //   }, 4000);
    // }
    // return registerState;
  }, [isSubmited, authType]);

  return (
    <>
      {!isHideCompanyNameInput && (
        <Row>
          <Col xs={{ span: 24 }} style={{ textWrap: "wrap" }}>
            <Typography.Text strong style={{ marginRight: 10 }}>
              {TextConstants.Common.CompanyName}：
            </Typography.Text>
            {companyNameValue}
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={{ span: 24 }} style={{ textWrap: "wrap" }}>
          <Typography.Text strong style={{ marginRight: 10 }}>
            {TextConstants.Common.EmployeeName}：
          </Typography.Text>
          {nameValue}
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} style={{ textWrap: "wrap" }}>
          <Typography.Text strong style={{ marginRight: 10 }}>
            {TextConstants.Common.GroupDepartmentName}：
          </Typography.Text>
          {departmentNameValue}
        </Col>
      </Row>
      {isShowRoleInput && (
        <Row>
          <Col xs={{ span: 24 }} style={{ textWrap: "wrap" }}>
            <Typography.Text strong style={{ marginRight: 10 }}>
              {TextConstants.Common.Role}：
            </Typography.Text>
            {TextConstants.ROLES[userRoleValue]}
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={{ span: 24 }} style={{ textWrap: "wrap" }}>
          <Typography.Text strong style={{ marginRight: 10 }}>
            {TextConstants.Common.EmailAddress}：
          </Typography.Text>
          {emailValue}
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} style={{ textWrap: "wrap" }}>
          <Typography.Text strong style={{ marginRight: 10 }}>
            {TextConstants.Common.Password}：
          </Typography.Text>
          ********
        </Col>
      </Row>
      {/* {isRegisterSuccess && (
        <Row>
          <Col xs={{ span: 24 }}>
            <Alert
              style={{ marginTop: 10, whiteSpace: "break-spaces" }}
              message={TextConstants.SignupScreen.SuccessMessage}
              type="success"
              showIcon
              closable
            />
          </Col>
        </Row>
      )} */}
      <Row className="mt-20">
        <Col xs={{ span: 24 }}>
          <Form.Item>
            {(isRegisterSuccess && (
              <Button
                type="primary"
                ghost
                onClick={redirectToLoginPage}
                style={{ width: "130px" }}
              >
                {TextConstants.Common.Login}
              </Button>
            )) || (
              <Button
                type="primary"
                ghost
                htmlType="button"
                onClick={() => {
                  setIsConfirmMode(false);
                }}
                style={{ width: "130px" }}
                disabled={loading}
              >
                {TextConstants.Common.Edit}
              </Button>
            )}
            <Button
              loading={loading}
              type="primary"
              style={{ width: "130px", float: "right" }}
              onClick={() => {
                form.submit();
              }}
              disabled={authType === SIGNUP.SUCCESS && isSubmited}
            >
              {TextConstants.Common.Registration}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state: locationState } = location;
  const query = useQuery();
  const [form] = Form.useForm();
  const companyNameValue = Form.useWatch("companyName", form);
  const nameValue = Form.useWatch("name", form);
  const departmentNameValue = Form.useWatch("departmentName", form);
  const userRoleValue = Form.useWatch("role", form);
  const emailValue = Form.useWatch("email", form);

  const { loading, type: authType } = useSelector((state) => state.Auth);

  const [isConfirmMode, setIsConfirmMode] = useState(false);
  const [tokenPayload, setTokenPayload] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    const isAgreeTermsAndConditions = (locationState || {})
      .isAgreeTermsAndConditions;

    if (!isAgreeTermsAndConditions) {
      const userToken = query.get("token");
      const tokenParam = userToken ? `?token=${userToken}` : "";
      navigate(`${PATHS.TERMS_OF_USER_WHEN_SIGNUP}${tokenParam}`);
    }
  }, [locationState, navigate, query]);

  useEffect(() => {
    // get the user's token
    const userToken = query.get("token");
    if (!userToken || !JWT_REGEX.test(userToken)) return;
    // parse the token payload and get the username
    const parseToken = parseJwt(userToken);

    if (parseToken.exp) {
      const expTime = (parseToken.exp || 0) * 1000;
      if (dayjs().isAfter(dayjs(expTime))) {
        Notification.error(TextConstants.Common.ExpiredLink);
        navigate(PATHS.LOGIN);
      }
    }

    const tokenPload = {};

    // FIXME: refactor here, does not need assign item by item, assign object to object?
    if (parseToken && parseToken.email) {
      tokenPload["email"] = parseToken.email;
      form.setFieldsValue({
        email: parseToken.email,
      });
    }

    if (parseToken && parseToken.role) {
      tokenPload["role"] = parseToken.role;

      if (parseToken.role === ROLES.FA) {
        tokenPload["faAdminRole"] = parseToken.faAdminRole;
      }
    }

    if (parseToken && parseToken.companyId) {
      tokenPload["companyId"] = parseToken.companyId;
    }

    setTokenPayload(tokenPload);
  }, [query, navigate, setTokenPayload, form]);

  const isShowRoleInput = useMemo(() => {
    return !(tokenPayload && tokenPayload.role);
  }, [tokenPayload]);

  const isHideCompanyNameInput = useMemo(() => {
    return tokenPayload.faAdminRole === FACILITY_ADMIN_ROLES.OWNER;
  }, [tokenPayload]);

  const initValues = {
    role: ROLES.FA,
    email: "",
  };

  const onFinish = useCallback(
    (params) => {
      const payload = { ...params };
      if (tokenPayload && tokenPayload.email) {
        payload["email"] = tokenPayload.email;

        // if (tokenPayload.role === ROLES.FA) {
        //   payload["companyId"] = tokenPayload.companyId;
        // }

        const userToken = query.get("token");
        payload["token"] = userToken;
      }

      if (isConfirmMode) {
        setIsSubmited(true);
        dispatch(
          signup(payload, () => {
            Modal.success({
              content: `${TextConstants.SignupScreen.SuccessMessage}`,
            });
          })
        );
      } else {
        setIsConfirmMode(true);
      }
    },
    [tokenPayload, query, dispatch, isConfirmMode, setIsConfirmMode]
  );
  // const isAuthenticated = useMemo(() => !!user, [user]);

  // if (isAuthenticated && user) {
  //   if (user.role === ROLES.SA) {
  //     return <Redirect to={PATHS.ADMIN_USERS} />;
  //   }

  //   if (user.role === ROLES.GU) {
  //     return <Redirect to={PATHS.NEW_DEPLOYMENT} />;
  //   }
  // }

  const redirectToLoginPage = () => {
    navigate(`${PATHS.LOGIN}`);
  };

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 8 }}
            lg={{ span: 12 }}
            md={{ span: 16 }}
            xs={{ span: 24 }}
          >
            <div className="d-flex justify-content-center">
              <img src={logo} alt="LICONEX LITE" className="home-logo" />
            </div>
            <div className="d-flex justify-content-center mb-20">
              <Typography.Title level={3}>
                {isConfirmMode
                  ? TextConstants.Pages.NewAccountRegistrationConfirmation
                  : TextConstants.Common.CreateAccount}
              </Typography.Title>
            </div>
            {/* [Start] Only for confirmation mode */}
            {isConfirmMode && (
              <NewAccountConfirmation
                isSubmited={isSubmited}
                loading={loading}
                setIsConfirmMode={setIsConfirmMode}
                userRoleValue={userRoleValue}
                isShowRoleInput={isShowRoleInput}
                authType={authType}
                form={form}
                isHideCompanyNameInput={isHideCompanyNameInput}
                companyNameValue={companyNameValue}
                nameValue={nameValue}
                departmentNameValue={departmentNameValue}
                emailValue={emailValue}
                navigate={navigate}
              />
            )}
            {/* [End] Only for confirmation mode */}
            <CustomForm
              form={form}
              key={initValues.email}
              onFinish={onFinish}
              layout="vertical"
              className="row-col"
              initialValues={initValues}
              style={{ display: isConfirmMode ? "none" : "block" }}
            >
              {!isHideCompanyNameInput && (
                <Form.Item
                  className="companyName"
                  label={
                    <Typography.Text strong>
                      {TextConstants.Common.CompanyName}
                    </Typography.Text>
                  }
                  name="companyName"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      max: 255,
                      message:
                        TextConstants.Common.MaxLengthWarningMessage.replace(
                          "{max}",
                          "255"
                        ),
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder={TextConstants.Common.CompanyName}
                  />
                </Form.Item>
              )}
              <Form.Item
                className="employeeName"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.EmployeeName}
                  </Typography.Text>
                }
                name="name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 255,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        "255"
                      ),
                  },
                ]}
              >
                <Input placeholder={TextConstants.Common.EmployeeName} />
              </Form.Item>
              <Form.Item
                className="departmentName"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.GroupDepartmentName}
                  </Typography.Text>
                }
                name="departmentName"
                rules={[
                  {
                    max: 255,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        "255"
                      ),
                  },
                ]}
              >
                <Input placeholder={TextConstants.Common.GroupDepartmentName} />
              </Form.Item>
              {isShowRoleInput && (
                <Form.Item
                  className="userRole"
                  label={
                    <Typography.Text strong>
                      {TextConstants.Common.Role}
                    </Typography.Text>
                  }
                  name="role"
                >
                  <Select
                    options={[
                      {
                        label: TextConstants.ROLES[ROLES.FA],
                        value: ROLES.FA,
                      },
                      // TODO: Disabled the Setup user
                      // {
                      //   label: TextConstants.Common.SettingUser,
                      //   value: ROLES.SU,
                      // },
                    ]}
                  ></Select>
                </Form.Item>
              )}
              <Form.Item
                className="email"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.EmailAddress}
                  </Typography.Text>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                  {
                    pattern: ASCII_REGEX,
                    message: TextConstants.Common.OnlyAcceptASCIIMessage,
                  },
                  {
                    max: 254,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        "254"
                      ),
                  },
                ]}
              >
                <Input
                  placeholder={TextConstants.Common.EmailAddress}
                  disabled={tokenPayload.email}
                />
              </Form.Item>
              <Form.Item
                className="password"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.Password}
                  </Typography.Text>
                }
                name="password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: PASSWORD_REGEX,
                    message: <InvalidPasswordMessage />,
                  },
                ]}
              >
                <Input.Password
                  placeholder={TextConstants.Common.Password}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item
                className="confirmPassword"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.ConfirmPassword}
                  </Typography.Text>
                }
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(TextConstants.Common.PasswordAreNotMatched)
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder={TextConstants.Common.ConfirmPassword}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  htmlType="button"
                  onClick={redirectToLoginPage}
                  style={{ width: "130px" }}
                >
                  {TextConstants.Common.GoBack}
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "130px", float: "right" }}
                >
                  {TextConstants.Common.Confirmation}
                </Button>
              </Form.Item>
            </CustomForm>
            <div>
              <hr className="custom-hr" />
              <AppReleaseVersion />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Register;
