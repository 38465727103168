import * as Types from "src/store/actions";

export const getCompanies = (params, callback = null) => ({
  type: Types.GET_COMPANIES.REQUEST,
  payload: params,
  callback,
});

export const createCompany = (params, callback = null) => ({
  type: Types.CREATE_COMPANY.REQUEST,
  payload: params,
  callback,
});

export const updateCompany = (params, callback = null) => ({
  type: Types.UPDATE_COMPANY.REQUEST,
  payload: params,
  callback,
});

export const deleteCompany = (params, callback = null) => ({
  type: Types.DELETE_COMPANY.REQUEST,
  payload: params,
  callback,
});
