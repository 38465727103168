import { notification } from "antd";
import TextConstants from "../constants/TextConstants";

const defaultOptions = {
  duration: 4,
};

export class Notification {
  static info = (
    message,
    title = TextConstants.Toasts.Notification,
    options = defaultOptions
  ) => {
    notification.info({
      message: title,
      description: message,
      ...options,
    });
  };

  static success = (
    message,
    title = TextConstants.Toasts.Success,
    options = defaultOptions
  ) => {
    notification.success({
      message: title,
      description: message,
      ...options,
    });
  };

  static warn = (
    message,
    title = TextConstants.Toasts.Warning,
    options = defaultOptions
  ) => {
    notification.warn({
      message: title,
      description: message,
      ...options,
    });
  };

  static error = (
    message,
    title = TextConstants.Toasts.Error,
    options = defaultOptions
  ) => {
    notification.error({
      message: title,
      description: message,
      ...options,
    });
  };
}
