import { Divider, Space, Typography } from "antd";
import React from "react";
import BorderTable from "src/components/BorderTable";
import LightColorCircle from "src/components/LightColorCircle";
import TextConstants from "src/constants/TextConstants";
const { Text } = Typography;
const PowerOnSettings = ({ groups, loading }) => {
  const columns = [
    {
      width: "200px",
      title: TextConstants.Common.GroupName,
      dataIndex: "label",
      key: "label",
    },
    {
      width: "400px",
      title: TextConstants.Common.Mode,
      dataIndex: "bootConfig",
      key: "bootConfig_mode",
      render: (configs) => {
        return (
          <>
            <Text>
              {
                TextConstants.FloorBMLiteInformation.PowserOnSettingLabels[
                  configs.mode
                ]
              }
            </Text>
            {configs.mode === 1 && (
              <Space>
                <Text>
                  {TextConstants.FloorBMLiteInformation.Brightness}：
                  {configs.brightness}%
                </Text>
                <Text>
                  {TextConstants.FloorBMLiteInformation.ColorAdjustment}：
                </Text>
                <LightColorCircle color={configs.color} />
              </Space>
            )}
          </>
        );
      },
    },
    {
      title: TextConstants.FloorBMLiteInformation.FadeSettings,
      dataIndex: "bootConfig",
      key: "bootConfig_fade",
      render: (configs) => {
        return (
          <>
            {isNaN(configs.fade) ? "-" : configs.fade}
            {TextConstants.Common.Seconds}
          </>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Divider orientation="left">
        {TextConstants.FloorBMLiteInformation.PowerOnSettings}
      </Divider>
      <BorderTable
        loading={loading}
        columns={columns}
        dataSource={groups}
        rowKey={"id"}
        showPagination={false}
        minHeight={0}
      />
    </React.Fragment>
  );
};

export default PowerOnSettings;
