import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";

import { API_ENDPOINTS } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import client from "src/helpers/HTTPClient";
import { Notification } from "src/helpers/Notification";
import * as Actions from "../actions";
import { createActionStates } from "../utils";

function* getFacilitiesSaga({ payload: params, type }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const queryString = params
      ? `?${new URLSearchParams(params).toString()}`
      : "";
    const response = yield call(
      client.get,
      `${API_ENDPOINTS.FACILITIES}${queryString}`
    );

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* createFacilitySaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.post, API_ENDPOINTS.FACILITIES, payload);

    yield put(onSuccess(response));
    Notification.success(
      TextConstants.CreateFacilitySuccessModal.SuccessMessage,
      TextConstants.CreateFacilitySuccessModal.Title
    );
    callback && callback(response.facilityId);
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteFacilitySaga({ type, payload: facilityId, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    yield call(
      client.delete,
      API_ENDPOINTS.FACILITY.replace("{:facilityId}", facilityId)
    );

    yield put(onSuccess());
    Notification.success(TextConstants.Common.Success);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* updateFacilitySaga({
  type,
  payload: { facilityId, data },
  callback,
}) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.FACILITY.replace(
      "{:facilityId}",
      facilityId
    )}`;
    const response = yield call(client.put, endpoint, data);

    yield put(onSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getFacilityInvitations({ type }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    while (true) {
      const response = yield call(
        client.get,
        API_ENDPOINTS.GET_FACILITY_INVITATIONS
      );

      yield put(onSuccess(response));
      yield delay(60000);
    }
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* acceptFacilityInvitationViaDashboad({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId } = payload;
    yield call(
      client.post,
      API_ENDPOINTS.ACCEPT_FACILITY_INVITATION_VIA_DASHBOARD.replace(
        "{:facilityId}",
        facilityId
      )
    );

    yield put(onSuccess({ facilityId }));
    Notification.success(
      TextConstants.AcceptFacilityInvitationSuccessModal.SuccessMessage,
      TextConstants.AcceptFacilityInvitationSuccessModal.Title
    );
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* rejectFacilityInvitationViaDashboad({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId } = payload;
    yield call(
      client.post,
      API_ENDPOINTS.REJECT_FACILITY_INVITATION_VIA_DASHBOARD.replace(
        "{:facilityId}",
        facilityId
      )
    );

    yield put(onSuccess({ facilityId }));
    Notification.success(
      TextConstants.RejectFacilityInvitationSuccessModal.SuccessMessage,
      TextConstants.RejectFacilityInvitationSuccessModal.Title
    );
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* acceptFacilityInvitation({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.ACCEPT_FACILITY_INVITATION}`;
    const response = yield call(client.post, endpoint, payload);

    yield put(onSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchFloorsSagas() {
  yield takeEvery(Actions.GET_FACILITIES.REQUEST, getFacilitiesSaga);
  yield takeEvery(Actions.CREATE_FACILITY.REQUEST, createFacilitySaga);
  yield takeEvery(Actions.UPDATE_FACILITY.REQUEST, updateFacilitySaga);
  yield takeEvery(Actions.DELETE_FACILITY.REQUEST, deleteFacilitySaga);
  yield takeEvery(
    Actions.GET_FACILITY_INVITATIONS.REQUEST,
    getFacilityInvitations
  );
  yield takeEvery(
    Actions.ACCEPT_FACILITY_INVITATION_VIA_DASHBOARD.REQUEST,
    acceptFacilityInvitationViaDashboad
  );
  yield takeEvery(
    Actions.REJECT_FACILITY_INVITATION_VIA_DASHBOARD.REQUEST,
    rejectFacilityInvitationViaDashboad
  );
  yield takeEvery(
    Actions.ACCEPT_FACILITY_INVITATION.REQUEST,
    acceptFacilityInvitation
  );
}

export default function* rootSaga() {
  yield all([fork(watchFloorsSagas)]);
}
