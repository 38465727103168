import { Card, Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useNavigate } from "react-router";
import CardSelector from "src/components/CardSelector";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";

const Dashboard = () => {
  const navigate =useNavigate();

  const moveToNewDeploymentPage = () => {
    navigate(`${PATHS.NEW_DEPLOYMENT_SURVEY}`);
  };

  const moveToFloorManagementPage = () => {
    navigate(`${PATHS.FLOOR_MANAGEMENT}`);
  };

  return (
    <React.Fragment>
      <Card
        bordered={true}
        className="criclebox tablespace mb-24"
        title={TextConstants.Pages.RegisteredNewPropertyChecking}
      >
        <div style={{ minHeight: "calc(100vh - 180px)" }}>
          <Row gutter={[24, 12]} justify={"center"}>
            <Col xs={{ span: 20 }}>
              <Title level={2} className="text-center">
                {
                  TextConstants.NewPropertyChecking
                    .WouldUouLikeToRegisterANewProperty
                }
              </Title>
            </Col>
            <Col xs={{ span: 20 }}>
              <Row gutter={[24, 16]} justify={"center"}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <CardSelector
                    label={
                      TextConstants.NewPropertyChecking
                        .CheckRegisteredProperties
                    }
                    onClick={moveToFloorManagementPage}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <CardSelector
                    label={TextConstants.Common.Yes}
                    onClick={moveToNewDeploymentPage}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Dashboard;
