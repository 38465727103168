import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { API_ENDPOINTS } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { getRefreshToken, getToken } from "src/helpers/AuthHelper";
import client from "src/helpers/HTTPClient";
import * as Actions from "../actions";
import { createActionStates } from "../utils";
import { Notification } from "src/helpers/Notification";

function* loginSaga({ type, payload }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.post, API_ENDPOINTS.LOGIN, payload);
    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* logoutSaga({ type }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const payload = {
        refreshToken,
        token: getToken(),
      };
      yield call(client.post, API_ENDPOINTS.LOGOUT, payload);
    }

    yield put(onSuccess());
    Notification.success(TextConstants.Common.LogoutSuccessfulMessage);
  } catch (error) {
    console.log("error", error);
    yield put(onFailure(error));
  }
}

function* signupSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.post, API_ENDPOINTS.SIGNUP, payload);
    yield put(onSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log("error", error);
    yield put(onFailure(error));
  }
}

function* passwordResetRequest({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.post,
      API_ENDPOINTS.PASSWORD_RESET_REQUEST,
      payload
    );
    yield put(onSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log("error", error);
    yield put(onFailure(error));
  }
}

function* resetPassword({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.post,
      API_ENDPOINTS.RESET_PASSWORD,
      payload
    );
    yield put(onSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log("error", error);
    yield put(onFailure(error));
  }
}

function* confirmEmailAddress({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.post,
      API_ENDPOINTS.CONFIRM_EMAIL_ADDRESS,
      payload
    );
    yield put(onSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log("error", error);
    yield put(onFailure(error));
  }
}

export function* watchAuthSagas() {
  yield takeEvery(Actions.LOGIN.REQUEST, loginSaga);
  yield takeEvery(Actions.LOGOUT.REQUEST, logoutSaga);
  yield takeEvery(Actions.SIGNUP.REQUEST, signupSaga);
  yield takeEvery(Actions.PASSWORD_RESET_REQUEST.REQUEST, passwordResetRequest);
  yield takeEvery(Actions.RESET_PASSWORD.REQUEST, resetPassword);
  yield takeEvery(Actions.CONFIRM_EMAIL_ADDRESS.REQUEST, confirmEmailAddress);
}

export default function* rootSaga() {
  yield all([fork(watchAuthSagas)]);
}
