import { LeftOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import React from "react";
import TextConstants from "src/constants/TextConstants";

const CustomCard = ({ onGoBack, children, ...props }) => {
  return (
    <>
      <Card
        className="common-card tablespace"
        extra={
          onGoBack ? (
            <Button type="primary" ghost onClick={onGoBack} size="small">
              <LeftOutlined />
              {TextConstants.Common.GoBack}
            </Button>
          ) : null
        }
        {...props}
      >
        {children}
      </Card>
    </>
  );
};

export default CustomCard;
