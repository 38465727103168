import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import TextConstants from "src/constants/TextConstants";
import { deleteFacility } from "src/store/actions";

const DeleteFacilityModal = ({
  facility = {},
  facilitiesLoading,
  deletedSuccessCallback = () => {},
  isModalOpen = false,
  setIsOpenModal = () => {},
}) => {
  const dispatch = useDispatch();

  const handleOk = useCallback(() => {
    if (facility && facility.facilityId) {
      dispatch(deleteFacility(facility.facilityId, deletedSuccessCallback));
    }
  }, [dispatch, facility, deletedSuccessCallback]);

  return (
    <Modal
      confirmLoading={facilitiesLoading}
      title={
        <>
          <ExclamationCircleOutlined style={{ marginRight: 8 }} />
          {TextConstants.Common.Delete}
        </>
      }
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        setIsOpenModal(false);
      }}
      okText={TextConstants.Common.OK}
      cancelText={TextConstants.Common.Cancel}
    >
      <p>
        {TextConstants.Common.ConfirmDeleteMessage.replace(
          "{item}",
          facility?.facilityName || "..."
        )}
      </p>
      <p>
        <strong>
          <WarningOutlined style={{ marginRight: 8, color: "orange" }} />
          {TextConstants.Facility.RemoveFacilityConfirmMessage}
        </strong>
      </p>
    </Modal>
  );
};

export default DeleteFacilityModal;
