import produce from 'immer';
import * as Actions from '../actions';

const initState = {
  loading: false,
  measurements: [],
  type: '',
};

const Measurements = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_MEASUREMENTS.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      })
    case Actions.GET_MEASUREMENTS.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.measurements = payload;
        draft.type = type;
      })
    case Actions.GET_MEASUREMENTS.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      })
    default:
      return state
  }
}

export default Measurements;
