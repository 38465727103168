import * as Types from "src/store/actions";

export const getLights = (params, callback = null) => ({
  type: Types.GET_LIGHTS.REQUEST,
  payload: params,
  callback,
});

export const createLight = (params, callback = null) => ({
  type: Types.CREATE_LIGHT.REQUEST,
  payload: params,
  callback,
});

export const updateLight = (params, callback = null) => ({
  type: Types.UPDATE_LIGHT.REQUEST,
  payload: params,
  callback,
});

export const deleteLight = (params, callback = null) => ({
  type: Types.DELETE_LIGHT.REQUEST,
  payload: params,
  callback,
});
