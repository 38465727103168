import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { API_ENDPOINTS } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import client from "src/helpers/HTTPClient";
import { Notification } from "src/helpers/Notification";
import * as Actions from "../actions";
import { createActionStates } from "../utils";

function* inviteUsers({ type, payload: emails, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.post,
      API_ENDPOINTS.INVITE_USERS,
      emails
    );
    yield put(onSuccess(response));
    Notification.success(TextConstants.Common.InviteUsersSuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getInviteUsers({ type, payload: emails, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.get, API_ENDPOINTS.INVITE_USERS);
    yield put(onSuccess(response));

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteInviteUser({ type, payload: email, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.delete,
      `${API_ENDPOINTS.INVITE_USERS}/${email}`
    );
    yield put(onSuccess(response));
    Notification.success(TextConstants.Common.DeleteInviteUserSuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* inviteFacilityUsers({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId, body } = payload;
    const response = yield call(
      client.post,
      API_ENDPOINTS.FACILITIES_USERS.replace("{:facilityId}", facilityId),
      body
    );
    yield put(onSuccess(response));
    Notification.success(TextConstants.CreateUserModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getFacilityUsersSaga({ type, payload }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.get,
      API_ENDPOINTS.FACILITIES_USERS.replace("{:facilityId}", payload)
    );

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteFacilityUserSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId, email } = payload;
    yield call(
      client.delete,
      API_ENDPOINTS.FACILITIES_USER.replace(
        "{:facilityId}",
        facilityId
      ).replace("{:email}", email)
    );

    Notification.success(TextConstants.DeleteUserModal.SuccessMessage);
    yield put(onSuccess());

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getUsersSaga({ payload: params, type }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const queryString = params
      ? `?${new URLSearchParams(params).toString()}`
      : "";
    const response = yield call(
      client.get,
      `${API_ENDPOINTS.USERS}${queryString}`
    );

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* createUserSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.post, API_ENDPOINTS.USERS, payload);

    yield put(onSuccess(response));
    Notification.success(TextConstants.CreateUserModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* changeUserPasswordSaga({ type, payload: { email, data }, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.USERS}/${email}`;
    const response = yield call(client.patch, endpoint, data);

    yield put(onSuccess(response));
    Notification.success(TextConstants.ChangePasswordModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteUserSaga({ type, payload: email, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.USERS}/${email}`;
    const response = yield call(client.delete, endpoint);

    yield put(onSuccess(response));
    Notification.success(TextConstants.DeleteUserModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* updateUserInfoSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.UPDATE_USER_INFO}`;
    yield call(client.patch, endpoint, payload);

    yield put(onSuccess(payload));
    Notification.success(
      TextConstants.UpdateUserInfoModal.SuccessMessage,
      TextConstants.UpdateUserInfoModal.Title
    );

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchUsersSagas() {
  yield takeEvery(Actions.INVITE_USERS.REQUEST, inviteUsers);
  yield takeEvery(Actions.GET_INVITE_USERS.REQUEST, getInviteUsers);
  yield takeEvery(Actions.DELETE_INVITE_USER.REQUEST, deleteInviteUser);
  yield takeEvery(Actions.INVITE_FACILITY_USERS.REQUEST, inviteFacilityUsers);
  yield takeEvery(Actions.GET_FACILITY_USERS.REQUEST, getFacilityUsersSaga);
  yield takeEvery(Actions.DELETE_FACILITY_USER.REQUEST, deleteFacilityUserSaga);
  yield takeEvery(Actions.GET_USERS.REQUEST, getUsersSaga);
  yield takeEvery(Actions.CREATE_USER.REQUEST, createUserSaga);
  yield takeEvery(Actions.CHANGE_USER_PASSWORD.REQUEST, changeUserPasswordSaga);
  yield takeEvery(Actions.DELETE_USER.REQUEST, deleteUserSaga);
  yield takeEvery(Actions.UPDATE_USER_INFO.REQUEST, updateUserInfoSaga);
}

export default function* rootSaga() {
  yield all([fork(watchUsersSagas)]);
}
