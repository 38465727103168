import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { API_ENDPOINTS } from "src/constants";
import client from "src/helpers/HTTPClient";
import * as Actions from "../actions";
import { createActionStates } from "../utils";

function* getBMConfigurationInfo({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { bmId } = payload;
    const response = yield call(
      client.get,
      API_ENDPOINTS.BMS_CONFIGURATION_INFORMATION.replace("{bmId}", bmId)
    );
    callback && callback();
    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getBMsDeleted({ type, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(client.get, API_ENDPOINTS.BMS_DELETED);
    callback && callback();
    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getBMSetupData({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.get,
      API_ENDPOINTS.BM_SETUP_DATA.replace("{bmId}", payload.bmId)
    );
    callback && callback();
    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchBmsSagas() {
  yield takeEvery(
    Actions.GET_BM_CONFIGURATION_INFO.REQUEST,
    getBMConfigurationInfo
  );
  yield takeEvery(Actions.GET_BMS_DELETED.REQUEST, getBMsDeleted);
  yield takeEvery(Actions.GET_BM_SETUP_DATA.REQUEST, getBMSetupData);
}

export default function* rootSaga() {
  yield all([fork(watchBmsSagas)]);
}
