import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useQueryStringController() {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);

  const setSearchParam = (key, value) => {
    searchParams.set(key, value);
    navigate({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const deleteSearchParam = (key) => {
    searchParams.delete(key);
    navigate({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };
  return React.useMemo(() => {
    return { searchParams, setSearchParam, deleteSearchParam };
  }, [search]);
}
