import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import TextConstants from "src/constants/TextConstants";

const GoNextButton = ({
  onClick = () => {},
  label = "",
  isShowIcon = true,
  ...props
}) => {
  return (
    <Button type="primary" onClick={onClick} {...props}>
      {label || TextConstants.Common.GoNext}{" "}
      {isShowIcon && (
        <RightOutlined style={{ marginLeft: 15, marginRight: 0 }} />
      )}
    </Button>
  );
};

export default GoNextButton;
