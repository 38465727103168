import * as Types from "src/store/actions";

export const getFacilities = (params, callback = null) => ({
  type: Types.GET_FACILITIES.REQUEST,
  payload: params,
  callback,
});

export const createFacility = (params, callback = null) => ({
  type: Types.CREATE_FACILITY.REQUEST,
  payload: params,
  callback,
});

export const updateFacility = (params, callback = null) => ({
  type: Types.UPDATE_FACILITY.REQUEST,
  payload: params,
  callback,
});

export const deleteFacility = (params, callback = null) => ({
  type: Types.DELETE_FACILITY.REQUEST,
  payload: params,
  callback,
});

export const acceptFacilityInvitation = (params, callback = null) => ({
  type: Types.ACCEPT_FACILITY_INVITATION.REQUEST,
  payload: params,
  callback,
});

export const getFacilityInvitations = (callback = null) => {
  return {
    type: Types.GET_FACILITY_INVITATIONS.REQUEST,
    callback,
  };
};

export const acceptFacilityInvitationViaDashboard = (
  params,
  callback = null
) => ({
  type: Types.ACCEPT_FACILITY_INVITATION_VIA_DASHBOARD.REQUEST,
  payload: params,
  callback,
});

export const rejectFacilityInvitationViaDashboard = (
  params,
  callback = null
) => ({
  type: Types.REJECT_FACILITY_INVITATION_VIA_DASHBOARD.REQUEST,
  payload: params,
  callback,
});
