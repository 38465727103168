import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import TextConstants from "src/constants/TextConstants";

const AppReleaseVersion = ({}) => {
  const {
    loading: getBEVersionLoading,
    backendVersion,
    isError: beVersionError,
  } = useSelector((state) => state.Backend);

  return (
    <>
      <small
        title={
          beVersionError ? "Couldn't get system version" : "System version"
        }
        className="app-release-version"
      >
        {TextConstants.Common.AppVersion}
        {(!getBEVersionLoading && backendVersion) ?? "--"}
        {getBEVersionLoading && <LoadingOutlined style={{ marginLeft: 2 }} />}
      </small>
    </>
  );
};

export default AppReleaseVersion;
