import produce from "immer";
import * as Actions from "../actions";

const initState = {
  loading: false,
  isCreating: false,
  isUpdating: false,
  companies: [],
  companyNameMappedByIds: {},
  type: "",
};

const Companies = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_COMPANIES.REQUEST:
    case Actions.DELETE_COMPANY.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });
    case Actions.GET_COMPANIES.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.companies = payload;
        const companiesMappedByIdObj = {};
        payload.forEach((company) => {
          companiesMappedByIdObj[company.companyId] = company.name;
        });
        draft.companyNameMappedByIds = companiesMappedByIdObj;
        draft.type = type;
      });
    case Actions.GET_COMPANIES.FAILURE:
    case Actions.DELETE_COMPANY.SUCCESS:
    case Actions.DELETE_COMPANY.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    case Actions.CREATE_COMPANY.REQUEST:
      return produce(state, (draft) => {
        draft.isCreating = true;
        draft.type = type;
      });
    case Actions.CREATE_COMPANY.SUCCESS:
    case Actions.CREATE_COMPANY.FAILURE:
      return produce(state, (draft) => {
        draft.isCreating = false;
        draft.type = type;
      });
    case Actions.UPDATE_COMPANY.REQUEST:
      return produce(state, (draft) => {
        draft.isUpdating = true;
        draft.type = type;
      });
    case Actions.UPDATE_COMPANY.SUCCESS:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.companies = state.companies.map((company) => {
          return company.companyId === payload.companyId ? payload : company;
        });
        draft.type = type;
      });
    case Actions.UPDATE_COMPANY.FAILURE:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.type = type;
      });
    default:
      return state;
  }
};

export default Companies;
