export const createActionTypes = (type) => {
  return {
    REQUEST: type,
    SUCCESS: `${type}_SUCCESS`,
    FAILURE: `${type}_FAILURE`,
    CLEAN_STATE: `${type}_CLEAN_STATE`,
  }
}

export const createActionStates = (type, payload = null) => {
  const types = createActionTypes(type);

  const onSuccess = (params) => ({
    type: types.SUCCESS,
    payload: params,
  });

  const onFailure = ({ message }) => ({
    type: types.FAILURE,
    payload: message,
  });

  const onCleanState = () => ({
    type: types.CLEAN_STATE,
  });

  return {
    onSuccess,
    onFailure,
    onCleanState,
  };
};
