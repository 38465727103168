const TOKEN_KEY = '_token';
const REFRESH_TOKEN_KEY = '_refresh_token';
const PROFILE_KEY = '_profile';

export const getToken = () => {
  return sessionStorage.getItem(TOKEN_KEY)
};

export const saveToken = (token) => {
  if (!token) {
    throw Error('Invalid token!')
  }
  sessionStorage.setItem(TOKEN_KEY, token);
};


export const getRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY)
};

export const saveRefreshToken = (refreshToken) => {
  if (!refreshToken) {
    throw Error('Invalid token!')
  }
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const removeTokens = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export const isTokenHasExpired = (token) => {
  const tokenPayload = parseJwt(token);
  const expirationTimestamp = tokenPayload.exp * 1000; // Convert to milliseconds
  return new Date().getTime() > expirationTimestamp;
}

export const saveUserInfo = (data) => {
  sessionStorage.setItem(PROFILE_KEY, JSON.stringify(data))
}

export const getUserInfo = () => {
  const storedUser = sessionStorage.getItem(PROFILE_KEY);
  if (storedUser) {
    return JSON.parse(storedUser);
  }

  const token = getToken();
  if (!token) {
    return
  }

  return parseJwt(token);
}


export const updateUserInfoFromLocal = (newData) => {
  const currentUserData = getUserInfo();
  sessionStorage.setItem(
    PROFILE_KEY,
    JSON.stringify({ ...currentUserData, ...newData })
  );
};

export const removeUserInfo = () => {
  sessionStorage.removeItem(PROFILE_KEY);
}