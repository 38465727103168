import {
  ClockCircleOutlined,
  CommentOutlined,
  DashboardOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
  TableOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";

import { PATHS } from "src/routes";

const pages = [
  // START-ADMIN
  {
    label: TextConstants.Pages.Dashboard,
    route: PATHS.ADMIN_DASHBOARD,
    icon: <DashboardOutlined />,
    roles: [ROLES.SA],
  },
  {
    label: TextConstants.Pages.UserManagement,
    route: PATHS.ADMIN_USERS,
    icon: <UsergroupAddOutlined />,
    roles: [ROLES.SA],
  },
  {
    label: TextConstants.Pages.FloorPlan,
    route: PATHS.ADMIN_FLOOR_PLAN,
    icon: <TableOutlined />,
    roles: [ROLES.SA],
  },
  {
    label: TextConstants.Pages.GlobalBMSearch,
    route: PATHS.ADMIN_GLOBAL_BM_SEARCH,
    icon: <SearchOutlined />,
    roles: [ROLES.SA],
  },
  {
    label: TextConstants.Pages.FeedbackFromUser,
    route: PATHS.ADMIN_CHECK_ALL_FEEDBACK,
    icon: <CommentOutlined />,
    roles: [ROLES.SA],
  },
  {
    label: TextConstants.Pages.BMsDeleted,
    route: PATHS.ADMIN_BMS_DELETED,
    icon: <ClockCircleOutlined />,
    roles: [ROLES.SA],
  },
  // {
  //   label: TextConstants.Pages.BMInformation,
  //   route: PATHS.ADMIN_BM_INFORMATION,
  //   icon: <InfoCircleOutlined />,
  //   roles: [ROLES.SA],
  // },
  // {
  //   label: TextConstants.Pages.PowerData,
  //   route: PATHS.ADMIN_POWER_DATA,
  //   icon: <BarChartOutlined />,
  //   roles: [ROLES.SA],
  // },
  // {
  //   label: TextConstants.Pages.SendBMCommand,
  //   route: PATHS.ADMIN_SEND_COMMAND,
  //   icon: <SendOutlined />,
  //   roles: [ROLES.SA],
  // },
  // END-ADMIN

  // START - FACILITY ADMIN, SETUP USER
  {
    label: TextConstants.Pages.AddNewFacility,
    route: PATHS.NEW_FACILITY,
    icon: <PlusOutlined />,
    roles: [ROLES.FA],
  },
  {
    label: "新しい施設の追加",
    route: PATHS.DASHBOARD,
    icon: <PlusOutlined />,
    roles: [ROLES.GU],
  },
  {
    label: TextConstants.Pages.FacilityList,
    route: PATHS.DASHBOARD,
    icon: <UnorderedListOutlined />,
    roles: [ROLES.FA, ROLES.SU],
  },
  // {
  //   label: TextConstants.Pages.SendFeedback,
  //   route: PATHS.SEND_FEEDBACK,
  //   icon: <FormOutlined />,
  //   roles: [ROLES.FA],
  // },
  // {
  //   label: TextConstants.Pages.FloorPlan,
  //   route: PATHS.FLOOR_MANAGEMENT,
  //   icon: <TableOutlined />,
  //   roles: [ROLES.GU],
  // },
  // END-GENERAL USER
];

export default pages;
