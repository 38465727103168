import { Col, Row } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NEW_FLOOR_MAP_SETTING_MODE } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { updateNewDeploymentLocal } from "src/store/actions";
import NewDeploymentCard from "../components/NewDeploymentCard/NewDeploymentCard";
import NewDeploymentSteps from "../components/NewDeploymentSteps/NewDeploymentSteps";
import LightSelection from "./LightSelection";
import FloorSettings from "./easySteps/FloorSettings";
import FloorSettingsConfirm from "./easySteps/FloorSettingsConfirm";
import InitNewDeployment from "./easySteps/InitNewDeployment";
import FloorMapLightSettings from "./manualSteps/FloorMapLightSettings";

const NewFloorMapSettings = () => {
  const dispatch = useDispatch();
  const navigate =useNavigate();
  const { newDeployment, isCreating } = useSelector((state) => state.Floors);
  const { lights, loading: isLoadingLights } = useSelector(
    (state) => state.Lights
  );

  const [stepMode, setStepMode] = useState(
    newDeployment.settingMode || NEW_FLOOR_MAP_SETTING_MODE.EASY
  );

  const lightsObj = useMemo(() => {
    const result = {};
    lights.forEach((light) => {
      result[light.lightId] = light;
    });
    return result;
  }, [lights]);

  const updateNewDeploymentFromLocal = (newDeploymentData) => {
    dispatch(updateNewDeploymentLocal(newDeploymentData));
  };

  const onGoBackClick = useCallback(() => {
    if (newDeployment.stepNo === undefined) {
      navigate(`${PATHS.NEW_DEPLOYMENT_SURVEY}`);
    }

    if (newDeployment.stepNo === 0) {
      dispatch(
        updateNewDeploymentLocal({
          stepNo: undefined,
        })
      );
    }

    if (newDeployment.stepNo > 0) {
      dispatch(
        updateNewDeploymentLocal({
          stepNo: newDeployment.stepNo - 1,
        })
      );
    }
  }, [newDeployment]);

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }} xl={{ span: 2 }}>
          <NewDeploymentSteps stepNo={newDeployment.stepNo} mode={stepMode} />
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 22 }}>
          <NewDeploymentCard
            title={TextConstants.Pages.NewProperty}
            loading={isCreating || isLoadingLights}
          >
            {/* FIRST STEP , [EZ, MANUAL] MODE */}
            {newDeployment.stepNo === undefined && (
              <InitNewDeployment
                newDeployment={newDeployment}
                onGoBack={onGoBackClick}
                updateNewDeploymentLocal={updateNewDeploymentFromLocal}
                setStepMode={setStepMode}
              />
            )}

            {/* NEXT OF INITIALED A NEW DEPLOYMENT -> LIGHT SELECTION , [EZ, MANUAL] MODE */}
            {newDeployment.stepNo === 0 && (
              <LightSelection
                newDeployment={newDeployment}
                onGoBack={onGoBackClick}
                updateNewDeploymentLocal={updateNewDeploymentFromLocal}
                lights={lights}
                mode={newDeployment.settingMode}
              />
            )}

            {newDeployment.settingMode === NEW_FLOOR_MAP_SETTING_MODE.EASY && (
              <>
                {/* NEXT OF LIGHTS SELECTION STEP -> EZ SETTINGS , [EZ] MODE */}
                {newDeployment.stepNo === 1 && (
                  <FloorSettings
                    newDeployment={newDeployment}
                    onGoBack={onGoBackClick}
                    lightsObj={lightsObj}
                    updateNewDeploymentLocal={updateNewDeploymentFromLocal}
                  />
                )}

                {/* NEXT OF FLOOR SETTINGS -> CONFIRM , [EZ] MODE */}
                {newDeployment.stepNo === 2 && (
                  <FloorSettingsConfirm
                    newDeployment={newDeployment}
                    onGoBack={onGoBackClick}
                    lightsObj={lightsObj}
                  />
                )}
              </>
            )}

            {newDeployment.settingMode ===
              NEW_FLOOR_MAP_SETTING_MODE.MANUAL && (
              <>
                {/* NEXT OF LIGHTS SELECTION STEP -> EZ SETTINGS , [EZ] MODE */}
                {[1, 2, 3].includes(newDeployment.stepNo) && (
                  <FloorMapLightSettings
                    stepNo={newDeployment.stepNo}
                    newDeployment={newDeployment}
                    lightsObj={lightsObj}
                    onGoBack={onGoBackClick}
                    updateNewDeploymentLocal={updateNewDeploymentFromLocal}
                  />
                )}
              </>
            )}
          </NewDeploymentCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NewFloorMapSettings;
