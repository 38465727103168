import { Radio } from "antd";
import TextConstants from "src/constants/TextConstants";

const YesNoSelector = ({ value, updateValue }) => {
  const YesNoOptions = [
    {
      label: TextConstants.Common.Yes,
      value: 1,
    },
    {
      label: TextConstants.Common.No,
      value: 0,
    },
  ];

  return (
    <>
      <Radio.Group
        options={YesNoOptions}
        onChange={(e) => {
          updateValue(e.target.value);
        }}
        buttonStyle="solid"
        value={value}
        optionType="button"
        className="new-deployment-yes-no-selector"
      />
    </>
  );
};

export default YesNoSelector;
