import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import TextConstants from "src/constants/TextConstants";

const GoBackButton = ({
  onClick = () => {},
  label = "",
  isShowIcon = true,
}) => {
  return (
    <Button type="primary" onClick={onClick}>
      {isShowIcon && <LeftOutlined />}
      {label || TextConstants.Common.GoBack}
    </Button>
  );
};

export default GoBackButton;
