import { Typography } from "antd";
import TextConstants from "src/constants/TextConstants";

const FloorStatus = ({ registered }) => {
  return (
    <>
      {registered ? (
        <Typography.Text type="success">
          {TextConstants.FacilityFloors.FloorStatus.AlreadySetup}
        </Typography.Text>
      ) : (
        <Typography.Text type="warning">
          {TextConstants.FacilityFloors.FloorStatus.WaitingForSettings}
        </Typography.Text>
      )}
    </>
  );
};

export default FloorStatus;
