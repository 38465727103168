import produce from "immer";
import * as Actions from "../actions";

const initState = {
  loading: false,
  bmsDeleted: [],
  bmSetupData: {},
  configurationInfo: {},
  type: "",
};

const Lights = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_BM_CONFIGURATION_INFO.REQUEST:
    case Actions.GET_BMS_DELETED.REQUEST:
    case Actions.GET_BM_SETUP_DATA.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.bmSetupData = {};
        draft.type = type;
      });
    case Actions.GET_BM_CONFIGURATION_INFO.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.configurationInfo = payload;
        draft.type = type;
      });
    case Actions.GET_BMS_DELETED.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.bmsDeleted = payload;
        draft.type = type;
      });
    case Actions.GET_BM_SETUP_DATA.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.bmSetupData = payload;
        draft.type = type;
      });
    case Actions.GET_BM_CONFIGURATION_INFO.FAILURE:
    case Actions.GET_BMS_DELETED.FAILURE:
    case Actions.GET_BM_SETUP_DATA.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    default:
      return state;
  }
};

export default Lights;
