import { Divider, Space, Typography } from "antd";
import React from "react";
import BorderTable from "src/components/BorderTable";
import LightColorCircle from "src/components/LightColorCircle";
import { DAY_IN_WEEK_LABELS } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import {
  parseSwitchBrightnessValueToUIReadable,
  parseSwitchColorValueToUIReadable,
  parseSwitchConfigValueToText,
} from "src/helpers/helpers";
const { Title, Text } = Typography;
const ScheduleSettings = ({ schedules, loading, dimmingStepVal = 0 }) => {
  const columns = [
    {
      width: "40px",
      title: "#",
      dataIndex: "_no",
      key: "_no",
      render: (_, __, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      width: "250px",
      title: `${TextConstants.Common.DayOfWeek}、${TextConstants.Common.Time}`,
      dataIndex: "days",
      key: "days",
      render: (days, record) => {
        return (
          <>
            <p>
              {days
                .map((day) => {
                  return DAY_IN_WEEK_LABELS[day];
                })
                .join("、")}
            </p>
            <p>
              <Text strong>
                {record.startHour}:{record.startMin}
              </Text>
            </p>
          </>
        );
      },
    },
    {
      width: "250px",
      title: TextConstants.FloorBMLiteInformation.Settings,
      dataIndex: "groups",
      key: "groups",
      render: (groups) => {
        return (
          <>
            {(groups || []).map((g) => {
              return (
                <p>
                  {TextConstants.Common.GroupName}: {g.groupName}、
                  {TextConstants.FloorBMLiteInformation.Brightness}:{" "}
                  {g.brightness}% 、
                  {TextConstants.FloorBMLiteInformation.ColorAdjustment}:{" "}
                  <LightColorCircle color={g.color} />
                </p>
              );
            })}
          </>
        );
      },
    },
    // {
    //   title: `${TextConstants.Common.Time}-${TextConstants.Common.DetailOfSettings}`,
    //   dataIndex: "timeSchedules",
    //   key: "timeSchedules",
    //   render: (timeSchedules) => {
    //     return (
    //       <ul className="time-schedulers-container">
    //         {timeSchedules
    //           .filter((scheduler) => scheduler.registered)
    //           .map((scheduler) => {
    //             return (
    //               <li>
    //                 <Space size={40}>
    //                   <Text strong>
    //                     {scheduler.startHour}:{scheduler.startMin}~
    //                   </Text>
    //                   <Text>
    //                     {TextConstants.FloorBMLiteInformation.Brightness}：
    //                     {scheduler.brightness}%
    //                   </Text>
    //                   <Space size={0}>
    //                     <Text>
    //                       {TextConstants.FloorBMLiteInformation.ColorAdjustment}
    //                       ：
    //                     </Text>
    //                     <LightColorCircle color={scheduler.color} />
    //                   </Space>
    //                 </Space>
    //               </li>
    //             );
    //           })}
    //       </ul>
    //     );
    //   },
    // },
  ];

  return (
    <React.Fragment>
      <Divider orientation="left">
        {TextConstants.FloorBMLiteInformation.ScheduleSettings}
      </Divider>
      <BorderTable
        loading={loading}
        columns={columns}
        dataSource={schedules}
        rowKey={"slotID"}
        showPagination={false}
        minHeight={0}
      />
    </React.Fragment>
  );
};

export default ScheduleSettings;
