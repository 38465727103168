import { Alert, Col, Row, Select, Table, Typography } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { updateNewDeploymentLocal } from "src/store/actions";
import NewDeploymentCard from "../components/NewDeploymentCard/NewDeploymentCard";
import StepControlButtons from "../components/StepControlButtons/StepControlButtons";
import YesNoSelector from "../components/YesNoSelector/YesNoSelector";

export const operationMethods = [
  { value: "SWITCH", label: TextConstants.OperationMethod.Switch },
  { value: "SCHEDULE", label: TextConstants.OperationMethod.Schedules },
  { value: "SENSOR", label: TextConstants.OperationMethod.Sensors },
  { value: "SCENE", label: TextConstants.OperationMethod.Scenes },
  {
    value: "SMARTPHONE",
    label: TextConstants.OperationMethod.SmartphoneControl,
  },
];
const columns = [
  {
    title: "_question",
    dataIndex: "_question",
    width: "80%",
  },
  {
    title: "_answer",
    dataIndex: "_answer",
    align: "center",
    colSpan: 18,
  },
];

const Survey = () => {
  const navigate =useNavigate();
  const dispatch = useDispatch();

  const [operationMethodSelected, setOperationMethodSelected] = useState(
    operationMethods[0].value
  );
  const [isNoAreasSurroundedByMetal, setIsNoAreasSurroundedByMetal] =
    useState(0);
  const [isNoLargeBeamsBetweenTheLights, setIsNoLargeBeamsBetweenTheLights] =
    useState(0);
  const [
    isNoPlansToOperateWithTheFireShuttersDown,
    setIsNoPlansToOperateWithTheFireShuttersDown,
  ] = useState(0);
  const [isNoPlaceToStackYourLuggageHigh, setIsNoPlaceToStackYourLuggageHigh] =
    useState(0);
  const [
    areThereOtherLiCONEXLiteSystemsNearThePlannedInstallationLocation,
    setAreThereOtherLiCONEXLiteSystemsNearThePlannedInstallationLocation,
  ] = useState(0);
  const [
    isNoLightsThatAreMoreThan15mAwayFromTheNearestLight,
    setIsNoLightsThatAreMoreThan15mAwayFromTheNearestLight,
  ] = useState(0);

  const data = [
    {
      key: "1",
      _question: (
        <>
          <Typography.Text strong style={{ marginRight: 10 }}>
            1.
          </Typography.Text>
          <Typography.Text>主な運用方法は？</Typography.Text>
        </>
      ),
      _answer: (
        <>
          <Select
            defaultValue={operationMethodSelected}
            style={{
              width: 140,
            }}
            onChange={(value) => {
              setOperationMethodSelected(value);
            }}
            options={operationMethods}
          />
        </>
      ),
    },
    {
      key: "2",
      _question: (
        <>
          <Typography.Text strong style={{ marginRight: 10 }}>
            2.
          </Typography.Text>
          <Typography.Text>
            金属で囲まれた区画はない。（アルミ・スチール・鉄筋コンクリート等）
          </Typography.Text>
        </>
      ),
      _answer: (
        <YesNoSelector
          value={isNoAreasSurroundedByMetal}
          updateValue={setIsNoAreasSurroundedByMetal}
        />
      ),
    },
    {
      key: "3",
      _question: (
        <>
          <Typography.Text strong style={{ marginRight: 10 }}>
            3.
          </Typography.Text>
          <Typography.Text>
            照明と照明の間に大きな梁はない。（目安：高さ 1ｍ幅 0.5ｍ）
          </Typography.Text>
        </>
      ),
      _answer: (
        <YesNoSelector
          value={isNoLargeBeamsBetweenTheLights}
          updateValue={setIsNoLargeBeamsBetweenTheLights}
        />
      ),
    },
    {
      key: "4",
      _question: (
        <>
          <Typography.Text strong style={{ marginRight: 10 }}>
            4.
          </Typography.Text>
          <Typography.Text>
            防火シャッターを下ろした状態での運用予定はなし。
          </Typography.Text>
        </>
      ),
      _answer: (
        <YesNoSelector
          value={isNoPlansToOperateWithTheFireShuttersDown}
          updateValue={setIsNoPlansToOperateWithTheFireShuttersDown}
        />
      ),
    },
    {
      key: "5",
      _question: (
        <>
          <Typography.Text strong style={{ marginRight: 10 }}>
            5.
          </Typography.Text>
          <Typography.Text>
            荷物を高く積み上げる場所はない。（積荷が通信障害の原因になる可能性はない。）
          </Typography.Text>
        </>
      ),
      _answer: (
        <YesNoSelector
          value={isNoPlaceToStackYourLuggageHigh}
          updateValue={setIsNoPlaceToStackYourLuggageHigh}
        />
      ),
    },
    {
      key: "6",
      _question: (
        <>
          <Typography.Text strong style={{ marginRight: 10 }}>
            6.
          </Typography.Text>
          <Typography.Text>
            設置予定場所の近くに他の LiCONEX-lite のシステムが存在しないか。
          </Typography.Text>
        </>
      ),
      _answer: (
        <YesNoSelector
          value={
            areThereOtherLiCONEXLiteSystemsNearThePlannedInstallationLocation
          }
          updateValue={
            setAreThereOtherLiCONEXLiteSystemsNearThePlannedInstallationLocation
          }
        />
      ),
    },
    {
      key: "7",
      _question: (
        <>
          <Typography.Text strong style={{ marginRight: 10 }}>
            7.
          </Typography.Text>
          <Typography.Text>
            最寄りの照明との距離が 15m 以上離れている照明が存在しない
          </Typography.Text>
        </>
      ),
      _answer: (
        <YesNoSelector
          value={isNoLightsThatAreMoreThan15mAwayFromTheNearestLight}
          updateValue={setIsNoLightsThatAreMoreThan15mAwayFromTheNearestLight}
        />
      ),
    },
  ];

  const onGoBackClick = () => {
    navigate(`${PATHS.NEW_DEPLOYMENT}`);
  };

  const onGoFloorMapSettings = useCallback(() => {
    dispatch(
      updateNewDeploymentLocal({
        operationMethod: operationMethodSelected,
      })
    );
    navigate(`${PATHS.NEW_DEPLOYMENT_FLOOR_MAP_SETTINGS}`);
  }, [dispatch, navigate, operationMethodSelected]);

  const isAgreeAllConditions = useMemo(() => {
    return (
      isNoAreasSurroundedByMetal === 1 &&
      isNoLargeBeamsBetweenTheLights === 1 &&
      isNoPlansToOperateWithTheFireShuttersDown === 1 &&
      isNoPlaceToStackYourLuggageHigh === 1 &&
      areThereOtherLiCONEXLiteSystemsNearThePlannedInstallationLocation === 1 &&
      isNoLightsThatAreMoreThan15mAwayFromTheNearestLight === 1
    );
  }, [
    isNoAreasSurroundedByMetal,
    isNoLargeBeamsBetweenTheLights,
    isNoPlansToOperateWithTheFireShuttersDown,
    isNoPlaceToStackYourLuggageHigh,
    areThereOtherLiCONEXLiteSystemsNearThePlannedInstallationLocation,
    isNoLightsThatAreMoreThan15mAwayFromTheNearestLight,
  ]);

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <NewDeploymentCard
            title={TextConstants.Pages.NewProperty}
            onGoBack={onGoBackClick}
            onGoNext={onGoFloorMapSettings}
            isDisabledNext={!isAgreeAllConditions}
          >
            <Table
              columns={columns}
              dataSource={data}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
              style={{ overflow: "auto" }}
            />
            {!isAgreeAllConditions && (
              <Alert
                message={
                  TextConstants.NewFloorMapSurvey.ConditionsMetWarningMessage
                }
                type="warning"
                showIcon
                className="mt-25"
              />
            )}
            <StepControlButtons
              onGoBack={onGoBackClick}
              onGoNext={onGoFloorMapSettings}
              isDisabledNext={!isAgreeAllConditions}
            />
          </NewDeploymentCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Survey;
