import produce from "immer";
import * as Actions from "../actions";

const initState = {
  loading: false,
  isCreating: false,
  isUpdating: false,
  lights: [],
  lightFetchedAt: null, // Unix time
  type: "",
};

const Lights = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_LIGHTS.REQUEST:
    case Actions.DELETE_LIGHT.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });
    case Actions.GET_LIGHTS.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.lights = payload;
        draft.lightFetchedAt = new Date().getTime();
        draft.type = type;
      });
    case Actions.GET_LIGHTS.FAILURE:
    case Actions.DELETE_LIGHT.SUCCESS:
    case Actions.DELETE_LIGHT.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    case Actions.CREATE_LIGHT.REQUEST:
      return produce(state, (draft) => {
        draft.isCreating = true;
        draft.type = type;
      });
    case Actions.CREATE_LIGHT.SUCCESS:
    case Actions.CREATE_LIGHT.FAILURE:
      return produce(state, (draft) => {
        draft.isCreating = false;
        draft.type = type;
      });
    case Actions.UPDATE_LIGHT.REQUEST:
      return produce(state, (draft) => {
        draft.isUpdating = true;
        draft.type = type;
      });
    case Actions.UPDATE_LIGHT.SUCCESS:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.lights = state.lights.map((light) => {
          return light.lightId === payload.lightId
            ? { ...light, name: payload.name }
            : light;
        });
        draft.type = type;
      });
    case Actions.UPDATE_LIGHT.FAILURE:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.type = type;
      });
    default:
      return state;
  }
};

export default Lights;
