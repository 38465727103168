import produce from "immer";
import * as Actions from "../actions";

const initState = {
  loading: false,
  isCreating: false,
  isUpdating: false,
  users: [],
  inviteUsersLoading: false,
  inviteUsers: [],
  type: "",
};

const Users = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.INVITE_FACILITY_USERS.REQUEST:
    case Actions.GET_FACILITY_USERS.REQUEST:
    case Actions.DELETE_FACILITY_USER.REQUEST:
    case Actions.GET_USERS.REQUEST:
    case Actions.DELETE_USER.REQUEST:
    case Actions.UPDATE_USER_INFO.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });
    case Actions.INVITE_USERS.REQUEST:
    case Actions.GET_INVITE_USERS.REQUEST:
    case Actions.DELETE_INVITE_USER.REQUEST:
      return produce(state, (draft) => {
        draft.inviteUsersLoading = true;
        draft.type = type;
      });
    case Actions.GET_USERS.SUCCESS:
    case Actions.GET_FACILITY_USERS.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.users = payload;
        draft.type = type;
      });
    case Actions.GET_INVITE_USERS.SUCCESS:
      return produce(state, (draft) => {
        draft.inviteUsersLoading = false;
        draft.inviteUsers = payload;
        draft.type = type;
      });
    case Actions.INVITE_FACILITY_USERS.FAILURE:
    case Actions.INVITE_FACILITY_USERS.SUCCESS:
    case Actions.GET_USERS.FAILURE:
    case Actions.GET_FACILITY_USERS.FAILURE:
    case Actions.DELETE_FACILITY_USER.SUCCESS:
    case Actions.DELETE_FACILITY_USER.FAILURE:
    case Actions.UPDATE_USER_INFO.FAILURE:
    case Actions.UPDATE_USER_INFO.SUCCESS:
    case Actions.DELETE_USER.FAILURE:
    case Actions.DELETE_USER.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    case Actions.INVITE_USERS.SUCCESS:
    case Actions.DELETE_INVITE_USER.SUCCESS:
    case Actions.DELETE_INVITE_USER.FAILURE:
      return produce(state, (draft) => {
        draft.inviteUsersLoading = false;
        draft.type = type;
      });
    case Actions.CREATE_USER.REQUEST:
      return produce(state, (draft) => {
        draft.isCreating = true;
        draft.type = type;
      });
    case Actions.CREATE_USER.SUCCESS:
    case Actions.CREATE_USER.FAILURE:
      return produce(state, (draft) => {
        draft.isCreating = false;
        draft.type = type;
      });
    case Actions.CHANGE_USER_PASSWORD.REQUEST:
      return produce(state, (draft) => {
        draft.isUpdating = true;
        draft.type = type;
      });
    case Actions.CHANGE_USER_PASSWORD.SUCCESS:
    case Actions.CHANGE_USER_PASSWORD.FAILURE:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.type = type;
      });
    default:
      return state;
  }
};

export default Users;
