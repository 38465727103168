import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.REACT_APP_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger({
    level: 'info',
    collapsed: true,
  });
  middlewares.push(logger);
}

export function configureStore(initialState) {
  const store = createStore(reducers, initialState, compose(applyMiddleware(...middlewares)));

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    })
  }

  return store;
}

const store = configureStore();

export default store;
