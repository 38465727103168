import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Select } from "antd";
import { useState } from "react";

export const ZoomLevel = [
  { label: "FIT", value: "FIT" },
  { label: 125, value: 0.3 },
  { label: 150, value: 0.5 },
  { label: 200, value: 0.7 },
  { label: 300, value: 1 },
];

const MapViewController = ({
  handleMapZoomClick,
  handleMapZoomSelectChange,
}) => {
  const [selected, setSelected] = useState(ZoomLevel[0].value);
  return (
    <Row gutter={[24, 0]}>
      <Col xs={{ span: 24 }} className="floor-map-controller">
        <Button
          color="secondary"
          className="map-map-zoom-buttons"
          onClick={() => handleMapZoomClick("+")}
        >
          <PlusOutlined />
        </Button>
        <Button
          size="sm"
          color="secondary"
          className="map-map-zoom-buttons"
          onClick={() => handleMapZoomClick("-")}
        >
          <MinusOutlined />
        </Button>
        &nbsp;
        <Select
          onChange={(e) => {
            setSelected(e);
            handleMapZoomSelectChange(e);
          }}
          style={{ width: 100, height: 34 }}
          value={selected}
          options={ZoomLevel}
        ></Select>
      </Col>
    </Row>
  );
};

export default MapViewController;
