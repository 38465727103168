import { Col, Form, InputNumber, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "src/components/CustomForm";
import TextConstants from "src/constants/TextConstants";
import { getLights } from "src/store/actions";
import StepControlButtons from "../../components/StepControlButtons/StepControlButtons";

// const MAX_GROUP_NUM = 6;

const FloorSettings = ({
  newDeployment,
  onGoBack = () => {},
  updateNewDeploymentLocal = () => {},
  lightsObj = {},
}) => {
  const dispatch = useDispatch();

  const [form] = useForm();

  const { lightFetchedAt } = useSelector((state) => state.Lights);

  useEffect(() => {
    // Refresh the lights data after 1 mins
    if (!lightFetchedAt || new Date().getTime() - lightFetchedAt > 1000 * 60) {
      dispatch(getLights());
    }
  }, [lightFetchedAt]);

  // const totalGroupFormValue = Form.useWatch("totalGroup", form);
  // const totalGroupValue = useMemo(() => {
  //   if (totalGroupFormValue <= 0) {
  //     return 1;
  //   }

  //   return totalGroupFormValue > MAX_GROUP_NUM
  //     ? MAX_GROUP_NUM
  //     : totalGroupFormValue;
  // }, [totalGroupFormValue]);

  const onFinish = useCallback(
    (values) => {
      // const groups = [];
      // for (let index = 0; index < values.totalGroup; index++) {
      //   groups.push({ name: values[`groupNames_${index}`], lights: [] });
      // }

      const totalLights = {};
      (newDeployment.lightIds || []).forEach((lightId) => {
        totalLights[lightId] = values[`totalLights_${lightId}`];
      });

      const newDeploymentData = {
        maxLightDistance: values.maxLightDistance,
        totalLights,
        // groups,
        stepNo: 2,
      };
      updateNewDeploymentLocal(newDeploymentData);
    },
    [newDeployment, updateNewDeploymentLocal]
  );

  const formInitValues = useMemo(() => {
    const initForm = {
      maxLightDistance: newDeployment?.maxLightDistance || 1,
      // totalGroup: newDeployment?.groups?.length || 1,
    };

    // if (newDeployment && newDeployment.groups) {
    //   newDeployment.groups.forEach((group, index) => {
    //     initForm[`groupNames_${index}`] = group.name || "";
    //   });
    // }

    if (newDeployment.totalLights) {
      Object.entries(newDeployment.totalLights).forEach(([lightId, total]) => {
        initForm[`totalLights_${lightId}`] = parseInt(total);
      });
    } else if (newDeployment.lightIds && newDeployment.lightIds.length > 0) {
      newDeployment.lightIds.forEach((lightId) => {
        initForm[`totalLights_${lightId}`] = null;
      });
    }

    return initForm;
  }, [newDeployment]);

  const onGoNext = useCallback(() => {
    if (form) {
      form.submit();
    }
  }, [form]);

  return (
    <React.Fragment>
      <CustomForm
        name="basic"
        style={{
          maxWidth: "100%",
        }}
        initialValues={formInitValues}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row gutter={[48, 0]}>
          <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Row gutter={[24, 0]}>
              <Col lg={{ span: 10 }} md={{ span: 10 }} xs={{ span: 24 }}>
                <label htmlFor="maxLightDistance">
                  <Typography.Text type="danger">*</Typography.Text>
                  {TextConstants.EasySetingsPage.MaxDistanceBetweenLightsLabel}
                  ：
                </label>
              </Col>
              <Col lg={{ span: 14 }} md={{ span: 14 }} xs={{ span: 24 }}>
                <Form.Item
                  name="maxLightDistance"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      type: "number",
                      min: 1,
                      max: 30,
                      message:
                        TextConstants.Common.MaxMinNumberValidateMessage.replace(
                          "{min}",
                          1
                        ).replace("{max}", 30),
                    },
                  ]}
                >
                  <InputNumber
                    autoFocus
                    style={{ width: "150px" }}
                    id="maxLightDistance"
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={[24, 0]}>
              <Col lg={{ span: 10 }} md={{ span: 10 }} xs={{ span: 24 }}>
                <label htmlFor="totalGroup">
                  <Typography.Text type="danger">*</Typography.Text>
                  {TextConstants.EasySetingsPage.TotalGroupsLabel}：
                </label>
              </Col>
              <Col lg={{ span: 14 }} md={{ span: 14 }} xs={{ span: 24 }}>
                <Form.Item
                  name="totalGroup"
                  rules={[
                    {
                      required: true,
                      message: TextConstants.Common.ThisFieldIsRequired,
                    },
                    {
                      type: "number",
                      min: 1,
                      max: 6,
                      message:
                        TextConstants.Common.MaxMinNumberValidateMessage.replace(
                          "{min}",
                          1
                        ).replace("{max}", 6),
                    },
                  ]}
                >
                  <InputNumber style={{ width: "150px" }} id="totalGroup" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col lg={{ span: 10 }} md={{ span: 10 }} xs={{ span: 24 }}>
                <label htmlFor="groupNames_0">
                  <Typography.Text type="danger">*</Typography.Text>
                  {TextConstants.EasySetingsPage.GroupNamesLabel}：
                </label>
              </Col>
              <Col lg={{ span: 14 }} md={{ span: 14 }} xs={{ span: 24 }}>
                {Array(totalGroupValue)
                  .fill()
                  .map((_, i) => {
                    return (
                      <Form.Item
                        key={i}
                        name={`groupNames_${i}`}
                        rules={[
                          {
                            required: true,
                            message: TextConstants.Common.ThisFieldIsRequired,
                          },
                          {
                            max: 255,
                            message:
                              TextConstants.Common.MaxLengthWarningMessage.replace(
                                "{max}",
                                "255"
                              ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={`#${i + 1}`}
                          style={{ width: "150px" }}
                          id={`groupNames_${i}`}
                        />
                      </Form.Item>
                    );
                  })}
              </Col>
            </Row> */}
          </Col>
          <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
            {newDeployment.lightIds &&
              newDeployment.lightIds.length > 0 &&
              newDeployment.lightIds.map((id, index) => {
                const light = lightsObj[id];
                const lightName = light ? light.name : `[ID: ${id}]`;
                return (
                  <Row gutter={[24, 0]} key={index}>
                    <Col lg={{ span: 16 }} md={{ span: 10 }} xs={{ span: 24 }}>
                      <label>
                        <Typography.Text type="danger">*</Typography.Text>
                        {TextConstants.EasySetingsPage.TotalLightsLabel} （
                        {lightName}）：
                      </label>
                    </Col>
                    <Col lg={{ span: 8 }} md={{ span: 14 }} xs={{ span: 24 }}>
                      <Form.Item
                        key={index}
                        name={`totalLights_${id}`}
                        rules={[
                          {
                            required: true,
                          },
                          {
                            type: "number",
                            min: 1,
                            max: 1000,
                            message:
                              TextConstants.Common.MaxMinNumberValidateMessage.replace(
                                "{min}",
                                1
                              ).replace("{max}", 1000),
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "150px" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </CustomForm>
      <StepControlButtons onGoBack={onGoBack} onGoNext={onGoNext} />
    </React.Fragment>
  );
};

export default FloorSettings;
