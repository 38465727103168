import produce from "immer";
import {
  getUserInfo,
  removeTokens,
  removeUserInfo,
  saveRefreshToken,
  saveToken,
  saveUserInfo,
  updateUserInfoFromLocal,
} from "src/helpers/AuthHelper";
import * as Actions from "../actions";

const initState = {
  loading: false,
  user: getUserInfo() || null,
  type: "",
};

const Auth = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.LOGIN.REQUEST:
    case Actions.SIGNUP.REQUEST:
    case Actions.PASSWORD_RESET_REQUEST.REQUEST:
    case Actions.RESET_PASSWORD.REQUEST:
    case Actions.CONFIRM_EMAIL_ADDRESS.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });
    case Actions.LOGIN.SUCCESS:
      const { token, refreshToken, user } = payload;
      saveToken(token);
      saveRefreshToken(refreshToken);
      saveUserInfo(user);

      return produce(state, (draft) => {
        draft.loading = false;
        draft.user = user;
        draft.type = type;
      });
    case Actions.LOGIN.FAILURE:
    case Actions.SIGNUP.FAILURE:
    case Actions.SIGNUP.SUCCESS:
    case Actions.PASSWORD_RESET_REQUEST.SUCCESS:
    case Actions.RESET_PASSWORD.SUCCESS:
    case Actions.PASSWORD_RESET_REQUEST.FAILURE:
    case Actions.RESET_PASSWORD.FAILURE:
    case Actions.CONFIRM_EMAIL_ADDRESS.SUCCESS:
    case Actions.CONFIRM_EMAIL_ADDRESS.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    case Actions.UPDATE_USER_INFO.SUCCESS:
      updateUserInfoFromLocal(payload);
      return produce(state, (draft) => {
        draft.user = getUserInfo();
        draft.type = type;
      });
    case Actions.LOGOUT.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });
    case Actions.LOGOUT.SUCCESS:
      removeTokens();
      removeUserInfo();

      return produce(state, (draft) => {
        draft.loading = false;
        draft.user = null;
        draft.type = type;
      });
    case Actions.LOGOUT.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    default:
      return state;
  }
};

export default Auth;
