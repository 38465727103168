import { Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "src/components/CustomForm";
import TextConstants from "src/constants/TextConstants";
import { Notification } from "src/helpers/Notification";
import { updateFacility } from "src/store/actions";

const EditFacilityModal = ({ setIsShowFacilityEditModal, facility }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { loading } = useSelector((state) => state.Facilities);

  const onSubmit = useCallback(
    (values) => {
      const data = { facilityName: values.facilityName };
      dispatch(
        updateFacility({ facilityId: facility.facilityId, data }, () => {
          Notification.success(
            TextConstants.UpdateFacilitySuccessModal.SuccessMessage
          );
          setIsShowFacilityEditModal(false);
        })
      );
    },
    [dispatch, updateFacility, setIsShowFacilityEditModal]
  );

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <React.Fragment>
      <Modal
        title={TextConstants.Facility.EditFacilityName}
        visible={true}
        onOk={handleSubmit}
        okButtonProps={{ onClick: handleSubmit, disabled: loading }}
        okText={TextConstants.Common.Registration}
        cancelText={TextConstants.Common.Cancel}
        cancelButtonProps={{ disabled: loading }}
        onCancel={() => {
          setIsShowFacilityEditModal(false);
        }}
        confirmLoading={loading}
        width={500}
      >
        <CustomForm
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="row-col"
          initialValues={{ facilityName: facility.facilityName }}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} md={24}>
              <Form.Item
                label={TextConstants.Facility.FacilityName}
                name="facilityName"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      value.length > 0 && !value.trim()
                        ? Promise.reject(
                            new Error(TextConstants.Common.ThisFieldIsRequired)
                          )
                        : Promise.resolve(),
                  },
                  {
                    max: 255,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        "255"
                      ),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
            </Col>
          </Row>
        </CustomForm>
      </Modal>
    </React.Fragment>
  );
};

export default EditFacilityModal;
