import { Col, Form, Input, Modal, Row } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import CustomForm from "src/components/CustomForm";
import InvalidPasswordMessage from "src/components/InvalidPasswordMessage";
import { PASSWORD_REGEX } from "src/constants";
import TextConstants from "src/constants/TextConstants";

const ChangePasswordModal = ({ data, onClose, onSubmit }) => {
  const [form] = Form.useForm();

  const { isUpdating } = useSelector((state) => state.Users);

  const initValues = useMemo(
    () => ({
      password: "",
      confirmPassword: "",
    }),
    []
  );

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <React.Fragment>
      <Modal
        width={680}
        title={`${TextConstants.ChangePasswordModal.Title} - [${data.email}] - [${data.name}]`}
        visible={true}
        onOk={onSubmit}
        okButtonProps={{ onClick: handleSubmit }}
        cancelText={TextConstants.Common.Cancel}
        onCancel={onClose}
        cancelButtonProps={{ disabled: isUpdating }}
        confirmLoading={isUpdating}
      >
        <CustomForm
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="row-col"
          initialValues={initValues}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={TextConstants.Common.NewPassword}
                name="password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: PASSWORD_REGEX,
                    message: <InvalidPasswordMessage />,
                  },
                ]}
              >
                <Input.Password
                  autoComplete="false"
                  placeholder={TextConstants.Common.NewPassword}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={TextConstants.Common.ConfirmPassword}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(TextConstants.Common.PasswordAreNotMatched)
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  autoComplete="false"
                  placeholder={TextConstants.Common.ConfirmPassword}
                />
              </Form.Item>
            </Col>
          </Row>
        </CustomForm>
      </Modal>
    </React.Fragment>
  );
};

export default ChangePasswordModal;
