import axios from "axios";
import { UNAUTH_ENDPOINTS } from "src/constants";
import { logout } from "src/store/actions";
import store from "src/store/store";
import TextConstants from "../constants/TextConstants";
import {
  getRefreshToken,
  getToken,
  isTokenHasExpired,
  saveToken,
} from "./AuthHelper";
import { Notification } from "./Notification";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
});

let _is_refreshing_token = false;

const refreshAccessToken = async (refreshToken) => {
  try {
    _is_refreshing_token = true;
    const refreshTokenRes = await axios.post(
      process.env.REACT_APP_BASE_URL + "/auth/refresh",
      {
        refreshToken,
      }
    );
    if (refreshTokenRes.status === 200) {
      const newAccessToken = refreshTokenRes.data["token"];
      saveToken(newAccessToken);
      _is_refreshing_token = false;
      return newAccessToken;
    } else {
      // Success but status code diff 200
      console.log(
        "1. Error when refresh token! Success but status code diff 200"
      );
      throw Error("Error when refresh token!");
    }
  } catch (error) {
    console.log("2. Error when refresh token!", error);
    store.dispatch(logout());
    return;
  }
};

client.interceptors.request.use(
  async function (config) {
    let accessToken = getToken();
    if (accessToken && !UNAUTH_ENDPOINTS.includes(config.url.split("?")[0])) {
      // When is refreshing token, then wait 200 ms
      while (_is_refreshing_token) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        // get new access token
        accessToken = getToken();
      }

      if (isTokenHasExpired(accessToken)) {
        accessToken = await refreshAccessToken(getRefreshToken());
      }
      config.headers["Authorization"] = accessToken;
    }
    return Promise.resolve(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const status = error.response && error.response.status;
    if (status === 401) {
      store.dispatch(logout());
    }

    const errorCode = error.response && error.response?.data?.code;
    if (errorCode) {
      const messageError = error.response && error.response?.data?.message;
      Notification.error(
        <>
          {messageError
            ? messageError
            : TextConstants.ERRORS[errorCode] || "Unknown Error"}
        </>
      );
    }
    return Promise.reject(error.response);
  }
);

export default client;
