import { Select, Space } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextConstants from "src/constants/TextConstants";
import useQueryStringController from "src/helpers/hooks/useQueryStringController";
import { getCompanies, getFacilities } from "src/store/actions";

const CompanyFacilitiesFilter = ({
  onCompanyChange = () => {},
  onFacilityChange = () => {},
}) => {
  const { searchParams, setSearchParam, deleteSearchParam } =
    useQueryStringController();
  const dispatch = useDispatch();
  const { loading: companiesLoading, companies } = useSelector(
    (state) => state.Companies
  );
  const { loading: facilitiesLoading, facilities } = useSelector(
    (state) => state.Facilities
  );

  const [companyIdSelected, setCompanyIdSelected] = useState();
  const [facilityIdSelected, setFacilityIdSelected] = useState();

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (facilityIdSelected) {
      setSearchParam("facilityId", facilityIdSelected);
    }
    onFacilityChange(facilityIdSelected);
  }, [facilityIdSelected]);

  useEffect(() => {
    if (companyIdSelected) {
      setSearchParam("companyId", companyIdSelected);
      dispatch(getFacilities({ companyId: companyIdSelected }));
      onCompanyChange(companyIdSelected);
    }
  }, [companyIdSelected]);

  useEffect(() => {
    if (companies.length > 0) {
      const companyId = searchParams.get("companyId");
      setCompanyIdSelected(
        companyId &&
          companies.find((company) => company.companyId === companyId)
          ? companyId
          : companies[0].companyId
      );
    }
  }, [companies, setCompanyIdSelected]);

  useEffect(() => {
    if (facilities.length > 0) {
      const facilityIdParam = searchParams.get("facilityId");
      const newFacilityIdSelected =
        facilityIdParam &&
        facilities.find((f) => f.facilityId === facilityIdParam)
          ? facilityIdParam
          : facilities[0].facilityId;
      setFacilityIdSelected(newFacilityIdSelected);
    }
  }, [facilities, setFacilityIdSelected]);

  const companiesOption = useMemo(() => {
    return companies.map((company) => {
      return {
        value: company.companyId,
        label: company.name,
      };
    });
  }, [companies]);

  const facilitiesOption = useMemo(() => {
    return facilities.map((facility) => {
      return {
        value: facility.facilityId,
        label: facility.facilityName,
      };
    });
  }, [facilities]);

  const onSelectCompany = useCallback(
    (companyId) => {
      setCompanyIdSelected(companyId);
      deleteSearchParam("facilityId");
      setFacilityIdSelected();
    },
    [setCompanyIdSelected, setFacilityIdSelected, deleteSearchParam]
  );

  const onSelectFacility = useCallback(
    (facilityId) => {
      setFacilityIdSelected(facilityId);
    },
    [setFacilityIdSelected]
  );

  return (
    <>
      <Space size={"large"} wrap>
        <div>
          <Space>
            {TextConstants.Common.CompanyName}
            <Select
              showSearch
              placeholder={TextConstants.Common.CompanyName}
              value={companyIdSelected}
              loading={companiesLoading}
              style={{
                width: 180,
              }}
              onChange={onSelectCompany}
              options={companiesOption}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Space>
        </div>
        <div>
          <Space>
            {TextConstants.NewFacility.FacilityName}
            <Select
              showSearch
              placeholder={TextConstants.NewFacility.FacilityName}
              value={facilityIdSelected}
              loading={facilitiesLoading || companiesLoading}
              style={{
                width: 180,
              }}
              onChange={onSelectFacility}
              options={facilitiesOption}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Space>
        </div>
      </Space>
    </>
  );
};

export default CompanyFacilitiesFilter;
