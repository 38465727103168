import { Button, Card, Col, Popconfirm, Row } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddButton from "src/components/AddButton";
import BorderTable from "src/components/BorderTable";
import TextConstants from "src/constants/TextConstants";
import {
  changeUserPassword,
  createUser,
  deleteUser,
  getCompanies,
  getUsers,
} from "src/store/actions";

const FacilityFloors = () => {
  const dispatch = useDispatch();

  const { user: loggedInUser } = useSelector((state) => state.Auth);

  const { loading, users } = useSelector((state) => state.Users);
  const { companies } = useSelector((state) => state.Companies);
  const { offices } = useSelector((state) => state.Offices);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const mapCompanies = useMemo(() => {
    let data = {};
    companies.forEach((company) => {
      data[company.companyId] = company.name;
    });
    return data;
  }, [companies]);

  const mapOffices = useMemo(() => {
    let data = {};
    offices.forEach((office) => {
      data[office.officeId] = office.name;
    });
    return data;
  }, [offices]);

  const fetchUsers = useCallback(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const fetchResources = useCallback(() => {
    // get list companies, departments and offices before users to map to table
    dispatch(getCompanies());
  }, [dispatch]);

  useEffect(() => {
    fetchResources();
    fetchUsers();
  }, [dispatch, fetchResources, fetchUsers]);

  const handleOpenCreateModal = () => setShowCreateModal(true);

  const handleCreateUser = (values) => {
    if (values.companyId && values.companyId === "-") {
      delete values.companyId;
    }
    if (values.departmentId && values.departmentId === "-") {
      delete values.departmentId;
    }
    dispatch(
      createUser(values, () => {
        setShowCreateModal(false);
        fetchUsers();
      })
    );
  };

  const handleCloseCreateModal = () => setShowCreateModal(false);

  const handleOpenChangePasswordModal = (user) => {
    setSelectedUser(user);
    setShowChangePasswordModal(true);
  };

  const handleChangePassword = (data) => {
    dispatch(
      changeUserPassword(
        {
          username: selectedUser.email,
          data,
        },
        handleCloseChangePasswordModal
      )
    );
  };

  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
    setSelectedUser();
  };

  const handleDeleteUser = ({ username }) => {
    dispatch(deleteUser(username, fetchUsers));
  };

  const usersDataMapped = useMemo(() => {
    return users.map((user) => {
      return {
        ...user,
        _role: TextConstants.ROLES[user.role],
        _info: `${user.companyId ? mapCompanies[user.companyId] : ""}
        ${user.officeId ? " | " + mapOffices[user.officeId] : ""}`,
      };
    });
  }, [users, mapCompanies, mapOffices]);

  const columns = [
    {
      title: TextConstants.Common.Name,
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: "20%",
    },
    {
      title: TextConstants.Common.Information,
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) => a.role.localeCompare(b.companyName),
      width: "20%",
    },
    {
      title: TextConstants.Common.Actions,
      dataIndex: "_actions",
      key: "_actions",
      canSearch: false,
      width: "15%",
      render: (_, record) => (
        <Row justify="center" gutter={[8, 6]}>
          <Col>
            <Button
              type="primary"
              size="small"
              onClick={() => handleOpenChangePasswordModal(record)}
            >
              {TextConstants.Common.Edit}
            </Button>
          </Col>
          <Col>
            <Popconfirm
              disabled={record.email === loggedInUser.email}
              title={TextConstants.Common.ConfirmDeleteMessage.replace(
                "{item}",
                TextConstants.Common.User
              )}
              onConfirm={() => handleDeleteUser(record)}
              okText={TextConstants.Common.Delete}
              cancelText={TextConstants.Common.Cancel}
              placement="topRight"
            >
              <Button
                size="small"
                disabled={record.email === loggedInUser.email}
                type="danger"
              >
                {TextConstants.Common.Delete}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card className="tablespace mb-24" title={"施設一覧"}>
            <Row gutter={[24, 0]}>
              <Col
                xs={{ span: 24 }}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <AddButton label="ユーザー管理" />
              </Col>
            </Row>

            <BorderTable
              showSearch
              loading={loading}
              columns={columns}
              dataSource={usersDataMapped}
              rowKey={"username"}
              header={
                <AddButton
                  onClick={handleOpenCreateModal}
                  label="フロアの追加"
                />
              }
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FacilityFloors;
