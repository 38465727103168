import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ASCII_REGEX,
  EMAIL_REGEX,
  MAX_NUM_USERS_INVITE_ONE_TIME,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { inviteUsers } from "src/store/actions";

const defaultUsers = [
  {
    email: "",
    error: TextConstants.Common.ThisFieldIsRequired,
    deletable: false,
    touched: false,
  },
];

const InviteUsersModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const { loading, inviteUsersLoading } = useSelector((state) => state.Users);

  const [users, setUsers] = useState(defaultUsers);

  const hasError = useMemo(() => {
    const errors = users.map((u) => !!u.error);
    return errors.includes(true);
  }, [users]);

  const isReachTheLimitUsers = useMemo(
    () => users.length >= MAX_NUM_USERS_INVITE_ONE_TIME,
    [users]
  );

  const handleAddRecord = () => {
    setUsers([
      ...users,
      {
        email: "",
        error: TextConstants.Common.ThisFieldIsRequired,
        deletable: true,
        touched: false,
      },
    ]);
  };

  const handleRemoveRecord = (removeIndex) => {
    setUsers(users.filter((_, idx) => idx !== removeIndex));
  };

  const handleInputChanged = (index, value) => {
    let errorMessage = "";
    const allEmails = users.filter((u) => !!u.email).map((u) => u.email);
    if (!value) {
      errorMessage = TextConstants.Common.ThisFieldIsRequired;
    } else if (!ASCII_REGEX.test(value)) {
      errorMessage = TextConstants.Common.OnlyAcceptASCIIMessage;
    } else if (!EMAIL_REGEX.test(value)) {
      errorMessage = TextConstants.Common.InvalidEmailMessage;
    } else if (value.length > 254) {
      errorMessage = TextConstants.Common.MaxLengthWarningMessage.replace(
        "{max}",
        "254"
      );
    } else if (allEmails.includes(value)) {
      errorMessage = TextConstants.Common.ThisEmailAlreadyExists;
    }
    setUsers(
      users.map((user, idx) =>
        idx === index
          ? {
              ...user,
              email: value,
              touched: true,
              error: errorMessage,
            }
          : user
      )
    );
  };

  const handleInputBlurChanged = (index) => {
    setUsers(
      users.map((user, idx) =>
        idx === index
          ? {
              ...user,
              touched: true,
            }
          : user
      )
    );
  };

  const handleSubmit = () => {
    const emails = users.filter((u) => !!u.email).map((u) => u.email);
    if (emails.length) {
      dispatch(
        inviteUsers(emails, () => {
          setUsers(defaultUsers);
          onClose && onClose();
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Modal
        title={TextConstants.NewFacility.InviteUsers}
        open={true}
        onOk={handleSubmit}
        okButtonProps={{ disabled: hasError }}
        cancelText={TextConstants.Common.Cancel}
        okText={TextConstants.Common.Invitation}
        onCancel={() => {
          onClose(false);
        }}
        cancelButtonProps={{ disabled: loading }}
        confirmLoading={loading || inviteUsersLoading}
        width={500}
      >
        <Row gutter={[24, 0]}>
          <Col xs={{ span: 24 }}>
            {users.map((user, idx) => (
              <Row gutter={[24, 0]} key={idx}>
                <Col xs={{ span: 20 }}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    validateStatus={
                      user.touched && user.error
                        ? "error"
                        : user.touched && !user.error
                        ? "success"
                        : ""
                    }
                    help={user.touched && user.error ? user.error : ""}
                  >
                    <Input
                      placeholder={`${TextConstants.Common.EmailAddress} #${
                        idx + 1
                      }`}
                      disabled={loading}
                      value={user.email}
                      onChange={(e) => handleInputChanged(idx, e.target.value)}
                      onBlur={() => handleInputBlurChanged(idx)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 4 }}>
                  <Form.Item>
                    <Button
                      hidden={!user.deletable}
                      disabled={loading}
                      type="danger"
                      onClick={() => handleRemoveRecord(idx)}
                    >
                      <MinusOutlined />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                disabled={loading || isReachTheLimitUsers}
                type="primary"
                onClick={handleAddRecord}
                icon={<PlusOutlined />}
              >
                {TextConstants.Facility.AddUsersToInvite}
              </Button>
            </Form.Item>
            <Form.Item>
              <Alert
                message={`一度の招待できる数は50になります。${users.length}/${MAX_NUM_USERS_INVITE_ONE_TIME}`}
                type="info"
                showIcon
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default InviteUsersModal;
