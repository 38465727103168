import { Divider, Empty, Typography } from "antd";
import React from "react";
import BorderTable from "src/components/BorderTable";
import TextConstants from "src/constants/TextConstants";
import {
  parseSwitchBrightnessValueToUIReadable,
  parseSwitchColorValueToUIReadable,
  parseSwitchConfigValueToText,
} from "src/helpers/helpers";

const { Title } = Typography;

const SwitchSettings = ({ switches, loading, dimmingStepVal = 0 }) => {
  const columns = [
    {
      title: "No",
      dataIndex: "buttonID",
      key: "buttonID",
      render: (val) => {
        return <>{String(val || 0)}</>;
      },
    },
    {
      title: TextConstants.FloorBMLiteInformation.SimpleSettings,
      key: "_simple_settings",
      render: (_, record) => {
        return record.mode === 0 && record.settings?.length ? (
          <>
            <p>
              {TextConstants.Common.GroupName}: {record.settings[0].groupName}
            </p>
            {record.configType === 0 && ( // simple mode: Brightness
              <p>
                {TextConstants.FloorBMLiteInformation.Brightness}:{" "}
                {parseSwitchConfigValueToText(
                  parseSwitchBrightnessValueToUIReadable(
                    record.settings[0].brightness
                  ),
                  dimmingStepVal
                )}
              </p>
            )}
            {record.configType === 1 && ( // simple mode: colors
              <p>
                {TextConstants.FloorBMLiteInformation.ColorAdjustment}:{" "}
                {parseSwitchConfigValueToText(
                  parseSwitchColorValueToUIReadable(record.settings[0].color),
                  dimmingStepVal
                )}
              </p>
            )}
          </>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: TextConstants.FloorBMLiteInformation.DetailedSettings,
      key: "_detailed_settings",
      render: (_, record) => {
        return record.mode === 1 && record.settings?.length ? (
          <>
            {record.settings.map((st) => {
              return (
                <p>
                  {TextConstants.Common.GroupName}: {st.groupName}、
                  {TextConstants.FloorBMLiteInformation.Brightness}:{" "}
                  {parseSwitchConfigValueToText(
                    parseSwitchBrightnessValueToUIReadable(st.brightness),
                    dimmingStepVal
                  )}
                  、{TextConstants.FloorBMLiteInformation.ColorAdjustment}:{" "}
                  {parseSwitchConfigValueToText(
                    parseSwitchColorValueToUIReadable(st.color),
                    dimmingStepVal
                  )}
                </p>
              );
            })}
          </>
        ) : (
          <>-</>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Divider orientation="left">
        {TextConstants.FloorBMLiteInformation.SwitchSettings}
      </Divider>
      {!switches ||
        (switches.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={TextConstants.Common.NoData}
          />
        ))}
      {switches.map((switchData) => {
        return (
          <BorderTable
            header={<Title level={5}>{switchData.name}</Title>}
            loading={loading}
            columns={columns}
            dataSource={switchData.buttons}
            rowKey={"buttonID"}
            showPagination={false}
            minHeight={0}
          />
        );
      })}
    </React.Fragment>
  );
};

export default SwitchSettings;
