import { Card, Col, Row } from "antd";
import React from "react";
import TextConstants from "src/constants/TextConstants";

const PowerData = () => {
  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card
            className="tablespace mb-24"
            title={TextConstants.Pages.PowerData}
          >
            TBD!
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PowerData;
