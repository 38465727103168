import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, Typography, Upload } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import noImage50 from "src/assets/images/no-image_50.png";
import {
  API_ENDPOINTS,
  FLOOR_SETTINGS_FILE_TYPE,
  NEW_FLOOR_MAP_SETTING_MODE,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";
import client from "src/helpers/HTTPClient";
import { Notification } from "src/helpers/Notification";
import { getLights } from "src/store/actions";
import StepControlButtons from "./components/StepControlButtons/StepControlButtons";

const columns = [
  {
    title: TextConstants.LightSelectionPage.SoftID,
    dataIndex: "lightId",
    width: "10%",
  },
  {
    title: TextConstants.LightSelectionPage.LightName,
    dataIndex: "name",
    width: "30%",
  },
  {
    title: TextConstants.LightSelectionPage.LightType,
    dataIndex: "type",
    width: "20%",
  },
  {
    title: TextConstants.LightSelectionPage.Detail,
    dataIndex: "detail",
    width: "30%",
    render: (colVal, { imageURL }) => (
      <>
        <Row gutter={[24, 0]} justify={"space-between"}>
          <Col xs={{ span: 24 }} md={{ span: 16 }} xxl={{ span: 20 }}>
            {colVal || TextConstants.Common.None}
          </Col>
          {/* <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 4 }}>
            {imageURL && (
              <img
                src={imageURL}
                title="device"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = noImage50;
                }}
              />
            )}
          </Col> */}
        </Row>
      </>
    ),
  },
  Table.SELECTION_COLUMN,
];

const getPresignedUrl = async (facilityId, fileName) => {
  try {
    // Make an API call to your server or directly to S3 to get the presigned URL
    const response = await client.get(
      `${API_ENDPOINTS.GEN_FLOORS_SETTINGS_FILE_PRESIGNED_URL_POST}?settingType=${FLOOR_SETTINGS_FILE_TYPE.MAP_IMAGE}&fileName=${fileName}&facilityId=${facilityId}`
    );
    return response; // Assuming the API response contains the presigned URL
  } catch (error) {
    console.error("error getPresignedUrl", error);
    Notification.error(TextConstants.Toasts.Error);
    return null;
  }
};

const buildUploadMapFormObj = (s3SignedData, file) => {
  const formdata = new FormData();
  formdata.append("AWSAccessKeyId", s3SignedData.fields?.["AWSAccessKeyId"]);
  formdata.append("key", s3SignedData.fields?.["key"]);
  formdata.append("policy", s3SignedData.fields?.["policy"]);
  formdata.append("signature", s3SignedData.fields?.["signature"]);
  formdata.append(
    "x-amz-security-token",
    s3SignedData.fields?.["x-amz-security-token"]
  );
  formdata.append("file", file);

  return formdata;
};

const uploadFileProps = {
  accept: "image/png, image/jpeg, image/pjp, image/pjpeg",
  maxCount: 1,
  beforeUpload: (file) => {
    const isPNGorJPG = [
      "image/png",
      "image/jpeg",
      "image/pjp",
      "image/pjpeg",
    ].includes(file.type);
    if (!isPNGorJPG) {
      Notification.error(
        TextConstants.Common.InvalidImageFileMsg.replace(
          file.name,
          "{fileName}"
        )
      );
    }
    return isPNGorJPG || Upload.LIST_IGNORE;
  },
};

const LightSelection = ({
  facilityId,
  mode,
  onGoBack,
  updateNewDeploymentLocal,
  newDeployment,
  lights = [],
}) => {
  const dispatch = useDispatch();

  const [warningModal, contextHolder] = Modal.useModal();

  const [isSelectedAllLights, setIsSelectedAllLights] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(getLights());
  }, [dispatch]);

  const lightsDataUpdated = useMemo(() => {
    const lightsSorted = [...lights].sort((a, b) =>
      String(a.lightId).localeCompare(String(b.lightId))
    );

    return lightsSorted.map((data) => {
      return { ...data, key: data.lightId, choose: "" };
    });
  }, [lights]);

  useEffect(() => {
    if (newDeployment && newDeployment.officeName) {
      setSelectedRowKeys(newDeployment.lightIds || []);

      if (newDeployment.lightIds && newDeployment.lightIds.length > 0) {
        setIsSelectedAllLights(newDeployment.lightIds.length === lights.length);
      }
    }
  }, [newDeployment, lights]);

  const onGoNext = useCallback(() => {
    if (
      mode === NEW_FLOOR_MAP_SETTING_MODE.MANUAL &&
      !newDeployment.mapImageTempFilePath
    ) {
      showWarning(TextConstants.LightSelectionPage.MustChooseMapImageFile);
      return;
    }

    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      showWarning(
        TextConstants.LightSelectionPage.MustChooseLightWarningMessage
      );
    } else {
      updateNewDeploymentLocal({
        lightIds: selectedRowKeys,
        stepNo: 1,
      });
    }
  }, [updateNewDeploymentLocal, selectedRowKeys]);

  const showWarning = (msg) => {
    warningModal.warning({
      title: TextConstants.Toasts.Warning,
      content: msg,
    });
  };

  const rowSelection = useMemo(() => {
    return {
      selectedRowKeys,
      columnTitle: (
        <>
          <Checkbox
            style={{ marginLeft: "40%" }}
            id="chooseAllLights"
            checked={isSelectedAllLights}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setIsSelectedAllLights(isChecked);
              if (isChecked) {
                setSelectedRowKeys(lights.map((d) => d.lightId));
              } else {
                setSelectedRowKeys([]);
              }
            }}
          ></Checkbox>
          <label htmlFor="chooseAllLights" style={{ margin: "0 10px" }}>
            {TextConstants.LightSelectionPage.Choice}
          </label>
        </>
      ),
      onChange: (selectedRowKeys) => {
        if (selectedRowKeys.length === lights.length) {
          setIsSelectedAllLights(true);
        } else {
          setIsSelectedAllLights(false);
        }
        setSelectedRowKeys(selectedRowKeys);
      },
    };
  }, [lights, isSelectedAllLights, setIsSelectedAllLights, selectedRowKeys]);

  return (
    <React.Fragment>
      {/* manual mode */}
      {mode === NEW_FLOOR_MAP_SETTING_MODE.MANUAL && (
        <FloorMapUpload
          facilityId={facilityId}
          newDeployment={newDeployment}
          updateNewDeploymentLocal={updateNewDeploymentLocal}
        />
      )}

      <Typography.Title level={5}>
        {TextConstants.LightSelectionPage.LightingSelection}
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={lightsDataUpdated}
        rowSelection={rowSelection}
        bordered
        pagination={false}
        size="small"
        scroll={{
          y: 500,
        }}
        style={{ overflow: "auto" }}
      />
      <StepControlButtons onGoBack={onGoBack} onGoNext={onGoNext} />
      {contextHolder}
    </React.Fragment>
  );
};

const FloorMapUpload = ({
  facilityId,
  newDeployment,
  updateNewDeploymentLocal,
}) => {
  const dispatch = useDispatch();

  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const defaultFileList = useMemo(() => {
    if (newDeployment.mapImageTempFilePath) {
      const pathSplitted = newDeployment.mapImageTempFilePath.split("/");
      return [
        {
          uid: "1",
          name: pathSplitted[pathSplitted.length - 1],
          status: "done",
        },
      ];
    }
    return [];
  }, [newDeployment]);

  const customRequest = useCallback(
    async ({ file, onSuccess, onError }) => {
      const signedData = await getPresignedUrl(facilityId, file.name);
      if (signedData) {
        try {
          await axios.post(
            signedData.url,
            buildUploadMapFormObj(signedData, file),
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST",
              },
            }
          );
          onSuccess();
          Notification.success(
            TextConstants.Common.FileUploadedSuccessfully.replace(
              "{fileName}",
              file.name
            )
          );
          updateNewDeploymentLocal({
            mapImageTempFilePath: signedData.fields?.["key"],
          });
        } catch (error) {
          console.error("Error uploading file to S3:", error);
          onError();
          Notification.error(
            TextConstants.Common.FileUploadedSuccessfully.replace(
              "{fileName}",
              file.name
            )
          );
        }
      } else {
        onError();
      }
    },
    [dispatch, updateNewDeploymentLocal]
  );

  const onUploadMapFileChange = useCallback(
    (info) => {
      setIsUploadingFile(info.file.status === "uploading");
    },
    [setIsUploadingFile]
  );

  return (
    <>
      <Row gutter={[24, 0]} className="mb-20">
        <Col xs={{ span: 24 }}>
          <Typography.Title level={5}>
            {TextConstants.NewFloorMapSettings.FloorMapUpload}
          </Typography.Title>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 10 }}>
          <Upload
            disabled={isUploadingFile}
            {...uploadFileProps}
            customRequest={customRequest}
            onChange={onUploadMapFileChange}
            defaultFileList={defaultFileList}
          >
            <Button
              type="primary"
              icon={<CloudUploadOutlined />}
              disabled={isUploadingFile}
            >
              {TextConstants.Common.SelectFiles}
            </Button>
          </Upload>
        </Col>
      </Row>
    </>
  );
};

export default LightSelection;
