import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Popconfirm, Row, Space } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddButton from "src/components/AddButton";
import BorderTable from "src/components/BorderTable";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { deleteFacility, getCompanies, getFacilities } from "src/store/actions";
import EditFacilityModal from "../facilities/EditFacilityModal";
import CreateFacilityModal from "./CreateFacilityModal";

const SystemAdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading: facilitiesLoading, facilities } = useSelector(
    (state) => state.Facilities
  );
  const { loading: companiesLoading, companyNameMappedByIds } = useSelector(
    (state) => state.Companies
  );

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isShowFacilityEditModal, setIsShowFacilityEditModal] = useState(false);
  const [targetFacility, setTargetFacility] = useState({});

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getFacilities());
  }, []);

  const facilitiesDataMapped = useMemo(() => {
    return facilities.map((facility) => {
      const faComName = companyNameMappedByIds[facility.companyId] || "-";
      return {
        ...facility,
        companyName: faComName,
        createdAt: dayjs(facility.createdAt).format("YYYY/MM/DD HH:mm:ss"),
      };
    });
  }, [facilities]);

  const handleDeleteFacility = (facilityId) => {
    dispatch(
      deleteFacility(facilityId, () => {
        dispatch(getFacilities());
      })
    );
  };

  const moveToFacilityUserMangement = (facilityId) => {
    navigate(
      PATHS.FACILITY_MANAGE_FACILITY_USERS.replace(":facilityId", facilityId)
    );
  };

  const moveToFacilityFloorPlan = (companyId, facilityId) => {
    navigate(
      `${PATHS.ADMIN_FLOOR_PLAN}?companyId=${companyId}&facilityId=${facilityId}`
    );
  };

  const columns = [
    {
      title: TextConstants.Common.Name,
      dataIndex: "facilityName",
      key: "facilityName",
      sorter: (a, b) => a.facilityName.localeCompare(b.facilityName),
      width: 30,
    },
    {
      title: TextConstants.Common.CompanyName,
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      width: 30,
    },
    {
      title: TextConstants.Common.CreatedAt,
      dataIndex: "createdAt",
      key: "createdAt",
      width: 10,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      defaultSortOrder: "descend", // Set default sorting order
    },
    {
      title: TextConstants.Common.Actions,
      dataIndex: "_actions",
      key: "_actions",
      canSearch: false,
      width: 30,
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setTargetFacility(record);
              setIsShowFacilityEditModal(true);
            }}
          >
            {TextConstants.Facility.EditFacilityName}
          </Button>
          <Popconfirm
            title={TextConstants.Facility.RemoveFacilityConfirmMessage}
            onConfirm={() => handleDeleteFacility(record.facilityId)}
            okText={TextConstants.Common.Delete}
            cancelText={TextConstants.Common.Cancel}
            placement="topRight"
          >
            <Button
              size="small"
              type="danger"
              icon={<DeleteOutlined />}
            >
              {TextConstants.Common.Delete}
            </Button>
          </Popconfirm>

          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        moveToFacilityUserMangement(record.facilityId);
                      }}
                      icon={<UserOutlined />}
                    >
                      {TextConstants.Pages.UserManagement}
                    </Button>
                  ),
                  key: "0",
                },
                {
                  label: (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        moveToFacilityFloorPlan(
                          record.companyId,
                          record.facilityId
                        );
                      }}
                      icon={<TableOutlined />}
                    >
                      {TextConstants.Pages.FloorPlan}
                    </Button>
                  ),
                  key: "1",
                },
              ],
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Button size="small" type="primary">
                <MoreOutlined />
              </Button>
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handleOpenCreateModal = () => setShowCreateModal(true);
  const handleCloseCreateModal = () => setShowCreateModal(false);

  return (
    <React.Fragment>
      {showCreateModal && (
        <CreateFacilityModal onClose={handleCloseCreateModal} />
      )}
      {isShowFacilityEditModal && (
        <EditFacilityModal
          setIsShowFacilityEditModal={setIsShowFacilityEditModal}
          facility={targetFacility}
        />
      )}
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card
            className="common-card tablespace"
            title={TextConstants.Pages.FacilityList}
          >
            <BorderTable
              showSearch
              searchInputPlaceHolder={TextConstants.Common.Input}
              loading={facilitiesLoading || companiesLoading}
              columns={columns}
              dataSource={facilitiesDataMapped}
              rowKey={"facilityId"}
              header={
                <AddButton onClick={handleOpenCreateModal} label="施設を追加" />
              }
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SystemAdminDashboard;
