import { Checkbox, Form, Radio } from "antd";
import { useState } from "react";
import TextConstants from "src/constants/TextConstants";

const YesNoSelector = ({ form, name, requiredYes = true }) => {
  const [isChooseYes, setIsChooseYes] = useState();
  const YesNoOptions = [
    {
      label: TextConstants.Common.Yes,
      value: 1,
    },
    {
      label: TextConstants.Common.No,
      value: 0,
    },
  ];
  return (
    <>
      <Radio.Group
        options={YesNoOptions}
        onChange={(e) => {
          const value = e.target.value;
          form.setFieldValue(name, !!value);
          setIsChooseYes(value);
          form.validateFields([name]);
        }}
        buttonStyle="solid"
        value={isChooseYes}
        optionType="button"
        className="new-deployment-yes-no-selector"
      />
      <Form.Item
        style={{ float: "left", marginTop: -4 }}
        className="yes-no-selector-checkbox"
        valuePropName="checked"
        name={name}
        rules={
          requiredYes
            ? [
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(TextConstants.Common.YesIsRequired)
                        ),
                },
              ]
            : []
        }
      >
        <Checkbox
          style={{ marginLeft: 4, marginRight: 10, display: "none" }}
        ></Checkbox>
      </Form.Item>
    </>
  );
};

export default YesNoSelector;
