import { Button, Checkbox, Col, Form, Layout, Row, Typography } from "antd";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "src/assets/images/dark-logo.png";
import AppReleaseVersion from "src/components/AppReleaseVersion";
import CustomForm from "src/components/CustomForm";
import TextConstants from "src/constants/TextConstants";
import useQuery from "src/helpers/hooks/useQuery";
import { PATHS } from "src/routes";
const { Content } = Layout;

const TermsOfUser = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [form] = Form.useForm();

  const { loading } = useSelector((state) => state.Auth);

  const initValues = {
    isAgreeTermsAndConditions: false,
  };

  const onFinish = (formValues) => {
    const userToken = query.get("token");
    const tokenParam = userToken ? `?token=${userToken}` : "";
    navigate(`${PATHS.SIGNUP}${tokenParam}`, {
      state: {
        isAgreeTermsAndConditions: formValues.isAgreeTermsAndConditions,
      },
    });
  };

  const redirectToLoginPage = () => {
    navigate(`${PATHS.LOGIN}`);
  };

  return (
    <Layout className="layout-default">
      <Content>
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 8 }}
            lg={{ span: 12 }}
            md={{ span: 16 }}
            xs={{ span: 24 }}
          >
            <div className="d-flex justify-content-center">
              <img src={logo} alt="LICONEX LITE" className="home-logo" />
            </div>
            <div className="d-flex justify-content-center mb-20">
              <Typography.Title level={3}>
                {TextConstants.TermsOfUser.Title}
              </Typography.Title>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xxl={{ span: 18 }}
            lg={{ span: 20 }}
            md={{ span: 22 }}
            xs={{ span: 22 }}
          >
            <div className="terms-of-user-contitions-container mb-20">
              <h2>LiCONEX LiTE利用規約</h2>
              <p>
                この規約（以下「本規約」といいます。）は、アイリスオーヤマ株式会社（以下「当社」と
                いいます。）が提供する照明制御システムアプリ「LiCONEX
                LiTE」（以下「本アプリ」
                といいます。）の利用に関する条件を、本アプリを利用するすべての利用者（以下「利用者」
                といいます。）と当社との間で定めるものです。
                <Typography.Text underline type="danger">
                  利用者は、本アプリを利用する前に、本規約をよくお読みください。
                </Typography.Text>
              </p>
              <br />
              <h3>第 1 条（本規約 への同意）</h3>
              <p>
                <Typography.Text underline type="danger">
                  1.
                </Typography.Text>
                利用者は、本規約に従って本アプリを利用するものとし、本規約に同意しない限り本アプリを利用することはできません。なお、本アプリの利用を開始した場合は、本規約に同意したものとみなします（本規約に基づき成立する契約を、以下「本アプリ利用契約」といいます。）。
              </p>
              <p>
                <Typography.Text underline type="danger">
                  2.本アプリはフリーソフトウェアとなります。
                </Typography.Text>
              </p>
              <p>第 2 条（本規約の改定・変更）</p>
              <p>
                1.当社は、当社が必要と判断する場合、本規約の内容を変更又は追加できるものとします。
              </p>
              <p>
                2.当社は、前項の変更又は追加を行うときは、利用者に改定後の内容を通知の上、同意を得るものとします。
              </p>
              <br />
              <p>第 3 条（本アプリの機能）</p>
              <p>
                本アプリは、当社製品であるLED照明機器「ECOHiLUX」のうち、照明制御機能LiCONEX対応の照明を操作、設定するためのソフトウェアです。利用者は、本アプリのかかる特性を踏まえ、自己の判断と責任において本アプリを利用するものとします。
              </p>
              <br />
              <p>第 4 条（利用制限）</p>
              <p>
                本アプリは、利用者自身における利用を目的として提供されるものであり、利用者は、本アプリにより提供されるコンテンツを第三者に販売すること、その他商業目的で使用することはできません。
              </p>
              <p>第 4 条（利用制限）</p>
              <p>
                本アプリは、利用者自身における利用を目的として提供されるものであり、利用者は、本アプリにより提供されるコンテンツを第三者に販売することその他商業目的で使用することはできません。
              </p>
              <br />
              <p>第 5 条（ログイン情報）</p>
              <p>
                1.本アプリの利用にあたり、利用者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこの承認を登録希望者に通知することによって、利用登録が完了するものとします。
              </p>
              <p>
                2.当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
              </p>
              <ul>
                <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                <li>本規約に違反したことがある者からの申請である場合</li>
                <li>その他，当社が利用登録を相当でないと判断した場合</li>
              </ul>
              <p>
                3.
                利用者は、自らの管理責任により、ログイン情報を不正使用されないよう管理するものとします。
              </p>
              <p>
                4.当社は、ログイン情報の不正利用によって利用者に生じた損害について責任を負いません。
              </p>
              <br />
              <p>第 6 条（ご利用環境の整備・維持）</p>
              <p>
                1.利用者は、自己の費用と責任において、当社が定める条件にて利用者の設備・機器等を設定し、本アプリ利用のための環境を維持するものとします。{" "}
              </p>
              <p>
                2.利用者は、本アプリを利用するにあたり自己の責任と費用をもって、電気通信事業者等の電気通信サービスを利用して利用者の設備をインターネットに接続するものとします。{" "}
              </p>
              <p>
                3.利用者の設備、前項に定めるインターネット接続並びに本アプリ利用のための環境に不具合がある場合、当社は利用者に対して本アプリの提供義務を負わないものとします。
              </p>
              <br />
              <p>
                第 7 条（個人情報の取扱い）
                当社は、個人情報について、当社が別途定めるプライバシーポリシー（以下「プライバシーポリシー」といいます。）に則り、適正に取り扱うこととします。
              </p>
              <br />
              <p>第 8 条（禁止行為）</p>
              <p>
                1.利用者は、本アプリの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
              </p>
              <p>
                (1)
                当社又は他の利用者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、
                その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）{" "}
              </p>
              <p>(2) 犯罪行為に関連する行為又は公序良俗に反する行為 </p>
              <p>
                (3)
                法令又は当社若しくは利用者が所属する業界団体の内部規則に違反する行為
                (4) 他の利用者の利用を妨害する行為又はそのおそれがある行為{" "}
              </p>
              <p>
                (5)
                コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為{" "}
              </p>
              <p>
                (6)
                本アプリを構成するハードウェア又はソフトウェアへの不正アクセス行為、クラッキン
                グ行為その他設備等に支障を与える等の行為{" "}
              </p>
              <p>(7) 本アプリに関し利用しうる情報を改竄する行為 </p>
              <p>
                (8)
                本アプリに関し、リバースエンジニアリング、逆コンパイル又は逆アセンブル等を実施する行為{" "}
              </p>
              <p>(9) 当社による本アプリの運営を妨害するおそれのある行為 </p>
              <p>
                (10)他人のメールアドレスを使用する行為又はその入手を試みる行為{" "}
              </p>
              <p>(11)反社会的勢力等へ利益を供与する行為 </p>
              <p>(12)その他、当社が不適切と判断する行為 </p>
              <p>
                2.当社は、本アプリにおける利用者による情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、利用者に事前に通知することなく、当該情報の全部又は一部を削除することができるものとします。当社は、本項に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。
              </p>
              <br />第 9 条（規約違反に対する措置等）
              <p>
                1.当社は、利用者が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告
                することなく、当該利用者について本アプリの利用を一時的に停止し、又は本アプリ利用登録を解除することができます。{" "}
              </p>
              <p>(1) 本規約のいずれかの条項に違反した場合 </p>
              <p>
                (2)
                本規約上、特定の日時又は一定の期間内に履行をしなければ本規約の目的を達することができない場合において、利用者が履行をしないでその時期を経過した場合{" "}
              </p>
              <p>
                (3)
                前各号に掲げる場合のほか、利用者がその債務の履行をせず、契約をした目的を達するのに足りる履行がされる見込みがないことが明らかである場合{" "}
              </p>
              <p>
                (4)
                本アプリの利用にあたり当社に提供した情報又は当社が利用者に要請した情報に虚偽の事実があることが判明した場合
              </p>
              <p>
                (5)
                当社、他の利用者その他の第三者に損害を生じさせるおそれのある目的又は方法で本ア
                プリを利用した、又は利用しようとした場合{" "}
              </p>
              <p>(6) 手段の如何を問わず、本アプリの運営を妨害した場合</p>
              <p>
                (7)死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合{" "}
              </p>
              <p>(8)当社からの連絡に対して応答がない場合 </p>
              <p>
                2.当社は、当社の故意又は重過失がない限り、本条に基づき当社が行った行為により利用者
                に生じた損害について一切の責任を負いません。
              </p>
              <br />
              <p>第 10 条（損害賠償） </p>
              <p>
                1.利用者は、本規約に違反することにより、又は本アプリの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。{" "}
              </p>
              <p>
                2.利用者が、本アプリに関連して他の利用者その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、利用者の費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
              </p>
              <p>
                3.利用者による本アプリの利用に関連して、当社が、他の利用者その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、利用者は当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。{" "}
              </p>
              <p>
                4.第1項又は第2項の定めにかかわらず、当社は、利用者に生じた損害のうち特別な事情から生じた損害（当社又は利用者が損害発生につき予見したもの及び予見すべきであったものを含みます。）については一切の責任を負わないものとします。
              </p>
              <br />
              <p>第 11 条（知的財産権の帰属） </p>
              <p>
                利用者及び当社は、本アプリを構成する一切の発明、考案、意匠、著作物（当社が利用者の
                依頼を受けて利用者のために作成する著作物を含みます。）、商標、商号その他事業活動に用いられる商品又は役務を表示するもの及び営業秘密その他の事業活動に有用な技術上又は
                営業上の情報に関する、特許権、実用新案権、意匠権、著作権（著作権法第
                27 条及び第 28
                条に規定する権利を含みます。）、商標権を含む一切の権利が当社に帰属し、もしくは当社が使用許諾を受けていることを確認します。{" "}
              </p>
              <br />
              <p>第 12 条（本アプリの変更・停止等）</p>
              <p>
                1.当社は、利用者に事前に通知することなく、本アプリの内容の全部又は一部を変更又は追
                加することができるものとします。ただし、当該変更又は追加によって、変更又は追加前の
                本アプリのすべての機能・性能が維持されることを保証するものではありません。
              </p>
              <p>
                2.当社は、以下のいずれかに該当する場合には、本アプリの利用の全部又は一部を停止又は
                中断することができるものとします。この場合において、当社は利用者に対して、できる限
                り事前に通知するよう努めるものとします。
              </p>
              <p>
                (1)
                本アプリに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う
                場合{" "}
              </p>
              <p>(2) コンピューター、通信回線等が事故により停止した場合 </p>
              <p>
                (3)
                火災、停電、天災地変等の不可抗力により本アプリの運営ができなくなった場合
              </p>
              <p>
                (4)
                その他、当社が本アプリの停止又は中断を必要と合理的に判断した場合{" "}
              </p>
              <p>
                3.当社は、本条により利用者に生じた不利益、損害について責任を負いません。{" "}
              </p>
              <br />
              <p>第 13 条（保証の制限及び免責）</p>
              <p>
                1.当社は、本アプリが、重要な点において、実質的に正常に提供されることを保証します。{" "}
              </p>
              <p>
                2.利用者は自己の責任において本アプリを利用するものとし、当社は、当社の故意又は重過失がない限り、利用者による本アプリの利用に起因して利用者に生じた損害について責任を負いません。
              </p>
              <br />
              <p>第 14 条（反社会的勢力の排除）</p>
              <p>
                1.利用者及び当社は、現在、暴力団、暴力団員、暴力団員でなくなった時から5
                年を経過し
                ない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下「暴力団員等」という。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
              </p>
              <p>
                (1) 暴力団員等が経営を支配していると認められる関係を有すること{" "}
              </p>
              <p>
                (2)
                暴力団員等が経営に実質的に関与していると認められる関係を有すること{" "}
              </p>
              <p>
                (3)
                自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること{" "}
              </p>
              <p>
                (4)
                暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること{" "}
              </p>
              <p>
                (5)
                役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係
                を有すること
              </p>
              <p>
                2.利用者及び当社は、自ら又は第三者を利用して次の各号のいずれにも該当する行為を行わ
                ないことを確約します。{" "}
              </p>
              <p>(1) 暴力的な要求行為 </p>
              <p>(2) 法的な責任を超えた不当な要求行為</p>
              <p>(3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為 </p>
              <p>
                (4)
                風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為{" "}
              </p>
              <p>(5) その他前各号に準ずる行為 </p>
              <p>
                3.利用者又は当社が、暴力団員等若しくは第 1
                項各号のいずれかに該当し、若しくは前項各
                号のいずれかに該当する行為をし、又は第 1
                項の規定にもとづく表明・確約に関して虚偽の申告をしたことが判明した場合には、自己の責に帰すべき事由の有無を問わず、相手方に対して何らの催告をすることなく本アプリ利用契約を解除することができるものとします。{" "}
              </p>
              <p>
                4.利用者及び当社は、前項により本アプリ利用契約を解除した場合には、相手方に損害が生じたとしてもこれを一切賠償する責任はないことを確認し、これを了承します。{" "}
              </p>
              <br />
              <p>第15条（連絡・通知） </p>
              <p>
                本アプリに関する問い合わせその他利用者から当社に対する連絡又は通知、及び本規約の
                変更に関する通知その他当社から利用者に対する連絡又は通知は、電子メールその他当社の定める方法で行うものとします。通知は、当社からの発信によってその効力が生ずるものとします。{" "}
              </p>
              <br />
              <p>第 16 条（地位の譲渡等） </p>
              <p>
                利用者は、相手方の書面による事前の承諾なく、本アプリ利用契約上の地位又は本規約に基づく権利若しくは義務の全部又は一部につき、第三者に対し、譲渡、移転、担保設定、その
                他の処分をすることはできません。{" "}
              </p>
              <br />
              <p>第 17 条（分離可能性） </p>
              <p>
                1.本規約のいずれかの条項又はその一部が無効又は執行不能と判断とされた場合であっても、当該判断は他の部分に影響を及ぼさず、本規約の残りの部分は、引き続き有効かつ執行力を有するものとします。当社及び利用者は、当該無効若しくは執行不能とされた条項又は部分の趣旨に従い、これと同等の効果を確保できるように努めるとともに修正された本規約に拘束されることに同意するものとします。{" "}
              </p>
              <p>
                2.本規約のいずれかの条項又はその一部が、ある利用者との関係で無効又は執行不能と判断された場合であっても、他の利用者との関係における有効性等には影響を及ぼさないものとします。{" "}
              </p>
              <br />
              <p>第 18 条（存続条項） </p>
              <p>
                本契約が終了した場合でも、第 7 条（個人情報の取扱い）、第 8
                条（禁止行為）2 項、第 9 条（規約違反に対する措置等）2 項、第 10
                条（損害賠償）、第 11 条（知的財産権の帰属）、 第 12
                条（本アプリの変更・停止等）3 項、第 14
                条（反社会的勢力の排除）3 項及び同条 4 項、第 16
                条（地位の譲渡等）、本条（存続条項）並びに第 20
                条（準拠法及び合意管轄）は 有効に存続するものとする。
              </p>
              <br />
              <p>
                第 19 条（不可抗力）
                当社は、天災、法令・規則の制定・改廃、疫病・感染症の流行その他の不可抗力によって本
                アプリの履行が妨げられた場合には、本アプリ利用契約その他の一切の規定にかかわらず、
                かかる不可抗力によって利用者に生じた損害について一切の責任を負担しません。{" "}
              </p>
              <br />
              <p>第 20 条（準拠法及び合意管轄） </p>
              <p>
                本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、当社の所在地を管轄する地方裁判所又は簡易裁判所を第一審の専属的合意管轄裁判所とします。{" "}
              </p>
              <br />
              <p>第 21 条（協議解決） </p>
              <p>
                当社及び利用者は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
              </p>
            </div>
            <CustomForm
              form={form}
              onFinish={onFinish}
              className="row-col"
              initialValues={initValues}
            >
              <Form.Item
                valuePropName="checked"
                name="isAgreeTermsAndConditions"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(TextConstants.TermsOfUser.RequiredMessage)
                          ),
                  },
                ]}
              >
                <Checkbox style={{ marginLeft: 4, marginRight: 10 }}>
                  {TextConstants.TermsOfUser.IAgreeWithTermsAndConditions}
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  htmlType="button"
                  onClick={redirectToLoginPage}
                  style={{ width: "130px" }}
                >
                  {TextConstants.Common.GoBack}
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "130px", float: "right" }}
                >
                  {TextConstants.Common.GoNext}
                </Button>
              </Form.Item>
            </CustomForm>
            <div>
              <hr className="custom-hr" />
              <AppReleaseVersion />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default TermsOfUser;
