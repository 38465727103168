import { Col, Row } from "antd";
import GoBackButton from "src/components/GoBackButton";
import GoNextButton from "src/components/GoNextButton";

const StepControlButtons = ({
  backLabel = "",
  isDisabledBack = false,
  isShowBackIcon = true,
  onGoBack = () => {},
  nextLabel = "",
  isDisabledNext = false,
  isShowNextIcon = true,
  onGoNext = () => {},
}) => {
  return (
    <Row gutter={[24, 0]} style={{ marginTop: 20 }}>
      <Col xs={{ span: 12 }}>
        <GoBackButton
          label={backLabel}
          disabled={isDisabledBack}
          onClick={onGoBack}
          isShowIcon={isShowBackIcon}
        />
      </Col>
      <Col xs={{ span: 12 }} style={{ display: "flex", justifyContent: "end" }}>
        <GoNextButton
          label={nextLabel}
          disabled={isDisabledNext}
          onClick={onGoNext}
          isShowIcon={isShowNextIcon}
        />
      </Col>
    </Row>
  );
};

export default StepControlButtons;
