import { Table } from "antd";
import React, { useCallback, useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PROPERTY_TYPES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { createFloor, getLights } from "src/store/actions";
import StepControlButtons from "../../components/StepControlButtons/StepControlButtons";
import { PATHS } from "src/routes";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    title: TextConstants.Common.Item,
    dataIndex: "item",
    width: "50%",
  },
  {
    title: TextConstants.Common.Value,
    dataIndex: "value",
    width: "50%",
  },
];
const FloorSettingsConfirm = ({
  newDeployment,
  lightsObj = {},
  onGoBack = () => {},
}) => {
  const navigate =useNavigate();
  const dispatch = useDispatch();
  const { lightFetchedAt, loading } = useSelector((state) => state.Lights);
  useEffect(() => {
    // Refresh the lights data after 1 mins
    if (!lightFetchedAt || new Date().getTime() - lightFetchedAt > 1000 * 60) {
      dispatch(getLights());
    }
  }, [lightFetchedAt]);

  const newDeploymentDataSource = useMemo(() => {
    return [
      {
        key: 1,
        item: TextConstants.NewFloorMapSettings.Name,
        value: newDeployment.officeName,
      },
      {
        key: 2,
        item: TextConstants.NewFloorMapSettings.BuildingName,
        value: newDeployment.buildingName,
      },
      {
        key: 3,
        item: TextConstants.NewFloorMapSettings
          .EnterRoomDimensionsAndCeilingHeight,
        value: (
          <>
            {newDeployment.dimensions[0]}
            <span style={{ marginRight: "10px", marginLeft: "10px" }}>x</span>
            {newDeployment.dimensions[1]}
            <span style={{ marginRight: "10px", marginLeft: "10px" }}>x</span>
            {newDeployment.dimensions[2]}
          </>
        ),
      },
      {
        key: 4,
        item: TextConstants.NewFloorMapSettings.PropertyType,
        value: PROPERTY_TYPES[newDeployment.propertyType]["LABEL"],
      },
      {
        key: 5,
        item: TextConstants.EasySetingsPage.MaxDistanceBetweenLightsLabel,
        value: newDeployment.maxLightDistance,
      },
      // {
      //   key: 6,
      //   item: TextConstants.EasySetingsPage.TotalGroupsLabel,
      //   value: newDeployment.groups.length,
      // },
      {
        key: 7,
        item: TextConstants.EasySetingsPage.TotalLightsLabel,
        value: (
          <>
            {Object.entries(newDeployment.totalLights).map(([lightId, num]) => {
              const light = lightsObj[lightId];
              const lightName = light ? light.name : `[ID: ${lightId}]`;
              return (
                <p key={lightId}>
                  {lightName}：{num}
                </p>
              );
            })}
          </>
        ),
      },
      // {
      //   key: 8,
      //   item: TextConstants.EasySetingsPage.GroupNamesLabel,
      //   value: newDeployment.groups.map((g) => g.name).join("、"),
      // },
    ];
  }, [newDeployment, loading]);

  const onGoNext = useCallback(() => {
    dispatch(
      createFloor(newDeployment, () => {
        navigate(`${PATHS.NEW_DEPLOYMENT}`);
      })
    );
  }, [dispatch, createFloor, newDeployment]);

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={newDeploymentDataSource}
        bordered
        pagination={false}
        size="small"
        style={{ overflow: "auto" }}
      />
      <StepControlButtons
        onGoBack={onGoBack}
        onGoNext={onGoNext}
        nextLabel={TextConstants.Common.Confirmation}
      />
    </React.Fragment>
  );
};

export default FloorSettingsConfirm;
