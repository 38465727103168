import {
  EllipsisOutlined,
  FieldTimeOutlined,
  ProfileOutlined,
  SettingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Col, Row, Tabs } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomCard from "src/components/CustomCard/CustomCard";
import TextConstants from "src/constants/TextConstants";
import { getFacilityFloor, getFacilityFloorV2Data } from "src/store/actions";
import Settings from "./Settings/Settings";
import { PATHS } from "src/routes";
import OperationLogs from "./OperationLogs/OperationLogs";
import Others from "./Others/Others";


const FloorBMInformation = () => {
  const { facilityId, floorId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { facilityFloor, loading } = useSelector((state) => state.Floors);

  useEffect(() => {
    dispatch(getFacilityFloor({ facilityId, floorId }));
    dispatch(getFacilityFloorV2Data({ facilityId, floorId }));
  }, [dispatch, facilityId, floorId]);

  const onGoBack = () => {
    navigate(`${PATHS.ADMIN_FLOOR_PLAN}`);
  };

  const cardTitle = useMemo(() => {
    const floorName = facilityFloor.name || "-";
    return `${TextConstants.Pages.FloorBMLiteInformation}（${floorName}）`;
  }, [facilityFloor.name]);

  const informationTabs = [
    {
      label: (
        <>
          <SettingOutlined />
          {TextConstants.FloorBMLiteInformation.Settings}
        </>
      ),
      key: "settings",
      children: <Settings />,
    },
    {
      label: (
        <>
          <WarningOutlined />
          {TextConstants.FloorBMLiteInformation.Error}
        </>
      ),
      key: "error",
      children: ``,
    },
    {
      label: (
        <>
          <FieldTimeOutlined />
          {TextConstants.FloorBMLiteInformation.OperationLog}
        </>
      ),
      key: "operationLog",
      children: <OperationLogs />,
    },
    {
      label: (
        <>
          <ProfileOutlined />
          {TextConstants.FloorBMLiteInformation.Others}
        </>
      ),
      key: "others",
      children: <Others />,
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xl={{ span: 24 }}>
          <CustomCard
            bordered={true}
            title={cardTitle}
            onGoBack={onGoBack}
          >
            <Tabs
              defaultActiveKey="settings"
              type="card"
              size="small"
              items={informationTabs}
            />
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FloorBMInformation;
