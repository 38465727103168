import { Steps } from "antd";
import { NEW_DEPLOYMENT_STEPS_BY_MODE } from "src/constants";
import useDeviceWidth from "src/helpers/hooks/useDeviceWidth";

const NewDeploymentSteps = ({ stepNo, mode = "EASY" }) => {
  const deviceWidth = useDeviceWidth();

  return (
    <>
      <Steps
        direction={parseInt(deviceWidth) < 1200 ? "horizontal" : "vertical"}
        current={stepNo === undefined ? 0 : stepNo + 1}
        size="small"
        items={NEW_DEPLOYMENT_STEPS_BY_MODE[mode].map((step) => {
          return {
            title: step,
          };
        })}
        style={{ marginBottom: "10px" }}
      />
    </>
  );
};

export default NewDeploymentSteps;
