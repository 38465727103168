import { Alert, Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomCard from "src/components/CustomCard/CustomCard";
import CustomForm from "src/components/CustomForm";
import CustomNote from "src/components/CustomNote";
import { MAX_NUM_REGISTERED_FACILITIES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { createFacility, getFacilities } from "src/store/actions";

const NewFacility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { facilities, loading } = useSelector((state) => state.Facilities);

  const [totalEmaisIndex, setTotalEmaisIndex] = useState([]);

  const formInitValues = {
    facilityName: "",
  };

  const moveToFacilityDetailById = (facilityId) => {
    navigate(PATHS.FACILITY_DETAIL.replace(":facilityId", facilityId));
  };

  const onFinish = useCallback(
    (formValues) => {
      // TODO: Disabled the Setup user
      const payload = {
        facilityName: "",
        // emails: [],
      };
      payload["facilityName"] = formValues.facilityName;
      // payload.emails.push(formValues[`email_0`]);

      // totalEmaisIndex.forEach((indexNum) => {
      //   payload.emails.push(formValues[`email_${indexNum}`]);
      // });

      dispatch(createFacility(payload, moveToFacilityDetailById));
    },
    [dispatch, totalEmaisIndex]
  );

  const onGoBack = () => {
    navigate(PATHS.DASHBOARD);
  };

  const isHaveReachedFacilitySubscriptionLimit = useMemo(() => {
    return (facilities || []).length >= MAX_NUM_REGISTERED_FACILITIES;
  }, [facilities]);

  useEffect(() => {
    dispatch(getFacilities());
  }, [dispatch, getFacilities]);

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <CustomCard
            className="common-card tablespace"
            title={TextConstants.NewFacility.Title}
          >
            <CustomNote>{TextConstants.NewFacility.SubTitle}</CustomNote>
            {isHaveReachedFacilitySubscriptionLimit && (
              <Alert
                message={`${
                  TextConstants.Common.MaxFacilitiesRegisteredInvalidMessage
                }${TextConstants.Common.InvalidMaxNumberMessage.replace(
                  "{num}",
                  MAX_NUM_REGISTERED_FACILITIES
                )}`}
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}
            <CustomForm
              name="basic"
              initialValues={formInitValues}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row gutter={[48, 0]}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                  <Row gutter={[24, 0]}>
                    <Col lg={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                      <label htmlFor="facilityName">
                        <Typography.Text type="danger">*</Typography.Text>
                        {TextConstants.NewFacility.FacilityName}：
                      </label>
                    </Col>
                    <Col lg={{ span: 18 }} md={{ span: 16 }} xs={{ span: 24 }}>
                      <Form.Item
                        style={{
                          width: "calc(100% - 65px)",
                        }}
                        name="facilityName"
                        rules={[
                          {
                            required: true,
                          },
                          {
                            validator: (_, value) =>
                              value.length > 0 && !value.trim()
                                ? Promise.reject(
                                    new Error(
                                      TextConstants.Common.ThisFieldIsRequired
                                    )
                                  )
                                : Promise.resolve(),
                          },
                          {
                            max: 255,
                            message:
                              TextConstants.Common.MaxLengthWarningMessage.replace(
                                "{max}",
                                "255"
                              ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus
                          id="facilityName"
                          placeholder={
                            TextConstants.NewFacility.FacilityNameExample
                          }
                          disabled={isHaveReachedFacilitySubscriptionLimit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row gutter={[24, 0]}>
                    <Col lg={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                      <label htmlFor="email_0">
                        <Typography.Text type="danger">*</Typography.Text>
                        {TextConstants.NewFacility.InviteUsers}：
                      </label>
                    </Col>
                    <Col lg={{ span: 18 }} md={{ span: 16 }} xs={{ span: 24 }}>
                      <Form.Item
                        key={0}
                        name={`email_0`}
                        rules={[
                          {
                            required: true,
                            type: "email",
                          },
                        ]}
                        style={{
                          width: "calc(100% - 65px)",
                        }}
                      >
                        <Input
                          id={`email_0`}
                          placeholder={`${TextConstants.Common.EmailAddress} #1`}
                        />
                      </Form.Item>
                      {totalEmaisIndex.map((indexNum, i) => {
                        const index = i + 1;
                        return (
                          <Row key={index}>
                            <Form.Item
                              style={{
                                width: "calc(100% - 65px)",
                                marginRight: 10,
                              }}
                              key={`email_${indexNum}`}
                              name={`email_${indexNum}`}
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                },
                              ]}
                            >
                              <Input
                                placeholder={`${
                                  TextConstants.Common.EmailAddress
                                } #${index + 1}`}
                                autoFocus
                              />
                            </Form.Item>
                            <Button
                              disabled={loading}
                              type="danger"
                              onClick={() => {
                                setTotalEmaisIndex(
                                  totalEmaisIndex.filter(
                                    (index) => index !== indexNum
                                  )
                                );
                              }}
                            >
                              <MinusOutlined />
                            </Button>
                          </Row>
                        );
                      })}
                      {totalEmaisIndex.length <
                        MAX_NUM_SETUP_USER_EACH_FACILITY && (
                        <Form.Item>
                          <Button
                            disabled={loading}
                            type="primary"
                            onClick={() => {
                              const maxIndex =
                                totalEmaisIndex.length > 0
                                  ? Math.max(...totalEmaisIndex)
                                  : -1;
                              setTotalEmaisIndex([
                                ...totalEmaisIndex,
                                maxIndex + 2,
                              ]);
                            }}
                          >
                            <PlusOutlined />
                          </Button>
                        </Form.Item>
                      )}
                    </Col>
                  </Row> */}
                  <Row gutter={[24, 0]}>
                    <Col
                      lg={{ span: 6 }}
                      md={{ span: 8 }}
                      xs={{ span: 24 }}
                    ></Col>
                    <Col lg={{ span: 18 }} md={{ span: 16 }} xs={{ span: 24 }}>
                      <Button
                        loading={false}
                        htmlType="button"
                        style={{ width: "130px" }}
                        onClick={onGoBack}
                      >
                        {TextConstants.Common.Cancel}
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ float: "right", marginRight: 65 }}
                        loading={loading}
                        disabled={isHaveReachedFacilitySubscriptionLimit}
                      >
                        {TextConstants.Common.Create}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CustomForm>
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NewFacility;
