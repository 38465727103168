import { Form } from "antd";
import TextConstants from "src/constants/TextConstants";
import InvalidEmailMessage from "../InvalidEmailMessage";

const defaultValidateMessages = {
  required: TextConstants.Common.ThisFieldIsRequired,
  types: {
    email: <InvalidEmailMessage />,
  },
  number: {
    range: TextConstants.Common.MaxMinNumberValidateMessage.replace(
      "{min}",
      "${min}"
    ).replace("{max}", "${max}"),
  },
};

const CustomForm = ({ children, ...props }) => {
  return (
    <Form {...props} validateMessages={defaultValidateMessages}>
      {children}
    </Form>
  );
};

export default CustomForm;
