import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import TextConstants from "src/constants/TextConstants";

const AddButton = ({
  label = "",
  onClick = null,
  style = {},
  type = "primary",
  htmlType = "button",
  disabled = false,
  loading = false,
}) => {
  return (
    <Button
      type={type}
      htmlType={htmlType}
      icon={<PlusOutlined />}
      onClick={onClick ? onClick : () => {}}
      style={{ ...{ minWidth: 120 }, ...style }}
      disabled={disabled}
      loading={loading}
    >
      {label ? label : TextConstants.Common.Add}
    </Button>
  );
};

export default AddButton;
