import { useState, useEffect } from "react";

const getDimentions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [dimentions, setDimentions] = useState(getDimentions());

  useEffect(() => {
    const handleResize = () => {
      setDimentions(getDimentions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimentions;
};
