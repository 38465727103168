import { Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "src/components/CustomForm";
import TextConstants from "src/constants/TextConstants";
import { createFacility } from "src/store/actions";

const CreateFacilityModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { loading } = useSelector((state) => state.Facilities);
  const { loading: companiesLoading, companies } = useSelector(
    (state) => state.Companies
  );

  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  const initialValues = { facilityName: "" };

  const companiesOptions = useMemo(() => {
    return companies.map((company) => {
      return {
        value: company.companyId,
        label: company.name,
      };
    });
  }, [companies]);

  const onSelectCompany = useCallback(
    (companyId) => {
      setSelectedCompanyId(companyId);
    },
    [setSelectedCompanyId]
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        companyId: selectedCompanyId,
        facilityName: values.facilityName,
      };
      dispatch(createFacility(data, () => onClose && onClose()));
    },
    [dispatch, createFacility, onClose, selectedCompanyId]
  );

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <React.Fragment>
      <Modal
        title={TextConstants.Facility.EditFacilityName}
        open={true}
        onOk={handleSubmit}
        okButtonProps={{ onClick: handleSubmit, disabled: loading }}
        okText={TextConstants.Common.Registration}
        cancelText={TextConstants.Common.Cancel}
        cancelButtonProps={{ disabled: loading }}
        onCancel={onClose}
        confirmLoading={loading}
        width={400}
      >
        <CustomForm
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="row-col"
          initialValues={initialValues}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} md={24}>
              <Form.Item
                label={TextConstants.Facility.FacilityName}
                name="facilityName"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      value?.length > 0 && !value.trim()
                        ? Promise.reject(
                            new Error(TextConstants.Common.ThisFieldIsRequired)
                          )
                        : Promise.resolve(),
                  },
                  {
                    max: 255,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        "255"
                      ),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label={TextConstants.Common.CompanyName}
                name="companyId"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      value?.length > 0 && !value.trim()
                        ? Promise.reject(
                            new Error(TextConstants.Common.ThisFieldIsRequired)
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={TextConstants.Common.CompanyName}
                  value={selectedCompanyId}
                  loading={companiesLoading}
                  style={{
                    width: "100%",
                  }}
                  onChange={onSelectCompany}
                  options={companiesOptions}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </CustomForm>
      </Modal>
    </React.Fragment>
  );
};

export default CreateFacilityModal;
