import * as Types from "src/store/actions";

export const getBMConfigrurationInformation = (params, callback = null) => ({
  type: Types.GET_BM_CONFIGURATION_INFO.REQUEST,
  payload: params,
  callback,
});

export const SetBMConfigrurationInformation = (params, callback = null) => ({
  type: Types.CREATE_OFFICE.REQUEST,
  payload: params,
  callback,
});

export const getBMsDeleted = (params, callback = null) => ({
  type: Types.GET_BMS_DELETED.REQUEST,
  payload: params,
  callback,
});

export const getBMSetupData = (params, callback = null) => ({
  type: Types.GET_BM_SETUP_DATA.REQUEST,
  payload: params,
  callback,
});
