import { Col, Form, Input, InputNumber, Row, Select, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useCallback, useMemo } from "react";
import CustomForm from "src/components/CustomForm";
import { NEW_FLOOR_MAP_SETTING_MODE, PROPERTY_TYPES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import EZOrManualModeSelector from "../../components/EZOrManualModeSelector/EZOrManualModeSelector";
import StepControlButtons from "../../components/StepControlButtons/StepControlButtons";

const propertyTypeOptions = [
  { value: PROPERTY_TYPES.SKOOL.KEY, label: PROPERTY_TYPES.SKOOL.LABEL },
  {
    value: PROPERTY_TYPES.OFFICE.KEY,
    label: PROPERTY_TYPES.OFFICE.LABEL,
  },
  {
    value: PROPERTY_TYPES.MEDICAL.KEY,
    label: PROPERTY_TYPES.MEDICAL.LABEL,
  },
  {
    value: PROPERTY_TYPES.WAREHOUSE.KEY,
    label: PROPERTY_TYPES.WAREHOUSE.LABEL,
  },
];

const InitNewDeployment = ({
  setStepMode,
  onGoBack,
  newDeployment,
  updateNewDeploymentLocal = () => {},
}) => {
  const [form] = useForm();

  const onFinish = (values) => {
    const newDeploymentData = {
      officeName: values.officeName,
      buildingName: values.buildingName,
      dimensions: [values.dimensionsW, values.dimensionsL, values.dimensionsH],
      propertyType: values.propertyType,
      settingMode: values.floorMapSettingMode,
      stepNo: 0,
    };
    updateNewDeploymentLocal(newDeploymentData);
  };

  const onGoNext = useCallback(() => {
    if (form) {
      form.submit();
    }
  }, [form]);

  const formInitValues = useMemo(() => {
    return {
      officeName: newDeployment?.officeName || "",
      buildingName: newDeployment?.buildingName || "",
      dimensionsW: newDeployment?.dimensions?.[0],
      dimensionsL: newDeployment?.dimensions?.[1],
      dimensionsH: newDeployment?.dimensions?.[2],
      propertyType: newDeployment?.propertyType || PROPERTY_TYPES.SKOOL.KEY,
      floorMapSettingMode:
        newDeployment?.settingMode || NEW_FLOOR_MAP_SETTING_MODE.EASY,
    };
  }, [newDeployment]);

  return (
    <React.Fragment>
      <CustomForm
        name="basic"
        style={{
          maxWidth: "100%",
        }}
        initialValues={formInitValues}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row gutter={[48, 0]}>
          <Col md={{ span: 24 }} xs={{ span: 24 }}>
            <Row gutter={[24, 0]}>
              <Col lg={{ span: 6 }} md={{ span: 7 }} xs={{ span: 24 }}>
                <label htmlFor="officeName">
                  <Typography.Text type="danger">*</Typography.Text>
                  {TextConstants.NewFloorMapSettings.Name}：
                </label>
              </Col>
              <Col lg={{ span: 18 }} md={{ span: 17 }} xs={{ span: 24 }}>
                <Form.Item
                  name="officeName"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      max: 255,
                      message:
                        TextConstants.Common.MaxLengthWarningMessage.replace(
                          "{max}",
                          "255"
                        ),
                    },
                  ]}
                >
                  <Input id="officeName" autoFocus />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col lg={{ span: 6 }} md={{ span: 7 }} xs={{ span: 24 }}>
                <label htmlFor="buildingName">
                  <Typography.Text type="danger">*</Typography.Text>
                  {TextConstants.NewFloorMapSettings.BuildingName}：
                </label>
              </Col>
              <Col lg={{ span: 18 }} md={{ span: 17 }} xs={{ span: 24 }}>
                <Form.Item
                  name="buildingName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input id="buildingName" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col lg={{ span: 6 }} md={{ span: 7 }} xs={{ span: 24 }}>
                <label htmlFor="dimensionsW">
                  <Typography.Text type="danger">*</Typography.Text>
                  {
                    TextConstants.NewFloorMapSettings
                      .EnterRoomDimensionsAndCeilingHeight
                  }
                  ：
                </label>
              </Col>
              <Col lg={{ span: 18 }} md={{ span: 17 }} xs={{ span: 24 }}>
                <Row gutter={[24, 0]}>
                  <Col xs={{ span: 8 }}>
                    <Form.Item
                      name="dimensionsW"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 2000,
                          message:
                            TextConstants.Common.MaxMinNumberValidateMessage.replace(
                              "{min}",
                              1
                            ).replace("{max}", 2000),
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="W"
                        id="dimensionsW"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 8 }}>
                    <Form.Item
                      name="dimensionsL"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 2000,
                          message:
                            TextConstants.Common.MaxMinNumberValidateMessage.replace(
                              "{min}",
                              1
                            ).replace("{max}", 2000),
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="L"
                        id="dimensionsL"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 8 }}>
                    <Form.Item
                      name="dimensionsH"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 2000,
                          message:
                            TextConstants.Common.MaxMinNumberValidateMessage.replace(
                              "{min}",
                              1
                            ).replace("{max}", 2000),
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="H"
                        id="dimensionsH"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col lg={{ span: 6 }} md={{ span: 7 }} xs={{ span: 24 }}>
                <label htmlFor="propertyType">
                  <Typography.Text type="danger">*</Typography.Text>
                  {TextConstants.NewFloorMapSettings.PropertyType}：
                </label>
              </Col>
              <Col lg={{ span: 18 }} md={{ span: 17 }} xs={{ span: 24 }}>
                <Form.Item name="propertyType">
                  <Select
                    onChange={() => {}}
                    id="propertyType"
                    options={propertyTypeOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }}>
            <Row gutter={[0, 0]}>
              <Col lg={{ span: 6 }} md={{ span: 7 }} xs={{ span: 24 }}>
                <label htmlFor="floorMapSettingMode">
                  <Typography.Text type="danger">*</Typography.Text>
                  {TextConstants.NewFloorMapSettings.FloorMapSettings}：
                </label>
              </Col>
              <Col lg={{ span: 18 }} md={{ span: 17 }} xs={{ span: 24 }}>
                <EZOrManualModeSelector
                  onChange={(e) => {
                    setStepMode(e.target.value);
                  }}
                  name={"floorMapSettingMode"}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomForm>
      <StepControlButtons onGoBack={onGoBack} onGoNext={onGoNext} />
    </React.Fragment>
  );
};

export default InitNewDeployment;
