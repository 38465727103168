const TextConstants = {
  JPCommonCharacters: {
    Colon: "：",
    Comma: "，",
    Punctuation: "。",
    QuestionMark: "？",
    LeftParenthesis: "（",
    RightParenthesis: "）",
  },
  ROLES: {
    SYSTEM_ADMIN: "システム管理者",
    FACILITY_ADMIN: "施設管理者",
    SETUP_USER: "設定ユーザー",
    COMPANY_ADMIN: "会社管理者",
    DEPARTMENT_ADMIN: "拠点管理者",
    GENERAL_USER: "店舗ユーザー",
  },
  ERRORS: {
    ERR_ISE: "内部サーバーエラー",
    ERR_FVF: "フォームの検証に失敗しました",
    ERR_LGF: "無効なログイン認証情報です",
    ERR_UAT: "無許可",
    ERR_APD: "許可が拒否されました",
    ERR_PCF: "プロセスが失敗しました",
    ERR_UNF: "ユーザーが見つかりません",
    ERR_RNF: "リソースが見つかりません",
    ERR001: "会社にはまだユーザーがいます",
    ERR002: "拠点にはまだユーザーがいます",
    ERR003: "店舗を選択してください",
    ERR004: "店舗Idが関連付けられた店舗と一致しません",
    ERR005: "会社名はすでに存在します",
    ERR006: "拠点名はすでに存在します",
    ERR007: "店舗名はすでに存在します",
    ERR008: "最大同時ログインユーザー数を超えています",
  },
  PortalName: "LICONEX LITE",
  Pages: {
    Dashboard: "ダッシュボード",
    FacilityList: "施設の一覧",
    Users: "ユーザー",
    BMInformation: "BM情報",
    PowerData: "電力データ",
    SendBMCommand: "BMコマンド送信",
    GlobalBMSearch: "グローバルBM検索",
    FeedbackFromUser: "ユーザーからのフィードバック",
    BMsDeleted: "BMが削除されました",

    RegisteredNewPropertyChecking: "物件管理",
    NewProperty: "新しい物件",
    FloorRegistration: "フロア登録",
    EditFloor: "フロア更新",
    Companies: "会社",
    Departments: "拠点",
    Offices: "店舗",

    UserManagement: "施設のユーザー管理",
    FloorPlan: "フロアプラン",
    FloorBMLiteInformation: "BM Lite情報",

    AddNewFacility: "新しい施設の登録",
    SendFeedback: "フィードバックを送信",

    NewAccountRegistrationConfirmation: "新規アカウント登録確認",
  },
  Toasts: {
    Notification: "通知",
    Success: "成功",
    Warning: "注意",
    Error: "エラー",
  },
  NewPropertyChecking: {
    Title: "新しい物件",
    WouldUouLikeToRegisterANewProperty: "新たに物件を登録してみませんか？",
    CheckRegisteredProperties: "登録されている物件を確認",
  },
  NewFacility: {
    Title: "新しい施設の登録",
    SubTitle:
      "LiCONEXLiTEを導入する施設を登録します。施設の名称を入力してください。",
    FacilityName: "施設名",
    FacilityNameExample: "例）○○学校 / ○○スーパー",
    InviteUsers: "ユーザーを招待",
  },
  Facility: {
    EditFacilityName: "施設名を変更",
    FacilityName: "施設名",
    DeleteFacility: "施設を削除",
    AddFloor: "フロアの追加",
    SearchFloorPlaceHolder: "３F、教室",
    SearchUsersPlaceHolder: "例：taro_iris@irisohyama.co.jp",
    RemoveFacilityConfirmMessage: " 全てのフロアとユーザーが削除されます ",

    AddUsersToInvite: "招待するユーザーを追加する",
  },
  FacilityFloors: {
    RegisterFloor: "フロアを登録します。",
    PleaseEnterFloorInformation: "フロア情報を入力してください。",
    FloorName: "フロア名",
    FloorNameExample: "例) 1年1組",
    FloorSize: "フロアサイズ",
    Vertical: "たて",
    Length: "長さ",
    Height: "高さ",
    ClickHereToRegisterDrawings: "図面登録はこちら (任意) ",
    RegisterTheLightingTypeAndInstallationLocation:
      "照明の種類や設置位置を登録ます。",
    ItIsEasierToManage: "管理が簡単になります。",
    IsTheDistanceBetweenLightsWithin15m: "照明同士の距離は15m以内ですか?",
    FloorStatus: {
      WaitingForSettings: "設定待ち",
      AlreadySetup: "設定済み",
    },
    TotalLightsLabel: "照明台数",
    CanNotDeleteFloorHasAlreadyBeenSetUpDone: "設定済みのフロアは削除できません。",
  },
  FloorBMLiteInformation: {
    Settings: "設定",
    GroupSettings: "グループ設定",
    ConferenceRoom: "会議室",
    Number: "数",
    SwitchSettings: "スイッチ設定",
    SimpleSettings: "簡単設定",
    Brightness: "明るさ",
    ColorAdjustment: "調色",
    DetailedSettings: "詳細設定",
    ScheduleSettings: "スケジュール設定",
    PowerOnSettings: "起動時点灯設定",
    PowserOnSettingLabels: [
      "最大の調光率で点灯",
      "設定した調光率・光色で点灯",
      "最終状態の調光率で点灯",
    ],
    BrightnessLimitSettings: "明るさ上限設定",
    FadeSettings: "フェード設定",
    Error: "エラー",
    OperationLog: "操作ログ",
    Others: "その他",
    LastLoggedIn: "最終ログイン",
    IPAddress: "IPアドレス",
    appVersion: "アプリバージョン",
    bmVersion: "BMバージョン",
    lxVersion: "LXバージョン",
    tgVersion: "TGバージョン",
    macAddress: "MACアドレス",
    lightingType: "照明の種類・種別",
    loginInfo: "お客様のログイン情報",
  },
  NewFloorMapSurvey: {
    ConditionsMetWarningMessage: "続行するには条件1～5を満たす必要がある。",
  },
  NewFloorMapSettings: {
    Note: "フロアとは1つのベースモジュール照明の操作を行う区画です。",
    Name: "名称",
    BuildingName: "ビル名",
    PropertyType: "物件タイプ",
    EnterRoomDimensionsAndCeilingHeight: "物件寸法・天高（W x L x H）",
    FloorMapSettings: "フロアマップ設定",
    EasySettings: "Easy設定",
    ManualSetting: "手動設定",
    EasyRegistration: "かんたん登録",
    RegisterTheMinimumInformation: "最低限の情報の登録を行い",
    MakeSureToRegister: "しっかり登録",
    RegisterDrawingsAndPlaceLighting: "図面を登録して照明の配置",
    CheckingInstallationConditions: "設置条件の確認",
    ExceededMaxHeightErrorMessage: "高さは15m以下であることが必要です",

    FloorMapUpload: "フロアマップアップロード",
    DoUWantToSetTheLightingPositionToAuto: "照明位置を自動に設定しますか？",
    Automatic: "自動",
    Manual: "手動",
    SettingUpLighting: "照明設定中",
    AddMode: "追加モード",
    DeleteMode: "削除モード",
    LightSettingsNote:
      "*マップ上にあるピンをクリック出来ます。照明位置を変更可能、照明追加可能。",
    ActionMode: "動作モード",
    NumberLightFixtures: "灯具台数",
    CheckLightingSettings: "照明設定確認",
    LightingType: "灯具タイプ",
    MaxLightSensorNumberWarningHint: {
      Content1: "照明・センサの台数についての注意事項",
      Content2: "<2台として算出するもの>",
      Hint1: "・110形のLX照明（品番: LX******110-LI）は2台分として算出します。",
      Hint2: "・LED直管（品番:LDG******）は1本で1台と算出します。",
    },
  },
  PropertyType: {
    School: "学校（教室・体育館）",
    SmallOffice: "小規模オフィス",
    MedicalOffice: "医療事務所",
    SmallFactoryWarehouse: "小規模工場倉庫",
  },
  LightSelectionPage: {
    FloorMapUpload: "フロアマップアップロード",
    SelectFiles: "ファイルを選択",
    LightingSelection: "灯具選択",
    LightName: "灯具名",
    LightType: "灯具の種類",
    SoftID: "ソフト ID",
    Detail: "詳細",
    Choice: "選択",
    MustChooseMapImageFile:
      "フロアマップファイルをアップロードする必要があります",
    MustChooseLightWarningMessage: "少なくとも1種類の照明をお選びください",
  },
  EasySetingsPage: {
    MaxDistanceBetweenLightsLabel: "照明間の最大距離 （m）",
    TotalLightsLabel: "総照明数",
    TotalGroupsLabel: "グループ合計（最大6名）",
    GroupNamesLabel: "グループ名",
  },
  NewDeploymentSteps: {
    Initial: "イニシャル",
    LightingSelection: "灯具選択",

    // EZ setting mode
    EZSettings: "Easy設定",

    // Manual setting mode
    LightSettingsConfirm: "照明設定の確認",
    SettingUpTheLight: "照明設定中",

    Confirm: "確認する",
  },
  SendFeedback: {
    Title: "フィードバックを送信",
    SubTitle:
      "お客様のフィードバックは LiCONEXLiTE のサポート部門に送信されます。お客様の問題が何かをお知らせください。",
    IssueTitle: "タイトル （問題の簡単な説明）",
    IssueType: "タイプ",
    IssueTypeBugsReport: "バグ報告",
    IssueTypeFeedback: "フィードバック",
    Description: "説明",
    SuccessMessage: "送信に成功しました",
    FeedbackDetail: "フィードバックの詳細",
    InquiryDetails: "お問い合わせ内容",
  },
  BMsDeleted: {
    Title: "BMが削除されました",
    EncryptionKey: "Encryption Key",
  },
  GlobalBMSearch: {
    Title: "グローバルBM検索",
    SearchResult: "検索結果",
    SetupDataInfo: "セットアップ情報",
  },
  Common: {
    Switch: "スイッチ",
    Seconds: "秒",
    DayOfWeek: "曜日",
    DateTime: "日時",
    DeletedAt: "削除日時",
    ViewConfiguration: "構成を見る",
    SeeMore: "もっと見る",
    None: "なし",
    AppVersion: "アプリバージョン",
    Download: "ダウンロード",
    DetailOfSettings: "設定の詳細",
    Invitation: "招待",
    Accept: "承認",
    Reject: "否認",
    Registration: "登録",
    BMFWVersion: "BM FW",
    LightFWVersion: "灯具 FW",
    All: "全て",
    CreatedAt: "作成日時",
    UpdatedAt: "最終更新日時",
    CreateNew: "新規作成",
    Yes: "はい",
    No: "いいえ",
    Actions: "アクション",
    Load: "ロード",
    SelectFiles: "ファイルを選択",
    Create: "作成",
    OK: "OK",
    Add: "追加する",
    Edit: "編集",
    EditFacility: "施設更新",
    Delete: "削除",
    Clear: "クリア",
    ExportCSV: "CSVエクスポート",
    Cancel: "キャンセル",
    Role: "役割",
    Information: "情報",
    Member: "メンバー",
    Inviting: "招待中",
    User: "ユーザー",
    FloorList: "フロア一覧",
    SettingUser: "設定ユーザー",
    Company: "会社",
    CompanyName: "会社名",
    EmployeeName: "社員名",
    GroupDepartmentName: "グループ名（部署）",
    GroupName: "グループ名",
    EmailAddress: "メールアドレス",
    OnlyAcceptASCIIMessage: "ASCII文字のみが望ましい。",
    InvalidEmailMessage: "有効なメールアドレスを入力してください。",
    InvalidEmailMessageDetail: {
      Why: "なぜこちらのエラーが表示されていますか？",
      Hint1: "- メールアドレスに@マークが含まれていないためです",
      Hint2: "- メールアドレスの長さが320文字を超えている可能性があります",
      Hint3: "- あなたのEメールアドレスが規格に合っていない可能性があります",
    },
    Department: "拠点",
    DepartmentName: "拠点名",
    Departments: "拠点",
    Office: "店舗",
    Offices: "店舗",
    Floor: "フロア",
    Username: "ユーザー名",
    Password: "パスワード",
    Confirm: "確認する",
    NewPassword: "新パスワード",
    ConfirmPassword: "パスワード確認",
    ConfirmDeleteMessage: "こちらの{item}を削除しますか？",
    ConfirmLogout: "ログアウトしますか？",
    ForceLogoutConfirmMessage: "ログアウトされますが、よろしいですか？",
    Name: "名前",
    Reset: "リセット",
    Measurements: "測定",
    DataType: "データタイプ",
    Loading: "読み込み中...",
    Search: "検索",
    Logout: "ログアウト",
    LogoutSuccessfulMessage: "ログアウトが成功しました",
    Pages: "ページ",
    Cuisine: "料理",
    Mode: "モード",
    Date: "日付",
    Time: "時間",
    TimeRange: "時間範囲",
    Start: "開始",
    End: "完了",
    Status: "ステータス",
    Temperature: "温度",
    PIC: "PIC", // Person in charge
    Timestamp: "タイムスタンプ",
    ProductCode: "製品コード",
    DocNum: "文書番号",
    Remarks: "備考",
    MaxLengthWarningMessage: "最大の長さは{max}文字です",
    YesIsRequired: "「はい」が必要です。",
    ThisFieldIsRequired: "項目は必須",
    PasswordInputRequired:
      "○○文字以上、半角記号数字を含むなど条件の記載をお願いします",
    MaxFacilitiesRegisteredInvalidMessage: "施設登録数が上限に達しました。",
    MaxFloorRegisteredInvalidMessage: "フロア登録の上限に達しました。",
    MaxTotalNumberInvitedUserInvalid: "ユーザー招待の制限に達しました。",
    InvalidNumberMessage: "有効な数値を入力してください",
    InvalidMinNumberMessage: "少なくとも{num}",
    InvalidMaxNumberMessage: "最高は{num}です",
    MaxMinNumberValidateMessage: "{min}～{max}の数値を設定してください",
    IncorrectPasswordFormat:
      "パスワードは長さが8~99文字で、数字、英大文字、英小文字、特殊文字のそれぞれを最低1文字ずつ含む必要があります",
    IncorrectPasswordFormatMessageDetail: {
      Hint1: "- 大文字と小文字の基本的なラテン文字",
      Hint2: "- 数字",
      Hint3: "- 特殊文字",
    },
    PasswordAreNotMatched: "入力した新しいパスワードが一致しません。",
    Login: "ログイン",
    Reset: "リセット",
    ResetPassword: "パスワードリセット",
    ForgotPassword: "パスワードを忘れた方",
    ProceduresIfYouCannotLogIn: "ログインできない場合のお手続き",
    PleaseEnterYourEmailRegistered:
      "紛失したパスワードのリセットができます。アカウントで使用しているメールアドレスを入力してください。",
    EnterConfirmPassword: "パスワードを確認のため再入力",
    NotMatchPassword: "パスワードと確認用パスワードが一致しません",
    PasswordResetRequestSuccessMsg:
      "パスワードのリセットを完了させるためにメールをチェックし、その指示に従ってください。",
    PleaseSelectAnOffice: "店舗を選択してください",
    PleaseSelectDataType: "データタイプを選択してください",
    NoData: "データなし",
    Filter: "フィルター",
    SystemScreenAdmin: "システム管理者画面",
    Input: "入力",
    Submit: "送信",
    GoBack: "戻る",
    GoNext: "次へ",
    Confirmation: "登録確認",
    Finished: "終了",
    InProgress: "進行中",
    Waiting: "待機中",
    Item: "項目",
    Value: "値",
    OperationMethod: "運用方法",
    FileUploadedSuccessfully:
      "{fileName}ファイルが正常にアップロードされました。",
    FileUploadedFailed: "{fileName}ファイルのアップロードが失敗しました。",
    InvalidImageFileMsg: "{fileName}はpng/jpgファイルのみアップロード可能です",
    CreateAccount: "アカウント作成",
    ExpiredLink: "リンクの有効期限が切れています",
    Failed: "失敗",
    Success: "成功",
    IsInvited: "招待されました",
    Owner: "オーナー ",
    Guest: "ゲスト",
    CompanyMember: "会社のメンバー",
    UpdateUserInfo: "ユーザー情報の更新",
    ConfirmEmailAddress: "メールアドレスの確認",
    ConfirmEmailAddressSuccessMessage:
      "メールアドレスに送られたメールで承認してください。",
    ThisEmailAlreadyExists: "このメールは既に存在します",
    InviteUsersSuccessMessage: "ユーザーを招待しました",
    InvitedAt: "招待された",
    PendingInviteUsers: "保留中のユーザー招待",
    DeleteInviteUserSuccessMessage: "招待ユーザーを削除しました",
  },
  LoginScreen: {
    Message: "ユーザー名とパスワードを入力してください",
  },
  TermsOfUser: {
    Title: "利用規約確認",
    IAgreeWithTermsAndConditions: "利用規約に同意する",
    RequiredMessage: "続行するには利用規約に同意する必要があります。",
  },
  SignupScreen: {
    SuccessMessage:
      "メールアドレスに送られたメールで承認してください。メールの確認上にアカウント登録が終了致します。",
  },
  FacilityInvitationsModal: {
    Title: "〇〇物件に招待されました。",
  },
  CreateUserModal: {
    Title: "ユーザー追加",
    SuccessMessage: "ユーザーが正常に作成されました",
  },
  UpdateUserInfoModal: {
    Title: "ユーザー情報更新",
    SuccessMessage: "ユーザー情報を正常に更新しました",
  },
  ChangePasswordModal: {
    Title: "パスワード変更",
    SuccessMessage: "ユーザーパスワードが正常に変更されました",
  },
  DeleteUserModal: {
    SuccessMessage: "ユーザーを正常に削除しました",
  },
  CreateCompanyModal: {
    Title: "会社作成",
    SuccessMessage: "会社を正常に作成しました",
  },
  UpdateCompanyModal: {
    Title: "会社更新",
    SuccessMessage: "会社を正常に更新しました",
  },
  DeleteCompanyModal: {
    Title: "会社削除",
    SuccessMessage: "会社を正常に削除しました",
  },
  CreateDepartmentModal: {
    Title: "拠点作成",
    SuccessMessage: "拠点を正常に作成しました",
  },
  UpdateDepartmentModal: {
    Title: "拠点更新",
    SuccessMessage: "拠点を正常に更新しました",
  },
  DeleteDepartmentModal: {
    Title: "拠点削除",
    SuccessMessage: "拠点を正常に削除しました",
  },
  CreateOfficeModal: {
    Title: "店舗作成",
    SuccessMessage: "店舗を正常に作成しました",
  },
  UpdateOfficeModal: {
    Title: "店舗更新",
    SuccessMessage: "店舗を正常に更新しました",
  },
  DeleteOfficeModal: {
    Title: "店舗削除",
    SuccessMessage: "店舗を正常に削除しました",
  },
  CreateFloorModal: {
    Title: "フロア作成",
    SuccessMessage: "フロアを正常に作成しました",
  },
  UpdateFloorModal: {
    Title: "フロア更新",
    SuccessMessage: "フロアを正常に更新しました",
  },
  DeleteFloorModal: {
    Title: "フロア削除",
    SuccessMessage: "フロアを正常に削除しました",
  },
  CreateLightModal: {
    Title: "灯具作成",
    SuccessMessage: "灯具を正常に作成しました",
  },
  UpdateLightModal: {
    Title: "灯具更新",
    SuccessMessage: "灯具を正常に更新しました",
  },
  DeleteLightModal: {
    Title: "灯具削除",
    SuccessMessage: "灯具を正常に削除しました",
  },
  PreRegistrationCompletedConfirmModal: {
    Title: "事前の登録が完了しました。",
    ConfirmContent:
      "LiCONEX スマートフォンアプリにログインして照明の設定を行ってください。",
  },
  CreateFacilitySuccessModal: {
    Title: "施設追加",
    SuccessMessage: "施設が正常に作成されました",
  },
  UpdateFacilitySuccessModal: {
    Title: "施設更新",
    SuccessMessage: "施設を正常に更新しました",
  },
  AcceptFacilityInvitationSuccessModal: {
    Title: "招待を受け入れる",
    SuccessMessage: "成功",
  },
  RejectFacilityInvitationSuccessModal: {
    Title: "招待を断る",
    SuccessMessage: "成功",
  },
  OperationMethod: {
    Switch: "スイッチ",
    Schedules: "スケジュール",
    Sensors: "センサ",
    Scenes: "シーン",
    SmartphoneControl: "スマホ制御",
  },
};

export default TextConstants;
